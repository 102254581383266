@import '~core/styles/mixins';
@import '~org/styles/variables';

$headerExtraRewardsPadding: () !default;
$headerExtraRewardsSectionTitleMargin: () !default;
$headerExtraRewardsSectionTitlePadding: () !default;
$headerExtraRewardsSectionTitleBorderBottom: () !default;
$headerExtraRewardsHeaderColor: () !default;
$headerExtraRewardsHeaderFontWeight: () !default;
$headerExtraRewardsHeaderFontSize: 1.4rem !default;
$headerExtraRewardsHeaderMargin: () !default;
$headerExtraRewardsHeaderTextTransform: () !default;
$headerExtraRewardsHeaderLetterSpacing: () !default;
$headerExtraRewardsHeaderPaddingBottom: () !default;
$headerExtraRewardsLinkBottomDisplay: inline-block !default;
$headerExtraRewardsLinkBottomMargin: 2rem 1.5rem 0 !default;
$headerExtraRewardsLinkBottomPadding: () !default;
$headerExtraRewardsRebateElevationOldValueFontSize: () !default;
$headerExtraRewardsLinkBottomWidth: () !default;
$headerExtraRewardsLinkBottomHeight: () !default;
$headerExtraRewardsLinkBottomBackground: () !default;
$headerExtraRewardsLinkBottomTextAlign: () !default;
$headerExtraRewardsLinkBottomColor: () !default;
$headerExtraRewardsLinkBottomLineHeight: () !default;
$headerExtraRewardsLinkBottomBorderRadius: () !default;
$headerExtraRewardsLinkBottomFontSize: () !default;
$headerExtraRewardsLinkBottomHoverTextDecoration: () !default;
$headerExtraRewardsLinkBottomHoverBackground: () !default;

.mn_headerExtraRewards {
  padding: $headerExtraRewardsPadding;

  .mn_sectionTitle {
    display: flex;
    justify-content: space-between;
    margin: $headerExtraRewardsSectionTitleMargin;
    padding: $headerExtraRewardsSectionTitlePadding;
    border-bottom: $headerExtraRewardsSectionTitleBorderBottom;

    span {
      display: none;
    }

    h2 {
      color: $headerExtraRewardsHeaderColor;
      font-weight: $headerExtraRewardsHeaderFontWeight;
      font-size: $headerExtraRewardsHeaderFontSize;
      margin: $headerExtraRewardsHeaderMargin;
      text-transform: $headerExtraRewardsHeaderTextTransform;
      letter-spacing: $headerExtraRewardsHeaderLetterSpacing;
      padding-bottom: $headerExtraRewardsHeaderPaddingBottom;
      line-height: initial;
    }
  }

  .mn_premierMerchantRebate {
    .mn_rebateValue {
      .mn_elevationOldValue {
        font-size: $headerExtraRewardsRebateElevationOldValueFontSize;
      }
    }
  }

  .mn_linkBottom {
    display: $headerExtraRewardsLinkBottomDisplay;
    margin: $headerExtraRewardsLinkBottomMargin;
    padding: $headerExtraRewardsLinkBottomPadding;
    width: $headerExtraRewardsLinkBottomWidth;
    height: $headerExtraRewardsLinkBottomHeight;
    background: $headerExtraRewardsLinkBottomBackground;
    text-align: $headerExtraRewardsLinkBottomTextAlign;
    color: $headerExtraRewardsLinkBottomColor;
    line-height: $headerExtraRewardsLinkBottomLineHeight;
    border-radius: $headerExtraRewardsLinkBottomBorderRadius;
    font-size: $headerExtraRewardsLinkBottomFontSize;

    &:hover {
      text-decoration: $headerExtraRewardsLinkBottomHoverTextDecoration;
      background: $headerExtraRewardsLinkBottomHoverBackground;
    }
  }
}
