/* stylelint-disable no-descending-specificity */
@import '~org/styles/variables';

.mn_mainNavigationTOF {
  width: 26rem;
  margin-right: 2.5rem;

  /* Navigation drop down */
  .mn_navigationDropdownWrapper {
    position: relative;
    padding-bottom: 2rem;
    margin-bottom: -2rem;

    .mn_navigationDropdown {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      transform: translate3d(0, -10px, 0);
      transition: all .3s ease-in-out;
      position: absolute;
      top: 3.8rem;
      left: -16rem;
      width: 96rem;
      background: $brandWhite;
      padding: 2rem 3rem 9rem;
      z-index: 2;
      text-align: left;
      display: flex;
      justify-content: space-between;
      box-shadow: 0 4px 4px 0 rgba(154, 158, 167, .5);

      .mn_allStoresLink {
        display: block;
        padding: 0;
        height: 4rem;
        line-height: 4rem;
        margin: 3.1rem 0;
        font-size: 1.4rem;
        letter-spacing: 1.24px;
        text-decoration: none;
        background: $brandPrimaryColor;
      }
    }

    .mn_navigationDropdownTrigger {
      display: block;
      background: none;
      border: none;
      width: 100%;
      border-bottom: 1px solid $brandLightGray2;
      color: $brandHeaderColor;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2.5rem;
      height: 3.5rem;
      text-align: left;
      padding: .4rem 1.5rem .4rem .5rem;

      .mn_navArrow {
        position: absolute;
        top: 0;
        right: 0;

        &:before {
          font-family: 'fontello', serif;
          content: '';
          background: no-repeat center/100% url('~org/assets/icons/carrot_down.svg');
          font-size: 3.6rem;
          position: absolute;
          top: 1rem;
          right: 1rem;
          height: 1.2rem;
          width: 2rem;
          color: $brandHeaderColor;
        }
      }
    }

    &.mn_focused {
      .mn_navigationDropdown {
        opacity: 1;
        transform: translate3d(0, 10px, 0);
        pointer-events: all;
        visibility: visible;
      }

      .mn_navigationDropdownTrigger {
        border-bottom: 3px solid $brandHeaderColor;

        .mn_navArrow:before {
          background: no-repeat center/100% url('~org/assets/icons/carrot_up.svg');
        }
      }
    }
  }

  /* Common navigation lists styles */
  h2 {
    font-size: 2rem;
    font-weight: bold;
    margin: 0 0 2rem;
    letter-spacing: .5px;
    color: $brandHeaderColor;
  }

  .mn_navDropdownMenu {
    list-style: none;
    width: 27.5rem;
    font: normal 1.4rem/2.1rem $brandFontFamily;
    margin: 0 -1rem;
    padding: 0;
    border: none;

    li {
      margin: 0;

      & > a {
        display: block;
        width: fit-content;
        text-decoration: none;
        text-transform: capitalize;
        padding: .65rem 1rem;
        color: $brandHeaderColor;
        letter-spacing: .34px;
      }
    }
  }

  .mn_leftMainNavPanelSect {
    flex: 0 0 27rem;
    border-right: #c8c9c7 solid 1px;

    .mn_navCategoriesTOFSection h2 {
      text-transform: none;
    }

    .mn_navCategoriesTOF {
      &:hover,
      &:focus {
        background: $brandLightColor;
      }
    }
  }

  .mn_middleMainNavPanelSect {
    flex: 0 0 30rem;
    border-right: #c8c9c7 solid 1px;
    padding-bottom: 16rem;

    .mn_navOffersTOF h2 {
      text-transform: none;
    }

    .mn_navSeasonalFlyoutSection {
      h2 {
        font-weight: 700;
      }

      .mn_extraRewardsPill {
        min-width: 10.8rem;
      }
    }
  }

  .mn_rightMainNavPanelSect {
    flex: 0 0 28rem;
    padding-bottom: 16rem;
  }

  .mn_bottomMainNavPanelSect {
    width: 53rem;
    height: 18rem;
    position: absolute;
    bottom: 3rem;
    left: 33rem;
  }
}
