@import '~org/styles/variables';

.mn_brandLogo {
  display: block;
  height: 7rem;
  width: 25rem;
  margin: .5rem 2rem 0 0;

  a {
    display: block;
    height: 100%;
    overflow: hidden;
    text-indent: -200%;
    position: relative;
    font-size: 0;
    line-height: 0;

    &:after {
      content: '';
      position: absolute;
      top: 1.6rem;
      left: 0;
      width: 100%;
      min-width: 100%;
      height: 3.4rem;
      background: url('~org/assets/header/Logo_USAA.svg') no-repeat 0 0;
      background-size: contain;
    }
  }

  @media #{$medium-screen} {
    margin: 0 2rem .4rem 0;

    a {
      &:after {
        top: 1.2rem;
        height: 4rem;
      }
    }
  }

  @media #{$xlarge-screen} {
    margin: .8rem 2rem 0 0;
  }
}
