@import '~org/styles/variables';

$recentStoresFlyoutBorder: 2px solid #d8e5e4 !default;
$recentStoresFlyoutBorderWidth: () !default;
$recentStoresFlyoutBorderZIndex: 100 !default;
$recentStoresFlyoutHeadBorder: none !default;
$recentStoresFlyoutHeadBgColor: #fff !default;
$recentStoresFlyoutHeadBorderRadius: 1.5rem 1.5rem 0 0 !default;
$recentStoresFlyoutH2Padding: 0 1.2rem !default;
$recentStoresFlyoutH2Color: () !default;
$recentStoresFlyoutH2FontSize: 2rem !default;
$recentStoresFlyoutH2LineHeight: 4rem !default;
$recentStoresFlyoutH2FontWeight: normal !default;
$recentStoresFlyoutH2TextTransform: () !default;
$recentStoresFlyoutBoxShadow: () !default;
$recentStoresFlyoutHeadBoxShadow: $recentStoresFlyoutBoxShadow !default;
$recentStoresFlyoutMinHeight: 15.5rem !default;
$recentStoresFlyoutBackground: #fff !default;
$recentStoresFlyoutLogoContainerMinHeight: inherit !default;
$recentStoresFlyoutLogoListWidth: 10rem !default;
$recentStoresFlyoutLogoListBgColor: #fff !default;
$recentStoresFlyoutLogoItemMargin: .7rem auto !default;

.mn_recentStoresFlyout {
  position: fixed;
  left: auto;
  right: 0;
  bottom: 50%;
  border: $recentStoresFlyoutBorder;
  border-width: $recentStoresFlyoutBorderWidth;
  z-index: $recentStoresFlyoutBorderZIndex;
  box-shadow: $recentStoresFlyoutBoxShadow;
  background: $recentStoresFlyoutBackground;
  min-height: $recentStoresFlyoutMinHeight;

  &.mn_flyoutInfoHide {
    transform: translate(100%, 50%);
    transition-duration: 600ms;
  }

  &.mn_flyoutInfoShow {
    transform: translate(0, 50%);
    transition-duration: 600ms;
  }

  .mn_head {
    transform: rotate(-90deg) translate3d(-50%, 0, 0);
    transform-origin: left bottom;
    width: auto;
    border: $recentStoresFlyoutHeadBorder;
    margin: 0;
    background-color: $recentStoresFlyoutHeadBgColor;
    border-radius: $recentStoresFlyoutHeadBorderRadius;
    white-space: nowrap;
    position: absolute;
    bottom: 50%;
    cursor: pointer;
    box-shadow: $recentStoresFlyoutHeadBoxShadow;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    h2 {
      margin: 0;
      padding: $recentStoresFlyoutH2Padding;
      text-align: center;
      color: $recentStoresFlyoutH2Color;
      font-size: $recentStoresFlyoutH2FontSize;
      line-height: $recentStoresFlyoutH2LineHeight;
      font-weight: $recentStoresFlyoutH2FontWeight;
      text-transform: $recentStoresFlyoutH2TextTransform;
    }
  }

  .mn_logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-collapse: collapse;
    margin: 0;
    min-height: $recentStoresFlyoutLogoContainerMinHeight;

    .mn_logoList {
      text-align: center;
      width: $recentStoresFlyoutLogoListWidth;
      vertical-align: middle;
      background-color: $recentStoresFlyoutLogoListBgColor;
    }

    .mn_logoItem {
      margin: $recentStoresFlyoutLogoItemMargin;
      padding: 0;
      width: 88px;
      height: 31px;
    }
  }
}
