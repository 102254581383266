@import '~org/styles/variables';

.mn_editEmailPreferencesModal {
  .mn_modalContent {
    .editPreferencesSection {
      h4,
      .mn_edit {
        text-transform: uppercase;
        margin: 0;
        font-size: 1.4rem;
        font-weight: 700;
        letter-spacing: 1.05px;
      }

      #mn_dialogTitle {
        font-size: 3.2rem;
        font-weight: 500;
        margin: 0 0 1.5rem;
        line-height: 1.2;
        color: #000;
      }

      #mn_dialogDescription {
        margin: 2rem 0;
        font-size: 1.6rem;
        line-height: 2.4rem;
      }

      strong {
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 500;
        display: inline-block;
        margin-bottom: .5rem;
      }

      ul {
        list-style-type: none;
        padding: 0;

        .mn_unsubscribeListItem {
          margin-top: 1.5rem;
          border-top: 1px solid #ccc;
          padding-top: 2.5rem;

          strong {
            margin: 0 3rem 1rem 0;
          }
        }
      }

      .mn_checkbox {
        display: flex;
        flex-direction: row;

        p {
          padding: 0 0 0 3rem;
          font-size: 1.4rem;
          line-height: 2.4rem;
          font-weight: 400;
          margin: -2.2rem 0 1rem;
        }

        input[checked='checked'] + label:before {
          border-color: transparent;
        }
      }
    }

    .mn_formButtonsWrap {
      margin: 1rem 0 0;
      display: flex;
      justify-content: space-between;

      .mn_button {
        width: 48%;
        min-width: 14rem;
        padding: 1.4rem 1rem;
        font-size: 1.6rem;
      }
    }
  }
}
