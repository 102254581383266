@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?68225196');
  src: url('../font/fontello.eot?68225196#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?68225196') format('woff2'),
       url('../font/fontello.woff?68225196') format('woff'),
       url('../font/fontello.ttf?68225196') format('truetype'),
       url('../font/fontello.svg?68225196#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?68225196#fontello') format('svg');
  }
}
*/
 
 [class^="fa-"]:before, [class*=" fa-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.fa-flight:before { content: '\e800'; } /* '' */
.fa-ok:before { content: '\e801'; } /* '' */
.fa-circle-empty:before { content: '\e802'; } /* '' */
.fa-ok-circle:before { content: '\e803'; } /* '' */
.fa-calendar:before { content: '\e804'; } /* '' */
.fa-search:before { content: '\f002'; } /* '' */
.fa-heart:before { content: '\f004'; } /* '' */
.fa-user:before { content: '\f007'; } /* '' */
.fa-th-large:before { content: '\f009'; } /* '' */
.fa-th-list:before { content: '\f00b'; } /* '' */
.fa-check:before { content: '\f00c'; } /* '' */
.fa-times:before { content: '\f00d'; } /* '' */
.fa-home:before { content: '\f015'; } /* '' */
.fa-tag:before { content: '\f02b'; } /* '' */
.fa-tags:before { content: '\f02c'; } /* '' */
.fa-bookmark:before { content: '\f02e'; } /* '' */
.fa-to-start:before { content: '\f048'; } /* '' */
.fa-play:before { content: '\f04b'; } /* '' */
.fa-pause:before { content: '\f04c'; } /* '' */
.fa-to-end:before { content: '\f051'; } /* '' */
.fa-chevron-left:before { content: '\f053'; } /* '' */
.fa-chevron-right:before { content: '\f054'; } /* '' */
.fa-plus-circled:before { content: '\f055'; } /* '' */
.fa-minus-circled:before { content: '\f056'; } /* '' */
.fa-cancel-circled:before { content: '\f057'; } /* '' */
.fa-check-circle:before { content: '\f058'; } /* '' */
.fa-ok-circled2:before { content: '\f05d'; } /* '' */
.fa-right-big:before { content: '\f061'; } /* '' */
.fa-plus:before { content: '\f067'; } /* '' */
.fa-minus:before { content: '\f068'; } /* '' */
.fa-attention:before { content: '\f071'; } /* '' */
.fa-down-open:before { content: '\f077'; } /* '' */
.fa-chevron-up:before { content: '\f078'; } /* '' */
.fa-basket:before { content: '\f07a'; } /* '' */
.fa-thumbs-up:before { content: '\f087'; } /* '' */
.fa-thumbs-down:before { content: '\f088'; } /* '' */
.fa-heart-empty:before { content: '\f08a'; } /* '' */
.fa-bookmark-empty:before { content: '\f097'; } /* '' */
.fa-twitter:before { content: '\f099'; } /* '' */
.fa-facebook:before { content: '\f09a'; } /* '' */
.fa-bars:before { content: '\f0c9'; } /* '' */
.fa-caret-up:before { content: '\f0d8'; } /* '' */
.fa-bell-alt:before { content: '\f0f3'; } /* '' */
.fa-angle-double-left:before { content: '\f100'; } /* '' */
.fa-angle-double-right:before { content: '\f101'; } /* '' */
.fa-angle-double-down:before { content: '\f103'; } /* '' */
.fa-angle-left:before { content: '\f104'; } /* '' */
.fa-angle-right:before { content: '\f105'; } /* '' */
.fa-angle-up:before { content: '\f106'; } /* '' */
.fa-angle-down:before { content: '\f107'; } /* '' */
.fa-circle-notch:before { content: '\f10c'; } /* '' */
.fa-quote-left:before { content: '\f10d'; } /* '' */
.fa-quote-right:before { content: '\f10e'; } /* '' */
.fa-exclamation:before { content: '\f12a'; } /* '' */
.fa-chevron-circle-left:before { content: '\f137'; } /* '' */
.fa-chevron-circle-right:before { content: '\f138'; } /* '' */
.fa-chevron-circle-up:before { content: '\f139'; } /* '' */
.fa-angle-circled-down:before { content: '\f13a'; } /* '' */
.fa-ellipsis:before { content: '\f141'; } /* '' */
.fa-ellipsis-vert:before { content: '\f142'; } /* '' */
.fa-play-circled:before { content: '\f144'; } /* '' */
.fa-minus-squared-alt:before { content: '\f147'; } /* '' */
.fa-youtube:before { content: '\f167'; } /* '' */
.fa-left:before { content: '\f177'; } /* '' */
.fa-plus-squared-alt:before { content: '\f196'; } /* '' */
.fa-building-filled:before { content: '\f1ad'; } /* '' */
.fa-circle-thin:before { content: '\f1db'; } /* '' */
.fa-trash:before { content: '\f1f8'; } /* '' */
.fa-question-circle-o:before { content: '\f29c'; } /* '' */