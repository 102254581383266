@import '~org/styles/variables';

$similarStoresBackground: #fff !default;
$similarStoresMargin: () !default;
$similarStoresPadding: 1.2rem .7rem !default;
$similarStoresBorderRadius: () !default;
$similarStoresTitleJustifyContent: space-between !default;
$similarStoresTitleMargin: 0 0 1rem !default;
$similarStoresTitlePadding: () !default;
$similarStoresTitleBorderBottom: () !default;
$similarStoresTitleTextAlign: () !default;
$similarStoresTitleFontWeight: bold !default;
$similarStoresTitleColor: () !default;
$similarStoresTitleFontSize: 1.8rem !default;
$similarStoresTitleHeadingMargin: () !default;
$similarStoresTitleHeadingTextTransform: () !default;
$similarStoresTitleHeadingLetterSpacing: () !default;

$similarStoresContentMargin: () !default;

$similarStoreShopNowButtonHeight: 2.7rem !default;
$similarStoreShopNowButtonWidth: 10.4rem !default;
$similarStoreShopNowButtonLineHeight: $similarStoreShopNowButtonHeight !default;
$similarStoreShopNowButtonFontSize: 1.2rem !default;
$similarStoreShopNowButtonLetterSpacing: .04px !default;

$similarStoresInSearchPadding: () !default;
$similarStoresInSearchBackground: () !default;
$similarStoresInSearchBorderRadius: () !default;
$similarStoresInSearchTitleMargin: () !default;
$similarStoresInSearchTitleColor: () !default;

.mn_similarStores {
  margin: $similarStoresMargin;
  background: $similarStoresBackground;
  padding: $similarStoresPadding;
  border-radius: $similarStoresBorderRadius;

  .mn_sectHead {
    display: flex;
    justify-content: $similarStoresTitleJustifyContent;
    margin: $similarStoresTitleMargin;
    padding: $similarStoresTitlePadding;
    border-bottom: $similarStoresTitleBorderBottom;

    h3 {
      color: $similarStoresTitleColor;
      font-weight: $similarStoresTitleFontWeight;
      font-size: $similarStoresTitleFontSize;
      margin: $similarStoresTitleHeadingMargin;
      text-transform: $similarStoresTitleHeadingTextTransform;
      letter-spacing: $similarStoresTitleHeadingLetterSpacing;
    }
  }

  .mn_sectContainer {
    margin: $similarStoresContentMargin;
  }
}

/* If placed inside the search form */
.mn_dropDownWrap {
  .mn_similarStores {
    background: $similarStoresInSearchBackground;
    border-radius: $similarStoresInSearchBorderRadius;
    padding: map_get($similarStoresInSearchPadding, medium);
    color: #fff;

    @media #{$less-than-medium-screen} {
      padding: map_get($similarStoresInSearchPadding, small);
    }

    h3 {
      color: $similarStoresInSearchTitleColor;
      margin: $similarStoresInSearchTitleMargin;
    }
  }
}
