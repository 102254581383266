@import '~org/styles/variables';

$premierRebateColor: $rebateColor !default;
$premierRebateFontSize: 1.8rem !default;
$premierRebateFontWeight: () !default;
$premierRebatePrefixColor: () !default;
$premierRebatePrefixFontsWeight: () !default;
$premierRebateSuffixColor: () !default;

$premierRebateValueColor: $rebateValueColor !default;
$premierRebateValueFontSize: () !default;
$premierRebateValueWithCurrencyFontWeight: $rebateValueWithCurrencyFontWeight !default;
$premierRebateElevationColor: $rebateElevationColor !default;
$premierRebateValueWithCurrencyOldValueFontWeight: normal !default;
$premierRebateTieredAdditionalPrefixColor: $premierRebateElevationColor !default;
$premierRebateTieredAdditionalPrefixFontWeight: $premierRebateValueWithCurrencyOldValueFontWeight !default;
$premierRebatePrefixTieredFirstLetterTextTransform: () !default;
$premierRebateNewValueFontSize: () !default;
$premierRebateValueWithCurrencyFontSize: () !default;
$premierRebateValueWithCurrencyOldValueFontSize: () !default;
$premierRebateValueWithCurrencyLetterSpacing: () !default;
$premierRebateOldValueAfterContent: ', ' !default;
$premierRebateValueWithCurrencyOldValueTextTransform: () !default;
$premierRebateValueWithCurrencyOldValueFirstLetterTextTransform: () !default;
$premierRebateValueWithCurrencyOldValueDisplay: () !default;
$premierRebateNewValueWhiteSpace: nowrap !default;
$premierRebateInlineTextTransform: () !default;
$premierRebateValueTierNameTextTransform: () !default;

.mn_premierMerchantRebate {
  color: $premierRebateColor;
  font-size: $premierRebateFontSize;
  font-weight: $premierRebateFontWeight;

  .mn_tierName {
    color: $premierRebateValueColor;
    font-weight: $premierRebateValueWithCurrencyFontWeight;
    font-size: $premierRebateValueFontSize;
    text-transform: $premierRebateValueTierNameTextTransform;
    white-space: initial;
  }

  .mn_blockRebate {
    .mn_elevationOldValue {
      font-size: 1.4rem;
    }
  }

  .mn_tieredAdditionalPrefix {
    color: $premierRebateTieredAdditionalPrefixColor;
    font-weight: $premierRebateTieredAdditionalPrefixFontWeight;
  }

  .mn_inlineRebate {
    text-transform: $premierRebateInlineTextTransform;

    .mn_tieredAdditionalPrefix,
    .mn_elevationOldValue,
    .mn_elevationNewValue {
      display: inline;
    }

    .mn_elevationNewValue {
      white-space: $premierRebateNewValueWhiteSpace;
      font-size: $premierRebateNewValueFontSize;
    }

    .mn_elevationOldValue:after {
      content: $premierRebateOldValueAfterContent;
    }
  }

  .mn_rebatePrefix {
    color: $premierRebatePrefixColor;
    font-weight: $premierRebatePrefixFontsWeight;
  }

  .mn_rebateValue:not(.mn_rebateElevated) {
    .mn_tieredPrefix {
      display: inline-block;

      &:first-letter {
        text-transform: $premierRebatePrefixTieredFirstLetterTextTransform;
      }
    }
  }

  .mn_rebateValueWithCurrency {
    font-weight: $premierRebateValueWithCurrencyFontWeight;
    font-size: $premierRebateValueWithCurrencyFontSize;
    letter-spacing: $premierRebateValueWithCurrencyLetterSpacing;

    .mn_rebateValue {
      color: $premierRebateValueColor;

      .mn_elevationOldValue {
        color: $premierRebateElevationColor;
        font-weight: $premierRebateValueWithCurrencyOldValueFontWeight;
        font-size: $premierRebateValueWithCurrencyOldValueFontSize;
        text-transform: $premierRebateValueWithCurrencyOldValueTextTransform;
        display: $premierRebateValueWithCurrencyOldValueDisplay;

        &:first-letter {
          text-transform: $premierRebateValueWithCurrencyOldValueFirstLetterTextTransform;
        }
      }
    }
  }

  .mn_rebateSuffix {
    color: $premierRebateSuffixColor;
  }
}
