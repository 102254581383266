@import '~org/styles/variables';

$recentStoresBackground: #fff !default;
$recentStoresPadding: 1.2rem .7rem !default;
$recentStoresMargin: () !default;
$recentStoresBorderRadius: () !default;
$recentStoresTextAlign: () !default;
$recentStoresTitleJustifyContent: space-between !default;
$recentStoresTitleMargin: 0 0 1rem !default;
$recentStoresTitlePadding: () !default;
$recentStoresTitleBorderBottom: () !default;
$recentStoresTitleTextAlign: () !default;
$recentStoresTitleFontWeight: bold !default;
$recentStoresTitleFontSize: 1.8rem !default;
$recentStoresTitleHeadingMargin: () !default;
$recentStoresTitleHeadingTextTransform: () !default;
$recentStoresTitleHeadingLetterSpacing: () !default;
$recentStoresTitleHeadingColor: () !default;
$recentStoresTitleFontFamily: () !default;

$recentStoresContentMargin: () !default;

$recentStoresViewAllLinkBottomFontWeight: () !default;
$recentStoresViewAllLinkBottomMargin: () !default;
$recentStoresViewAllLinkPadding: () !default;
$recentStoresViewAllLinkBottomFontSize: () !default;
$recentStoresViewAllLinkBottomColor: () !default;
$recentStoresViewAllLinkTextDecoration: () !default;
$recentStoresViewAllLinkTextDecorationHover: () !default;

$recentStoreShopNowButtonHeight: 2.7rem !default;
$recentStoreShopNowButtonWidth: 10.4rem !default;
$recentStoreShopNowButtonLineHeight: $recentStoreShopNowButtonHeight !default;
$recentStoreShopNowButtonFontSize: 1.2rem !default;
$recentStoreShopNowButtonLetterSpacing: .04px !default;

$recentStoresInSearchPadding: () !default;
$recentStoresInSearchBackground: () !default;
$recentStoresInSearchBorderRadius: () !default;
$recentStoresInSearchTitleMargin: () !default;
$recentStoresInSearchTitleColor: () !default;
$recentStoresInSearchViewAllBottomLinkColor: () !default;
$recentStoresInSearchViewAllBottomLinkMargin: () !default;
$recentStoresInSearchPosition: () !default;

$recentStoresInSearchViewAllBottomLinkAfterContent: () !default;
$recentStoresInSearchViewAllBottomLinkAfterFontFamily: () !default;
$recentStoresInSearchViewAllBottomLinkAfterFontSize: () !default;
$recentStoresInSearchViewAllBottomLinkAfterFontWeight: () !default;
$recentStoresInSearchViewAllBottomLinkAfterColor: () !default;
$recentStoresInSearchViewAllBottomLinkAfterLineHeight: () !default;
$recentStoresInSearchViewAllBottomLinkAfterPosition: () !default;
$recentStoresInSearchViewAllBottomLinkAfterBottom: () !default;
$recentStoresInSearchViewAllBottomLinkAfterRight: () !default;

$recentStoresFlipTileBorder: none !default;
$recentStoresFlipTileMargin: () !default;
$recentStoresFlipTileBorderRadius: () !default;
$recentStoresFlipTileFrontBackground: none !default;
$recentStoresFlipTileBackBackground: rgba(182, 184, 220, .25) !default;
$recentStoresFlipTileRebateColor: () !default;
$recentStoresFlipTileRebateValue: () !default;
$recentStoresFlipTileRebateFontSize: () !default;

$recentStoresFlipTileRebateElevationNowPrefixDisplay: () !default;
$recentStoresFlipTileRebateTieredPrefixFirstLetterTextTransform: () !default;
$recentStoresFlipTileRebateElevationOldValueDisplay: () !default;
$recentStoresFlipTileRebateElevationOldValueColor: () !default;

$recentStoresFlipTileItemBorderBottom: () !default;

$recentStoresMHPFlipTileItemBorderBottom: () !default;

.mn_recentStores {
  background: $recentStoresBackground;
  padding: $recentStoresPadding;
  margin: $recentStoresMargin;
  border-radius: $recentStoresBorderRadius;
  text-align: $recentStoresTextAlign;

  .mn_sectHead {
    display: flex;
    justify-content: $recentStoresTitleJustifyContent;
    margin: $recentStoresTitleMargin;
    padding: $recentStoresTitlePadding;
    border-bottom: $recentStoresTitleBorderBottom;

    h3 {
      font-family: $recentStoresTitleFontFamily;
      font-weight: $recentStoresTitleFontWeight;
      font-size: $recentStoresTitleFontSize;
      margin: $recentStoresTitleHeadingMargin;
      text-transform: $recentStoresTitleHeadingTextTransform;
      letter-spacing: $recentStoresTitleHeadingLetterSpacing;
      color: $recentStoresTitleHeadingColor;
    }

    a {
      font-size: 1.2rem;
    }
  }

  .mn_sectContainer {
    margin: $recentStoresContentMargin;
  }

  .mn_viewAllRecentStoresLinkBottom {
    display: inline-block;
    overflow: hidden;
    font-weight: $recentStoresViewAllLinkBottomFontWeight;
    margin: $recentStoresViewAllLinkBottomMargin;
    padding: $recentStoresViewAllLinkPadding;
    font-size: $recentStoresViewAllLinkBottomFontSize;
    color: $recentStoresViewAllLinkBottomColor;
    text-decoration: $recentStoresViewAllLinkTextDecoration;

    &:hover {
      text-decoration: $recentStoresViewAllLinkTextDecorationHover;
    }
  }
}

/* If placed inside the search form */
.mn_dropDownWrap {
  .mn_recentStores {
    background: $recentStoresInSearchBackground;
    border-radius: $recentStoresInSearchBorderRadius;
    padding: map_get($recentStoresInSearchPadding, medium);
    position: $recentStoresInSearchPosition;
    color: #fff;

    @media #{$less-than-medium-screen} {
      padding: map_get($recentStoresInSearchPadding, small);
    }

    h3 {
      color: $recentStoresInSearchTitleColor;
      margin: $recentStoresInSearchTitleMargin;
    }

    .mn_viewAllRecentStoresLinkBottom {
      color: $recentStoresInSearchViewAllBottomLinkColor;
      margin: $recentStoresInSearchViewAllBottomLinkMargin;

      &:after {
        content: $recentStoresInSearchViewAllBottomLinkAfterContent;
        font-family: $recentStoresInSearchViewAllBottomLinkAfterFontFamily;
        font-size: $recentStoresInSearchViewAllBottomLinkAfterFontSize;
        font-weight: $recentStoresInSearchViewAllBottomLinkAfterFontWeight;
        color: $recentStoresInSearchViewAllBottomLinkAfterColor;
        line-height: $recentStoresInSearchViewAllBottomLinkAfterLineHeight;
        position: $recentStoresInSearchViewAllBottomLinkAfterPosition;
        bottom: $recentStoresInSearchViewAllBottomLinkAfterBottom;
        right: $recentStoresInSearchViewAllBottomLinkAfterRight;
      }
    }

    .mn_flipTile {
      border: $recentStoresFlipTileBorder;
      margin: $recentStoresFlipTileMargin;
      border-radius: $recentStoresFlipTileBorderRadius;

      .mn_itemContent {
        border-bottom: $recentStoresFlipTileItemBorderBottom;
      }

      .mn_front {
        background: $recentStoresFlipTileFrontBackground;
      }

      .mn_back {
        background: $recentStoresFlipTileBackBackground;
      }

      .mn_premierMerchantRebate {
        color: $recentStoresFlipTileRebateColor;
        font-size: $recentStoresFlipTileRebateFontSize;

        .mn_rebateValue {
          color: $recentStoresFlipTileRebateValue;
        }

        .mn_elevationNowPrefix {
          display: $recentStoresFlipTileRebateElevationNowPrefixDisplay;
        }

        .mn_tieredPrefix:first-letter {
          text-transform: $recentStoresFlipTileRebateTieredPrefixFirstLetterTextTransform;
        }

        .mn_elevationOldValue {
          display: $recentStoresFlipTileRebateElevationOldValueDisplay;
          color: $recentStoresFlipTileRebateElevationOldValueColor;
        }
      }
    }
  }
}
