@import '~org/styles/variables';

$holidayImageMargin: (small: 0, medium: (), xlarge: ()) !default;
$holidayVideoContentHeight: (small: 4.5rem, medium: unset, xlarge: unset) !default;
$holidayImageContentHeight: (small: 4.5rem, medium: 5.9rem, xlarge: 6.5rem) !default;
$holidayImageContentBackgroundSize: (small: cover, medium: cover, xlarge: contain) !default;

.mn_holidayImage {
  margin: map_get($holidayImageMargin, small);

  .mn_imageWrap {
    height: map_get($holidayImageContentHeight, small);
    background-size: map_get($holidayImageContentBackgroundSize, small);
    background-position: center top;
    background-repeat: no-repeat no-repeat;
  }

  .mn_holidayVideo {
    height: map_get($holidayVideoContentHeight, small);
    clip-path: inset(0 0 2px 0); // fix for black line of non standart proportion video
    width: 100%;
    object-fit: cover;
    object-position: center;
    display: none;
  }

  @media #{$medium-screen} {
    margin: map_get($holidayImageMargin, medium);

    .mn_imageWrap {
      height: map_get($holidayImageContentHeight, medium);
      background-size: map_get($holidayImageContentBackgroundSize, medium);
    }

    .mn_holidayVideo {
      height: map_get($holidayVideoContentHeight, medium);
    }
  }

  @media #{$xlarge-screen} {
    margin: map_get($holidayImageMargin, xlarge);

    .mn_imageWrap {
      height: map_get($holidayImageContentHeight, xlarge);
      background-size: map_get($holidayImageContentBackgroundSize, xlarge);
    }

    .mn_holidayVideo {
      height: map_get($holidayVideoContentHeight, xlarge);
    }
  }

  .mn_sticky & {
    display: none;
  }
}
