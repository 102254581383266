@import '~org/styles/variables';

$searchBarPadding: 1rem 1.5rem !default;
$searchBarBackgroundColor: () !default;
$searchBarDesktopWidth: 27.5rem !default;
$searchBarDesktopBeforeDisplay: none !default;
$searchBarDesktopBeforeContent: () !default;
$searchBarDesktopBeforeWidth: () !default;
$searchBarDesktopBeforePosition: () !default;
$searchBarDesktopBeforeLeft: () !default;
$searchBarDesktopBeforeHeight: () !default;
$searchBarDesktopBeforeBackground: () !default;
$searchBarDesktopPadding: () !default;

.mn_searchBar {
  padding: $searchBarPadding;
  opacity: 1;
  background: $searchBarBackgroundColor;

  &.mn_searchBarHidden {
    opacity: 0;
    padding: 0;
    height: 0;
    overflow: hidden;
  }
}

/* Desktop header Search */
.mn_searchAndNavWrap {
  .mn_searchBar {
    width: $searchBarDesktopWidth;
    padding: $searchBarDesktopPadding;

    &:before {
      display: $searchBarDesktopBeforeDisplay;
      content: $searchBarDesktopBeforeContent;
      width: $searchBarDesktopBeforeWidth;
      position: $searchBarDesktopBeforePosition;
      left: $searchBarDesktopBeforeLeft;
      height: $searchBarDesktopBeforeHeight;
      background: $searchBarDesktopBeforeBackground;
    }
  }
}
