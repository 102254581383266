@import '~org/styles/variables';

.mn_previewTool {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 3rem;
  row-gap: 1rem;
  position: relative;
  z-index: 999999;
  padding: 1.5rem 0 1.5rem 4rem;
  background: #f3c;
  text-align: left;
  color: #000;
  font: normal 1.4rem Arial, Helvetica, sans-serif;

  .mn_prevToolIn {
    display: flex;
    justify-content: space-between;
  }

  .mn_prevDateTxt {
    font-weight: bold;
  }

  .mn_prevChangeDate {
    display: flex;
    column-gap: 1rem;

    .mn_prevLeft {
      display: flex;
      align-items: center;
    }

    .mn_prevMid {
      display: flex;
      column-gap: .1rem;
    }
  }

  .mn_prevClose {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    margin: 0;
    background: url('~core/assets/images/windows_close_icon.png');
    border: none;
    cursor: pointer;
  }

  select {
    margin: 0;
    min-width: 8rem;
    min-height: 3rem;
    -webkit-appearance: auto;
    background: #fff;
    font-size: 1.4rem;
    line-height: 1.4rem;
    border: 1px solid #666;
    padding: 0;
    border-radius: 0;
    font-weight: normal;
    color: #000;
  }

  input {
    width: 15rem;
    font-size: 1.4rem;
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
  }

  .mn_refreshButton {
    color: #000;
    font-size: 1.4rem;
    line-height: normal;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
  }
}
