@import '~org/styles/variables';

$similarStoresRebateFontSize: 1.4rem !default;
$similarStoresRebateLineHeight: 1.8rem !default;
$similarStoresRebateValueFontSize: () !default;
$similarStoresRebateOldValueColor: () !default;
$similarStoresRebateOldValueFontSize: () !default;
$similarStoresRebateOldValueAfterContent: () !default;
$similarStoresRebateNewValueFontSize: () !default;
$similarStoresRebatePrefixColor: () !default;
$similarStoresRebatePrefixFontWeight: () !default;
$similarStoresRebateNewValueColor: () !default;
$similarStoresRebateFontWeight: () !default;
$similarStoresRebateTieredPrefixFirstLetterTextTransform: () !default;
$similarStoresFavoriteIconWidth: (small: 2.1rem, medium: 2.1rem) !default;
$similarStoresFavoriteIconHeight: (small: 2.3rem, medium: 2.3rem) !default;
$similarStoresFavoriteIconMargin: .7rem 0 0 0 !default;
$similarStoreMargin: .5rem 0 !default;
$similarStoreOverflow: () !default;
$similarStoreDisplay: () !default;
$similarStoreBorder: 1px solid #e7ecef !default;
$similarStoreBorderRadius: 2px !default;
$similarStoreBorderWidth: () !default;
$similarStoreLastItemBorderWidth: () !default;
$similarStoreItemHeight: 6rem !default;
$similarStoreItemBorder: () !default;
$similarStoreItemMargin: () !default;
$similarStoreLogoMargin: 0 auto 0 2rem !default;
$similarStoreLogoPadding: () !default;
$similarStoreLogoBackground: () !default;
$similarStoreLogoBorderRadius: () !default;
$similarStoreRebateWrapMargin: () !default;
$similarStoreRebatePadding: 0 0 0 1rem !default;
$similarStoreRebateTextAlign: () !default;
$similarStoreFavoriteIconOrder: () !default;
$similarStoreFavoriteIconHeight: () !default;
$similarStoreFavoriteIconMarginLeft: () !default;
$similarStoreJustifyContent: space-between !default;
$similarStorePadding: 0 1rem !default;
$similarStoreBackground: #fff !default;
$similarStoreBorderBottom: () !default;
$similarStoresInSearchItemBackground: () !default;
$similarStoresInSearchLogoPadding: () !default;
$similarStoresInSearchLogoMargin: () !default;
$similarStoresInSearchLogoBorderRadius: () !default;
$similarStoresInSearchRebateOldValueColor: () !default;
$similarStoresInSearchRebateNewValueColor: () !default;
$similarStoresInSearchItemBorder: (small: none, medium: none) !default;
$similarStoresInSearchItemBorderWidth: () !default;
$similarStoresInSearchItemBackBackground: () !default;

.mn_similarStoreTile {
  display: $similarStoreDisplay;
  border: $similarStoreBorder;
  border-radius: $similarStoreBorderRadius;
  border-width: $similarStoreBorderWidth;
  margin: $similarStoreMargin;
  overflow: $similarStoreOverflow;

  &:last-child {
    border-width: $similarStoreLastItemBorderWidth;
  }

  .mn_rebateWrap {
    margin: $similarStoreRebateWrapMargin;

    .mn_premierMerchantRebate {
      padding: $similarStoreRebatePadding;
      text-align: $similarStoreRebateTextAlign;
      font-size: $similarStoresRebateFontSize;
      line-height: $similarStoresRebateLineHeight;

      &,
      .mn_rebateValueWithCurrency .mn_rebateValue {
        color: $similarStoresRebateNewValueColor;
        font-weight: $similarStoresRebateFontWeight;
        font-size: $similarStoresRebateValueFontSize;

        .mn_tieredPrefix:first-letter {
          text-transform: $similarStoresRebateTieredPrefixFirstLetterTextTransform;
        }
      }

      .mn_elevationOldValue {
        color: $similarStoresRebateOldValueColor;
        font-size: $similarStoresRebateOldValueFontSize;

        &:after {
          content: $similarStoresRebateOldValueAfterContent;
        }
      }

      .mn_elevationNewValue {
        font-size: $similarStoresRebateNewValueFontSize;
      }

      .mn_rebatePrefix {
        color: $similarStoresRebatePrefixColor;
        font-weight: $similarStoresRebatePrefixFontWeight;
      }
    }
  }

  .mn_favoriteIcon {
    width: map-get($similarStoresFavoriteIconWidth, small);
    height: map-get($similarStoresFavoriteIconHeight, small);
    margin: $similarStoresFavoriteIconMargin;
  }

  .mn_logoWrap {
    margin: $similarStoreLogoMargin;
    padding: $similarStoreLogoPadding;
    background: $similarStoreLogoBackground;
    border-radius: $similarStoreLogoBorderRadius;
    width: 88px;
    min-width: 88px;
    flex: 0 0 88px;

    img {
      width: 88px;
      height: 31px;
    }
  }

  .mn_itemContent {
    display: flex;
    justify-content: $similarStoreJustifyContent;
    align-items: center;
    width: 100%;
    height: $similarStoreItemHeight;
    border: $similarStoreItemBorder;
    margin: $similarStoreItemMargin;
    border-radius: $similarStoreBorderRadius;
    padding: $similarStorePadding;
    background: $similarStoreBackground;
    border-bottom: $similarStoreBorderBottom;
    z-index: 2;

    .mn_favoritesWrap {
      order: $similarStoreFavoriteIconOrder;
      height: $similarStoreFavoriteIconHeight;
      margin-left: $similarStoreFavoriteIconMarginLeft;
    }
  }

  @media #{$medium-screen} {
    .mn_favoriteIcon {
      width: map-get($similarStoresFavoriteIconWidth, medium);
      height: map-get($similarStoresFavoriteIconHeight, medium);
    }
  }
}

/* If placed inside the search form */
.mn_dropDownWrap .mn_similarStoreTile {
  border: map-get($similarStoresInSearchItemBorder, small);
  border-width: map-get($similarStoresInSearchItemBorderWidth, small);

  &:hover {
    background: $similarStoresInSearchItemBackBackground;
    text-decoration: none;
  }

  .mn_itemContent {
    background: $similarStoresInSearchItemBackground;
  }

  .mn_logoWrap {
    padding: $similarStoresInSearchLogoPadding;
    margin: $similarStoresInSearchLogoMargin;
    box-sizing: content-box;
    background: #fff;
    border-radius: $similarStoresInSearchLogoBorderRadius;
  }

  .mn_rebateWrap {
    .mn_premierMerchantRebate {
      &,
      .mn_rebateValueWithCurrency .mn_rebateValue {
        color: $similarStoresInSearchRebateNewValueColor;
      }

      .mn_elevationOldValue {
        color: $similarStoresInSearchRebateOldValueColor;
      }
    }
  }

  @media #{$medium-screen} {
    border: map-get($similarStoresInSearchItemBorder, medium);
    border-width: map-get($similarStoresInSearchItemBorderWidth, medium);
  }
}
