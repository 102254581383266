@import '~org/styles/variables';

$navSeasonalFlyoutMargin: 5rem 0 0 0 !default;

$navSeasonalFlyoutMerchantTileListJustifyContent: () !default;
$navSeasonalFlyoutMerchantTileListMargin: () !default;

$navSeasonalFlyoutTileWrapperMargin: 0 0 2.5rem 0 !default;
$navSeasonalFlyoutTileWidth: 12.2rem !default;
$navSeasonalFlyoutTileHeight: 15.6rem !default;
$navSeasonalFlyoutTileBorder: () !default;
$navSeasonalFlyoutTileBorderRadius: () !default;
$navSeasonalFlyoutTileMargin: () !default;
$navSeasonalFlyoutTileTop: () !default;
$navSeasonalFlyoutTileBottom: () !default;
$navSeasonalFlyoutTileRight: () !default;
$navSeasonalFlyoutTileLeft: () !default;

$navSeasonalFlyoutTileImgMargin: () !default;

$navSeasonalFlyoutTileFavoriteIconMarginTop: .3rem !default;
$navSeasonalFlyoutTileFavoriteIconPositionRight: () !default;

$navSeasonalFlyoutTilePillPositionTop: 45% !default;
$navSeasonalFlyoutTilePillWidth: () !default;
$navSeasonalFlyoutTilePillHeight: () !default;
$navSeasonalFlyoutTilePillLabelFontSize: () !default;
$navSeasonalFlyoutTilePillLabelLineHeight: () !default;
$navSeasonalFlyoutTilePillLabelPadding: () !default;
$navSeasonalFlyoutTilePillLabelAfterWidth: () !default;
$navSeasonalFlyoutTilePillLabelAfterHeight: () !default;

$navSeasonalFlyoutRebateHeight: () !default;
$navSeasonalFlyoutRebatePositionTop: 8rem !default;
$navSeasonalFlyoutRebateElevationNewValueFontSize: () !default;

$navSeasonalFlyoutTileHoverCursor: pointer;

$navSeasonalFlyoutCTAWrapMargin: () !default;
$navSeasonalFlyoutCTAColor: #fff !default;
$navSeasonalFlyoutCTAFontSize: () !default;
$navSeasonalFlyoutCTAFontWeight: () !default;
$navSeasonalFlyoutCTATextTransform: () !default;

$navSeasonalFlyoutContainerDisplay: flex !default;
$navSeasonalFlyoutContainerFlexWrap: wrap !default;
$navSeasonalFlyoutContainerJustifyContent: space-between !default;

.mn_navSeasonalFlyoutSection {
  margin: $navSeasonalFlyoutMargin;

  h2 {
    margin-bottom: 3rem;
  }

  .mn_merchantTileList {
    justify-content: $navSeasonalFlyoutMerchantTileListJustifyContent;
    margin: $navSeasonalFlyoutMerchantTileListMargin;
  }

  .mn_merchantTile {
    width: $navSeasonalFlyoutTileWidth;
    height: $navSeasonalFlyoutTileHeight;
    border: $navSeasonalFlyoutTileBorder;
    border-radius: $navSeasonalFlyoutTileBorderRadius;
    margin: $navSeasonalFlyoutTileMargin;

    .mn_tile {
      top: $navSeasonalFlyoutTileTop;
      bottom: $navSeasonalFlyoutTileBottom;
      right: $navSeasonalFlyoutTileRight;
      left: $navSeasonalFlyoutTileLeft;
    }

    img {
      margin: $navSeasonalFlyoutTileImgMargin;
    }

    .mn_favoriteIcon {
      width: 2rem;
      height: 2rem;
      margin-top: $navSeasonalFlyoutTileFavoriteIconMarginTop;
      right: $navSeasonalFlyoutTileFavoriteIconPositionRight;
    }

    .mn_extraRewardsPill {
      top: $navSeasonalFlyoutTilePillPositionTop;
      width: $navSeasonalFlyoutTilePillWidth;
      height: $navSeasonalFlyoutTilePillHeight;

      .mn_pillLabel {
        font-size: $navSeasonalFlyoutTilePillLabelFontSize;
        line-height: $navSeasonalFlyoutTilePillLabelLineHeight;
        padding: $navSeasonalFlyoutTilePillLabelPadding;

        &:after {
          width: $navSeasonalFlyoutTilePillLabelAfterWidth;
          height: $navSeasonalFlyoutTilePillLabelAfterHeight;
        }
      }
    }

    .mn_merchantRebateV2 {
      top: $navSeasonalFlyoutRebatePositionTop;
      height: $navSeasonalFlyoutRebateHeight;

      .mn_elevationNewValue {
        font-size: $navSeasonalFlyoutRebateElevationNewValueFontSize;
      }
    }

    &:hover {
      cursor: $navSeasonalFlyoutTileHoverCursor;
    }
  }

  .mn_offerOverlayLink {
    margin: $navSeasonalFlyoutTileWrapperMargin;

    &:focus .mn_merchantRebateV2 {
      text-decoration: underline;
    }
  }

  .mn_seasonalFlyoutCTAWrap {
    margin: $navSeasonalFlyoutCTAWrapMargin;

    .mn_link {
      color: $navSeasonalFlyoutCTAColor;
      font-size: $navSeasonalFlyoutCTAFontSize;
      font-weight: $navSeasonalFlyoutCTAFontWeight;
      text-transform: $navSeasonalFlyoutCTATextTransform;
    }
  }

  .mn_seasonalFlyoutContainer {
    display: $navSeasonalFlyoutContainerDisplay;
    flex-wrap: $navSeasonalFlyoutContainerFlexWrap;
    justify-content: $navSeasonalFlyoutContainerJustifyContent;
  }
}
