@import '~org/styles/variables.scss';

$modalWrapperBackground: (small: rgba(0, 0, 0, .6), medium: ()) !default;

$modalPositionTop: (small: 20%, medium: ()) !default;
$modalBackground: #fff !default;
$modalPadding: (small: 3rem 1.5rem 1.5rem 1.5rem, medium: 3rem) !default;
$modalWidth: (small: 75rem, medium: ()) !default;
$modalMaxWidth: (small: 100%, medium: ()) !default;
$modalBorderRadius: (small: 0, medium: ()) !default;
$modalBoxShadow: (small: (1px 1px 4px 0 rgba(0, 0, 0, .2), 0 2px 24px 0 rgba(0, 0, 0, .5)), medium: ()) !default;

$modalCloseButtonPositionTop: (small: 1rem, medium: ()) !default;
$modalCloseButtonPositionRight: (small: 1rem, medium: ()) !default;
$modalCloseButtonFontSize: (small: 2rem, medium: ()) !default;
$modalCloseButtonFontWeight: (small: (), medium: ()) !default;
$modalCloseButtonLineHeight: (small: 1, medium: ()) !default;
$modalCloseButtonFontFamily: () !default;
$modalCloseButtonColor: () !default;
$modalCloseButtonOpacity: (small: (), medium: ()) !default;
$modalCloseButtonTransition: () !default;

$modalCloseButtonHoverOpacity: (small: (), medium: ()) !default;

$modalCloseButtonAfterContent: '\2715' !default;

html {
  &.mn_hasModal {
    overflow: hidden;
  }

  &.mn_hasModal.mn_noTouch {
    padding-right: 1.7rem;
  }
}

.mn_modalWrapper {
  z-index: 9999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: auto;
  background: map-get($modalWrapperBackground, small);

  @media #{$medium-screen} {
    background: map-get($modalWrapperBackground, medium);
  }
}

.mn_modal {
  margin: 0 auto;
  z-index: 10000;
  position: absolute;
  left: 0;
  right: 0;
  top: map-get($modalPositionTop, small);
  background: $modalBackground;
  padding: map-get($modalPadding, small);
  animation: showLightbox .3s both;
  width: map-get($modalWidth, small);
  max-width: map-get($modalMaxWidth, small);
  border-radius: map-get($modalBorderRadius, small);
  box-shadow: map-get($modalBoxShadow, small);

  .mn_closeModal {
    background: unset;
    position: absolute;
    padding: 0;
    border: none;
    cursor: pointer;
    z-index: 1;
    right: map-get($modalCloseButtonPositionTop, small);
    top: map-get($modalCloseButtonPositionRight, small);
    font-size: map-get($modalCloseButtonFontSize, small);
    font-weight: map-get($modalCloseButtonFontWeight, small);
    line-height: map-get($modalCloseButtonLineHeight, small);
    font-family: $modalCloseButtonFontFamily;
    color: $modalCloseButtonColor;
    opacity: map-get($modalCloseButtonOpacity, small);
    transition: opacity .2s;

    &:after {
      content: $modalCloseButtonAfterContent;
    }

    &:hover {
      opacity: map-get($modalCloseButtonHoverOpacity, small);
    }
  }

  .mn_modalContent {
    height: 100%;
  }

  @media #{$medium-screen} {
    top: map-get($modalPositionTop, medium);
    padding: map-get($modalPadding, medium);
    width: map-get($modalWidth, medium);
    max-width: map-get($modalMaxWidth, medium);
    border-radius: map-get($modalBorderRadius, medium);
    box-shadow: map-get($modalBoxShadow, medium);

    .mn_closeModal {
      right: map-get($modalCloseButtonPositionTop, medium);
      top: map-get($modalCloseButtonPositionRight, medium);
      font-size: map-get($modalCloseButtonFontSize, medium);
      font-weight: map-get($modalCloseButtonFontWeight, medium);
      line-height: map-get($modalCloseButtonLineHeight, medium);
      opacity: map-get($modalCloseButtonOpacity, medium);

      &:hover {
        opacity: map-get($modalCloseButtonHoverOpacity, medium);
      }
    }
  }
}
