// For faster mobile-friendly development,
// use these utility classes for showing and hiding content by device via media query

// - - - - - - - - - - - - - - - - - - - - - - - - -
// Portrait/Landscape visibility classes

@media #{$landscape} {
  .mn_visible-landscape,
  .mn_visible-landscape-only { display: inherit !important; }

  .mn_hidden-landscape,
  .mn_visible-portrait-only { display: none !important; }
}

@media #{$portrait} {
  .mn_visible-portrait,
  .mn_visible-portrait-only { display: inherit !important; }

  .mn_hidden-portrait,
  .mn_visible-landscape-only { display: none !important; }
}

// - - - - - - - - - - - - - - - - - - - - - - - - -
// Visibility classes for differend screen sizes

.mn_visible-xs,
.mn_visible-s,
.mn_visible-m,
.mn_visible-l,
.mn_visible-xl { display: none !important; }

@media #{$xsmall-screen-only} {
  .mn_visible-xs { display: inherit !important; }
  .mn_hidden-xs { display: none !important; }
}

@media #{$small-screen-only} {
  .mn_visible-s { display: inherit !important; }
  .mn_hidden-s { display: none !important; }
}

@media #{$medium-screen-only} {
  .mn_visible-m { display: inherit !important; }
  .mn_hidden-m { display: none !important; }
}

@media #{$large-screen-only} {
  .mn_visible-l { display: inherit !important; }
  .mn_hidden-l { display: none !important; }
}

@media #{$xlarge-screen} {
  .mn_visible-xl { display: inherit !important; }
  .mn_hidden-xl { display: none !important; }
}
