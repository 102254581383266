#mn_ravenIndicatorBar {
  position: relative;
  z-index: 999999;
  padding: 15px 0 15px 40px;
  background: #32cd32;
  text-align: center;
  color: #000;
  font: normal 20px Arial, Helvetica, sans-serif;

  #mn_ravenBarClose,
  #mn_ravenBarClearCache {
    button {
      font-size: 12px;
      margin: .2rem;
      padding: .2rem;
    }
  }
}
