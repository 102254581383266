/* Screen sizes */
// These are default values for media query breakpoints.
// Each client may redefine values in their own variables.scss file

$xsmall: 375px !default;
$small: 480px !default;
$medium: 768px !default;
$large: 1024px !default;
$xlarge: 1280px !default;
$xxlarge: 1420px !default;

// - - - - - - - - - - - - - - - - - - - - - - - - -

/* Core media queries */
// All media query definitions in scss files should be replaced by these variables.
// The main idea of this is to make media query breakpoint values configurable per client's scss variables.
// Also code will look much cleaner in this syntax.
//
// Usage examples (scss => generated css):
// @media #{$medium-screen} {...} => @media (min-width: 768px) {...}
// @media #{$less-than-xlarge-screen} {...} => @media (max-width: 1279px) {...}
// @media #{$small-screen-only} {...} => @media (min-width: 480px) and (max-width: 767px) {...}
// @media #{$landscape} {...} => @media only screen and (orientation: landscape) {...}

$screen: 'only screen';

$less-than-xsmall-screen: '(max-width: #{$xsmall - .1})' !default;

$xsmall-screen: '(min-width: #{$xsmall})' !default;
$less-than-small-screen: '(max-width: #{$small - .1})' !default;
$xsmall-screen-only: '#{$less-than-small-screen}' !default;

$small-screen: '(min-width: #{$small})' !default;
$less-than-medium-screen: '(max-width: #{$medium - .1})' !default;
$small-screen-only: '#{$small-screen} and #{$less-than-medium-screen}' !default;

$medium-screen: '(min-width: #{$medium})' !default;
$less-than-large-screen: '(max-width: #{$large - .1})' !default;
$medium-screen-only: '#{$medium-screen} and #{$less-than-large-screen}' !default;

$large-screen: '(min-width: #{$large})' !default;
$less-than-xlarge-screen: '(max-width: #{$xlarge - .1})' !default;
$large-screen-only: '#{$large-screen} and #{$less-than-xlarge-screen}' !default;

$xlarge-screen: '(min-width: #{$xlarge})' !default;
$less-than-xxlarge-screen: '(max-width: #{$xxlarge - .1})' !default;
$xlarge-screen-only: '#{$xlarge-screen} and #{$less-than-xxlarge-screen}' !default;

$xxlarge-screen: '(min-width: #{$xxlarge})' !default;

$landscape: '#{$screen} and (orientation: landscape)' !default;
$portrait: '#{$screen} and (orientation: portrait)' !default;

$retina: (
  '#{$screen} and (-webkit-min-device-pixel-ratio: 2)',
  '#{$screen} and (min--moz-device-pixel-ratio: 2)',
  '#{$screen} and (-o-min-device-pixel-ratio: 2/1)',
  '#{$screen} and (min-device-pixel-ratio: 2)',
  '#{$screen} and (min-resolution: 192dpi)',
  '#{$screen} and (min-resolution: 2dppx)'
) !default;

// - - - - - - - - - - - - - - - - - - - - - - - - -

/* Brand defaults */
// Brand colors palette
$brandPrimaryColor: #ce1126 !default;
$brandSecondaryColor: #9f0d1d !default;
$brandTertiaryColor: #333 !default;
$brandQuaternaryColor: #000 !default;

$brandNeutralDarkColor: #999 !default;
$brandNeutralMediumColor: #d2d0c9 !default;
$brandNeutralLightColor: #ededed !default;
$brandWhite: #fff !default;
$brandPurple: #6244bb !default;

$brandErrorColor: #944242 !default;
$brandErrorLightColor: #ffcfc9 !default;

/* Typography */
// Text
$brandTextColor: #464d50 !default;
$brandTextColorInverse: #fff !default;
$brandTextFontSize: (small: 1.6rem, medium: 1.2rem) !default;
$brandLineHeight: (small: 2.2rem, medium: 1.8rem) !default;
$brandTextLetterSpacing: () !default;
$brandFontFamily: 'Helvetica Neue', Helvetica, Arial sans-serif !default;
// Links
$brandLinkColor: #2172ba !default;
$brandLinkColorHover: lighten($brandLinkColor, 5%) !default;
$brandLinkColorInverse: #fff !default;
$brandLinkColorHoverInverse: darken($brandLinkColorInverse, 10%) !default;
$brandLinkHoverDecoration: underline !default;
// Border
$brandBorderColor: $brandNeutralMediumColor !default;
$brandBorder: $brandBorderColor solid 1px !default;
$brandBorderColorSecondary: $brandNeutralLightColor !default;
$brandBorderSecondary: $brandBorderColorSecondary solid 1px !default;
// Ul
$brandUlMargin: (small: 0 0 1rem) !default;
$brandUlPadding: (small: 0 0 0 16px) !default;
$brandUlLiMargin: (small: 0 0 .5rem) !default;
$brandUlLvl2Margin: (small: .5rem 0) !default;
// Ol
$brandOlMargin: (small: 0 0 1rem) !default;
$brandOlPadding: (small: 0 0 0 16px) !default;
$brandOlLiMargin: (small: 0 0 .5rem) !default;
$brandOlLvl2Margin: (small: .5rem 0) !default;
// H1
$brandH1Margin: (small: 0 0 1rem) !default;
$brandH1FontSize: (small: 2rem, medium: 1.6rem) !default;
$brandH1LineHeight: (small: 1.2, medium: 1.2) !default;
$brandH1FontWeight: bold !default;
$brandH1FontFamily: $brandFontFamily !default;
$brandH1Color: $brandTextColor !default;
$brandH1Transform: () !default;
// H2
$brandH2Margin: (small: 2rem 0 .5rem) !default;
$brandH2FontSize: (small: 1.8rem, medium: 1.6rem) !default;
$brandH2LineHeight: (small: 1.2, medium: 1.2) !default;
$brandH2FontWeight: bold !default;
$brandH2FontFamily: $brandFontFamily !default;
$brandH2Color: $brandTextColor !default;
$brandH2Transform: () !default;
$brandH2LetterSpacing: () !default;
// H3
$brandH3Margin: (small: 2rem 0 .5rem) !default;
$brandH3FontSize: (small: 1.6rem, medium: 1.4rem) !default;
$brandH3LineHeight: (small: 1.2, medium: 1.2) !default;
$brandH3FontWeight: bold !default;
$brandH3FontFamily: $brandFontFamily !default;
$brandH3Color: $brandTextColor !default;
$brandH3Transform: () !default;
// P
$brandParagraphMargin: (small: 0 0 1rem) !default;

/* Favorites */
$brandUnselectedFavoriteOpacity: () !default;

/* Section */
$brandSectionMargin: (small: 0 0 5rem 0) !default;
$brandSectionPadding: (small: ()) !default;

/* List */
$brandFixedGap: 1.6rem !default;
$brandColumns: (small: ()) !default;
$brandRowGap: (small: $brandFixedGap, medium: 1.6rem, large: 1.6rem, xlarge: 1.6rem, xxlarge: 1.6rem) !default;
$brandColumnGap: (small: $brandFixedGap, medium: 1.6rem, large: 1.6rem, xlarge: 1.6rem, xxlarge: 1.6rem) !default;

// Dynamic section
// head
$brandDynamicSectHeadFontSize: (small: 2.4rem) !default;
$brandDynamicSectHeadFontWeight: normal !default;
$brandDynamicSectHeadColor: $brandTextColor !default;
$brandDynamicSectHeadPadding: (small: 0 0 1.5rem) !default;
$brandDynamicSectHeadMargin: (small: 0) !default;
$brandDynamicSectHeadBackground: none !default;
$brandDynamicSectHeadTitleFloat: (small: none, medium: none) !default;
$brandDynamicSectHeadTextTransform: none !default;
$brandDynamicSectHeadFirstLetterTextTransform: none !default;

// cont
$brandDynamicSectContPadding: (small: 0) !default;
$brandDynamicSectContBackground: none !default;
$brandDynamicSectContBorder: none !default;
$brandDynamicSectContBoxShadow: (small: none, medium: none) !default;

/* Button */
$brandButtonPadding: (small: .5rem .8rem, medium: .5rem .8rem) !default;
$brandButtonFontFamily: $brandFontFamily !default;
$brandButtonFontSize: 88% !default;
$brandButtonLineHeight: 1 !default;
$brandButtonFontWeight: bold !default;
$brandButtonFontTransform: none !default;
$brandButtonColor: $brandTextColorInverse !default;
$brandButtonBackgroundColor: $brandSecondaryColor !default;
$brandButtonBackgroundGradient: $brandButtonBackgroundColor, darken($brandButtonBackgroundColor, 5%) !default;
$brandButtonBorder: 1px solid darken($brandButtonBackgroundColor, 10%) !default;
$brandButtonBorderRadius: 4px !default;
$brandButtonShadow: rgba(0, 0, 0, .3) -2px 3px 2px !default;
$brandButtonLetterSpacing: () !default;
$brandButtonPosition: () !default;
// hover
$brandButtonColorHover: $brandTextColorInverse !default;
$brandButtonBackgroundColorHover: darken($brandButtonBackgroundColor, 10%) !default;
$brandButtonBackgroundGradientHover: $brandButtonBackgroundColorHover, darken($brandButtonBackgroundColorHover, 10%) !default;
$brandButtonBorderColorHover: darken($brandButtonBackgroundColorHover, 10%) !default;
// active
$brandButtonColorActive: $brandTextColorInverse !default;
$brandButtonBackgroundColorActive: darken($brandButtonBackgroundColorHover, 10%) !default;
$brandButtonBackgroundGradientActive: darken($brandButtonBackgroundColorActive, 10%), $brandButtonBackgroundColorActive !default;
$brandButtonBorderColorActive: darken($brandButtonBackgroundColorActive, 10%) !default;

$brandButtonInvertedBackground: () !default;
$brandButtonInvertedColor: () !default;
$brandButtonInvertedBorderColor: () !default;

$brandButtonSecondaryBackground: () !default;
$brandButtonSecondaryColor: () !default;
$brandButtonSecondaryBorderColor: () !default;

/* Navigation */
$navSubMenuWidth: auto;

/* Content */
$contentWrapPadding: (small: 0 1.5rem, medium: 0 1rem, large: 0 3rem) !default;
