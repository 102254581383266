@import '~core/styles/mixins';

/* Grid variables */

// Number of columns in the grid
$grid-columns: 12 !default;
// Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 2rem !default;

// Set styles for all grid columns excepting values for 'width' rule
@mixin set-columns-style($i:1,$list:'.mn_col-xs-#{$i}, .mn_col-s-#{$i}, .mn_col-m-#{$i}, .mn_col-l-#{$i}, .mn_col-xl-#{$i}') {
  @for $i from 2 through $grid-columns {
    $list: '#{$list}, .mn_col-xs-#{$i}, .mn_col-s-#{$i}, .mn_col-m-#{$i}, .mn_col-l-#{$i}, .mn_col-xl-#{$i}';
  }

  #{$list} {
    box-sizing: border-box;
    float: left;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    width: 100%;
    // Inner gutter via padding
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
  }
}

// Set values for grid columns width
@mixin set-columns-width($class) {
  @for $i from 1 through $grid-columns {
    .mn_col-#{$class}-#{$i} {
      width: percentage(($i / $grid-columns));
    }
  }
}

/* Grid classes */
.mn_row {
  margin-left: $grid-gutter-width / -2;
  margin-right: $grid-gutter-width / -2;

  @include clearfix;

  &.mn_centered {
    text-align: center;

    & > [class*='mn_col-'] {
      text-align: left;
      float: none;
      display: inline-block;
      vertical-align: top;
      margin-right: -.2em;
    }
  }
}

@include set-columns-style();
@include set-columns-width(xs);

@media #{$small-screen} {
  @include set-columns-width(s);
}

@media #{$medium-screen} {
  @include set-columns-width(m);
}

@media #{$large-screen} {
  @include set-columns-width(l);
}

@media #{$xlarge-screen} {
  @include set-columns-width(xl);
}
