@charset "UTF-8";

@font-face {
  font-family: 'usaa-icons';
  src: url('../fonts/usaa-icons.eot');
  src:
    url('../fonts/usaa-icons.eot?#iefix') format('embedded-opentype'),
    url('../fonts/usaa-icons.woff') format('woff'),
    url('../fonts/usaa-icons.ttf') format('truetype'),
    url('../fonts/usaa-icons.svg#usaa-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.usaa-icon,
[class*='usaa-icon_'] {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.usaa-icon_cart:before {
  content: '\61';
}

.usaa-icon_mobile:before {
  content: '\62';
}

.usaa-icon_dollar:before {
  content: '\63';
}

.usaa-icon_credit-cards:before {
  content: '\64';
}

.usaa-icon_wallet:before {
  content: '\65';
}

.usaa-icon_chart:before {
  content: '\66';
}

.usaa-icon_search:before {
  content: '\67';
}
