@import '~org/styles/variables';

.mn_navBar {
  .mn_mainNav {
    display: flex;
    flex-direction: column;
    user-select: none;
    margin-bottom: 1rem;

    &.mn_contentSection {
      padding: 1rem 2rem 5rem;
      margin: 0 0 5rem;
    }

    .mn_navigationListItem {
      border-bottom: 1px solid $brandLightGray2;
      margin: 0;

      &.mn_externalLink {
        display: flex;
        align-items: center;
      }
    }
  }

  .mn_usaaSVGIconExternalLinkGrey {
    &:after {
      margin: 1rem .7rem 1.5rem;
    }
  }

  .mn_pill {
    background: #3192aa;
    width: 10rem;
    transform: none;
    left: calc(100% + 1rem);
    top: .2rem;

    .mn_pillLabel::after {
      height: 1rem;
      width: 1.8rem;
      left: .5rem;
      top: .1rem;
    }
  }
}
