@import '~org/styles/variables';

.mn_skipHeaderContentLink {
  &:focus {
    clip: initial;
    height: auto;
    margin: 0 auto;
    overflow: visible;
    font-weight: bold;
    position: static;
    display: flex;
    font-size: 1.6rem;
    width: 22rem;
    padding: 0 1rem;
    justify-content: center;
    color: $brandWhite;
  }
}
