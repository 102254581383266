@import '~org/styles/variables';

$newPillWidth: 5rem !default;
$newPillHeight: () !default;
$newPillPositionLeft: calc(100% + 1rem) !default;
$newPillLabelFontWeight: () !default;
$newPillLabelFontSize: () !default;
$newPillLabelPadding: () !default;
$newPillLabelAfterDisplay: inline-block !default;

.mn_newPill {
  width: $newPillWidth;
  left: $newPillPositionLeft;
  height: $newPillHeight;
  transform: none;

  .mn_pillLabel {
    font-weight: $newPillLabelFontWeight;
    font-size: $newPillLabelFontSize;
    padding: $newPillLabelPadding;

    &:after {
      display: $newPillLabelAfterDisplay;
    }
  }
}
