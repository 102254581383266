@import '~org/styles/variables';

$navCategoriesTOFLinkHeight: 4.5rem !default;
$navCategoriesTOFLinkWidth: 20rem !default;
$navCategoriesTOFLinkMargin: 0 0 4rem !default;
$navCategoriesTOFLinkFontWeight: () !default;

.mn_navCategoriesTOFSection {
  .mn_allStoresLink {
    height: $navCategoriesTOFLinkHeight;
    width: $navCategoriesTOFLinkWidth;
    margin: $navCategoriesTOFLinkMargin;
    font-weight: $navCategoriesTOFLinkFontWeight;
  }

  .mn_dropdownContentWrap {
    position: static;
  }
}
