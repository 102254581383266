@import '~core/styles/mixins';
@import 'variables';

@mixin mn_linkWithArrow($fontSize: 1.6rem, $margin: 0 0 .2rem .8rem) {
  &:after {
    content: '\f105';
    font-family: 'fontello', serif;
    font-size: $fontSize;
    vertical-align: middle;
    display: inline-block;
    margin: $margin;
  }
}
