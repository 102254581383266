@import '~org/styles/variables';

$pageErrorBoundaryBackgroundImage: () !default;
$pageErrorBoundaryBackgroundColor: () !default;
$pageErrorBoundaryColor: () !default;
$pageErrorBoundaryHeadingColor: () !default;

.mn_pageErrorBoundary {
  background-image: $pageErrorBoundaryBackgroundImage;
  background-color: $pageErrorBoundaryBackgroundColor;
  padding: 4rem 1.5rem;
  color: $pageErrorBoundaryColor;

  h2 {
    color: $pageErrorBoundaryHeadingColor;
    padding-bottom: 1rem;
  }
}
