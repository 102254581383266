@import '~org/styles/variables';

.mn_accountNavigation {
  display: flex;
  justify-content: flex-start;
  color: $brandWhite;
  margin-left: 2rem;

  /* Account Navigation elements */
  .mn_navigationWrap {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;

    & > li:not(.mn_userSection) {
      padding: 0 .6rem;
    }

    .mn_navigationItem {
      display: block;
      padding: .1rem .6rem;
      margin-top: 1.7rem;
      text-decoration: none;
      color: $brandWhite;
      text-align: center;
      background: none;
      border: none;
      position: relative;

      & > i {
        display: block;
        width: 2rem;
        height: 2.2rem;
        line-height: 1.9rem;
        font-size: 2.8rem;
      }
    }

    .mn_searchTrigger {
      width: 2.8rem;
      height: 5rem;
      cursor: pointer;
      margin: 0;
      padding: 0;
    }
  }

  /* Dropdown */
  .mn_navigationDropdownTrigger {
    position: relative;
    margin: 0 0 -2.5rem;
    padding: 0 0 2.1rem;
    display: flex;
    align-items: flex-end;

    &:hover,
    &:focus,
    &:active {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: -8px;
        bottom: 15px;
        height: 3px;
        background: $brandHeaderColor;
      }
    }

    .mn_navigationItem {
      position: relative;
      z-index: 2;
      bottom: 1rem;
    }

    .mn_navigationDropdown {
      opacity: 0;
      visibility: hidden;
      transform: translate3d(-25%, 28px, 0);
      transition: all .3s ease-in-out;
      position: absolute;
      top: 2.1rem;
      right: -14rem;
      width: 38rem;
      background: $brandWhite;
      padding: 0;
      z-index: 2;
      text-align: left;
      box-shadow: 0 4px 4px 0 rgba(154, 158, 167, .5);

      &.mn_extraRewardsNavigationDropdown {
        padding: 2rem;

        &:before {
          left: 87%;
        }

        .mn_sectionTitle {
          margin: 0 0 1rem;

          h2 {
            font-weight: 700;
            font-size: 2rem;
            margin: 1rem 0 0;
            color: $brandHeaderColor;
          }
        }

        .mn_linkBottom {
          font-weight: 300;
          margin: 3.6rem 0 0;
          font-size: 1.4rem;
          color: $brandBlue7;
        }

        .mn_rebateValueWithCurrency {
          letter-spacing: 0;

          .mn_elevationOldValue {
            color: #626b78;
          }

          .mn_elevationNewValue {
            font-size: 1.3rem;
          }
        }
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          margin: 0;
          padding: 0;
          font-size: 1.4rem;
          position: relative;
          height: 6.4rem;

          &:after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0;
            height: 1px;
            width: 90%;
            border-bottom: 1px solid $brandLightGray2;
            transform: translateX(-50%);
          }
        }

        a {
          color: $brandHeaderColor;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          font-size: 1.4rem;
          line-height: 2.4rem;
          padding: 2rem 3.5rem;

          &:hover {
            background: $brandLightGray2;
          }
        }
      }
    }

    &.mn_focused {
      .mn_navigationDropdown {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }
    }
  }

  .mn_favoritesSection {
    .mn_navigationItem {
      margin: .1rem 0 0;
    }
  }

  /* User section */
  .mn_userSection {
    display: flex;
    align-items: baseline;
    position: relative;
    margin: 0 .5rem 0 0;

    .mn_navigationDropdownTrigger {
      &:hover {
        &:after {
          display: none;
        }
      }
    }

    .mn_userInfo {
      color: $brandHeaderColor;
      text-align: right;
      font-size: 1.4rem;
      line-height: 3.6rem;
      position: relative;
      margin: 1.3rem .5rem 0 0;
      padding-right: 3.1rem;

      .mn_userNameWrap {
        display: block;
        white-space: nowrap;
        margin: 0 .3rem .5em 0;

        .mn_userName {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 17rem;
          vertical-align: bottom;
        }
      }

      .mn_userInfoWrap {
        font-weight: 700;
      }
    }

    .mn_navigationItem {
      display: block;
      margin-top: 1.3rem;
      text-decoration: none;
      color: $brandWhite;
      text-align: center;
      background: none;
      border: none;
      position: absolute;
      bottom: 3.9rem;
      right: -.7rem;

      & > i {
        width: 2rem;
      }

      &:hover,
      &:focus,
      &:active {
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: -.3rem;
          bottom: -1.5rem;
          height: 3px;
          background: $brandHeaderColor;
        }
      }
    }

    .mn_button {
      height: 3.5rem;
      padding: .5rem 0;
      margin: 0 2rem;
    }
  }

  .mn_headerExtraRewardsSection {
    .mn_navigationDropdownTrigger {
      &:hover,
      &:focus,
      &:active {
        &:after {
          bottom: 15.5px;
        }
      }
    }

    .mn_logoWrap {
      margin: 0 3rem 0 0;
    }

    .mn_favoritesWrap {
      order: 3;
      margin: 0 0 0 auto;
    }
  }

  .mn_helpSection {
    ul a.mn_externalLink {
      color: #9a9ea7;
    }

    .mn_usaaSVGIconExternalLinkGrey {
      &:after {
        margin: 0 .7rem;
      }
    }
  }

  @media #{$medium-screen} {
    .mn_navigationWrap {
      li.mn_searchSection {
        padding: 0 1rem .4rem 1.1rem;
      }
    }
  }

  @media #{$xlarge-screen} {
    .mn_userSection {
      .mn_userInfo {
        .mn_userNameWrap {
          margin: 0 0 .3rem;
        }
      }

      .mn_navigationItem {
        bottom: -.2rem;
        right: -1rem;
      }
    }

    .mn_navigationWrap {
      li.mn_helpSection {
        padding: 0 .2rem 0 .9rem;
      }
    }
  }
}

/* Secondary navigation search bar for tablet and small desktop */
.mn_secondaryNavSearchBar {
  margin: 1.9rem 1rem 0 auto;

  .mn_searchBar {
    width: 25.4rem;
    padding: 0;

    &:before {
      display: none;
    }

    .mn_searchForm {
      .mn_dropDownWrap {
        left: -5.6rem;
        top: 2.5rem;
        box-shadow: 0 2px 4px 0 $brandQuaternaryColor;
      }

      .mn_searchInput {
        background: 0 0;
        border-bottom: 1px solid $brandLightGray2;
        color: $brandHeaderColor;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 3.5rem;
        height: 3.5rem;
        border-radius: 0;

        &:focus {
          background: $brandLightColor;
          border-bottom: 2px solid $brandHeaderColor;
          border-radius: 0;
        }
      }

      .mn_searchSubmit {
        position: absolute;
        top: 0;
        right: .5rem;
        background: 0 0;
        border: none;
        height: 3.5rem;
        line-height: 4.5rem;
        font-size: 2.2rem;
        width: 2.4rem;
        padding: .2rem .5rem 0 0;
      }
    }
  }

  @media #{$xlarge-screen} {
    margin: 0 0 0 auto;
  }
}
