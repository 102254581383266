@import '~org/styles/variables';

$orderInquiryPopUpPadding: 4.5rem !default;

$orderInquiryPopUpModalCloseDisplay: () !default;

$orderInquiryPopUpTitleFontSize: 3.2rem !default;
$orderInquiryPopUpTitleLineHeight: 3.8rem !default;
$orderInquiryPopUpTitlePaddingBottom: 1rem !default;

$orderInquiryPopUpTextFontSize: 1.6rem !default;
$orderInquiryPopUpTextLineHeight: 2.3rem !default;
$orderInquiryPopUpTextPaddingBottom: 2rem !default;

$orderInquiryPopUpConfirmationButtonsDisplay: flex !default;
$orderInquiryPopUpConfirmationButtonsJustifyContent: center !default;
$orderInquiryPopUpButtonMinWidth: 10rem !default;
$orderInquiryPopUpButtonMargin: 0 1rem 0 !default;
$orderInquiryPopUpButtonPadding: 1.25rem 1rem !default;
$orderInquiryPopUpContinueButtonColor: $brandPrimaryColor !default;
$orderInquiryPopUpContinueButtonBackground: $brandWhite !default;

$orderInquiryPopUpListMaxHeight: (xsmall: 30rem, small: 40rem) !default;
$orderInquiryPopUpListOverflow: auto !default;
$orderInquiryPopUpListPadding: 0 1rem 0 2rem !default;

.mn_orderInquiryModal {
  .mn_modal {
    padding: $orderInquiryPopUpPadding;
  }

  .mn_closeModal {
    display: $orderInquiryPopUpModalCloseDisplay;
  }

  h1 {
    font-size: $orderInquiryPopUpTitleFontSize;
    line-height: $orderInquiryPopUpTitleLineHeight;
    padding-bottom: $orderInquiryPopUpTitlePaddingBottom;
  }

  p {
    font-size: $orderInquiryPopUpTextFontSize;
    line-height: $orderInquiryPopUpTextLineHeight;
    padding-bottom: $orderInquiryPopUpTextPaddingBottom;
  }

  .mn_confirmationButtons {
    display: $orderInquiryPopUpConfirmationButtonsDisplay;
    justify-content: $orderInquiryPopUpConfirmationButtonsJustifyContent;

    .mn_button {
      min-width: $orderInquiryPopUpButtonMinWidth;
      margin: $orderInquiryPopUpButtonMargin;
      padding: $orderInquiryPopUpButtonPadding;

      &.mn_continue {
        color: $orderInquiryPopUpContinueButtonColor;
        background: $orderInquiryPopUpContinueButtonBackground;
      }
    }
  }

  @media #{$less-than-small-screen} {
    ul {
      max-height: map-get($orderInquiryPopUpListMaxHeight, small);
      overflow: $orderInquiryPopUpListOverflow;
      padding: $orderInquiryPopUpListPadding;
    }
  }

  @media (max-width: 376px) {
    ul {
      max-height: map-get($orderInquiryPopUpListMaxHeight, xsmall);
    }
  }
}
