[class*='mn_usaaSVGIcon'] {
  background: none;
  border: none;
  -webkit-appearance: none;
  border-radius: 0;

  &:before {
    content: '';
    display: block;
    width: 2.4rem;
    height: 2.4rem;
  }
}

.mn_usaaSVGIconMyAccount {
  &:before {
    background: no-repeat center/100% url(../assets/icons/Icon_Account_Blue.svg);
    width: 1.6rem;
    height: 2.2rem;
  }
}

.mn_usaaSVGIconMyAccountWhite {
  &:before {
    background: no-repeat center/100% url(../assets/icons/Icon_Account.svg);
    width: 1.8rem;
  }
}

.mn_usaaSVGIconHelp {
  &:before {
    background: no-repeat center/100% url(../assets/icons/Icon_HIW_Blue.svg);
    width: 2.2rem;
    height: 2.2rem;
  }
}

.mn_usaaSVGIconExtraRewards {
  &:before {
    background: no-repeat center/100% url(../assets/icons/Icon_Reward_Blue.svg);
    width: 2.3rem;
    height: 2.2rem;
  }
}

.mn_usaaSVGIconExternalLink {
  &:before {
    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;
    background: no-repeat center/100% url(../assets/icons/external_link_icon.svg);
  }
}

.mn_usaaSVGIconExternalLinkGrey {
  &:before {
    content: none;
  }

  &:after {
    display: inline-block;
    content: '';
    width: 1.4rem;
    height: 1.4rem;
    background: no-repeat center/100% url(../assets/icons/external_link_blue_icon.svg);
  }
}

.mn_usaaSVGIconSearch {
  &:before {
    background: no-repeat bottom/100% url(../assets/icons/Icon_Search.svg);

    @media #{$less-than-medium-screen} {
      height: 2.1rem;
      width: 2.1rem;
    }
  }
}

.mn_usaaSVGIconHamburger {
  &:before {
    background: no-repeat bottom/100% url(../assets/icons/Icon_Menu.svg);

    @media #{$less-than-medium-screen} {
      height: 2.1rem;
      width: 2.1rem;
    }
  }
}

.mn_usaaSVGIconFavorites {
  &:before {
    background: no-repeat bottom/100% url(../assets/icons/active.svg);

    @media #{$less-than-medium-screen} {
      height: 2.1rem;
      width: 2.1rem;
    }
  }
}

.mn_usaaSVGIconFavoritesHeader {
  &:before {
    background: no-repeat bottom/100% url(../assets/favorites/USAA-header-favorite.svg);

    @media #{$less-than-medium-screen} {
      height: 2.1rem;
      width: 2.1rem;
    }
  }
}

.mn_usaaSVGIconClose {
  &:before {
    background: no-repeat bottom/100% url(../assets/icons/Icon_Close.svg);
  }
}

html.mn_open .mn_usaaSVGIconHamburger {
  &:before {
    background: no-repeat bottom/100% url(../assets/icons/Icon_Close.svg);

    @media #{$less-than-medium-screen} {
      height: 2.1rem;
      width: 2.1rem;
    }
  }
}

.mn_usaaIconArrowUp:before {
  content: '';
  background-image: url(../assets/arrow_up.png);
  background-size: 1.6rem 1.8rem;
  display: inline-block;
  width: 1.6rem;
  height: 1.8rem;
}
