// Clear inner floats
@mixin clearfix() {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  }
}

/* Mixins for generating css 'up' and 'down' arrows */
@mixin arrow($width, $color, $scale: 1, $direction: up) {
  pointer-events: none;
  display: block;
  height: 0;
  width: 0;
  border: $width solid transparent;

  @if $direction == up {
    border-top-width: 0;
    border-bottom-width: $width * $scale;
    border-bottom-color: $color;
  }

  @else if $direction == down { // stylelint-disable-line at-rule-no-unknown
    border-bottom-width: 0;
    border-top-width: $width * $scale;
    border-top-color: $color;
  }
}

@mixin arrow-up($width, $color, $scale) {
  @include arrow($width, $color, $scale, up);
}

@mixin arrow-down($width, $color, $scale) {
  @include arrow($width, $color, $scale, down);
}

@mixin dropdown-arrow-down($width, $color) {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 1rem;
    top: 50%;
    height: 0;
    width: 0;
    border: solid transparent;
    border-width: $width $width 0;
    border-top-color: $color;
    margin-top: -1 * round($width/2 - 1px);
    pointer-events: none;
  }
}

@mixin dropdown-arrow-up($width, $color) {
  @include dropdown-arrow-down($width, $color);

  &:after {
    border-width: 0 $width $width;
    border-top-color: transparent;
    border-bottom-color: $color;
    margin-top: -1 * round($width/2);
  }
}

@mixin dropdown-arrow-left($width, $color) {
  @include dropdown-arrow-down($width, $color);

  &:after {
    border-width: $width $width+1 $width 0;
    border-color: transparent $color;
  }
}

@mixin dropdown-arrow-right($width, $color) {
  @include dropdown-arrow-down($width, $color);

  &:after {
    border-width: $width 0 $width $width+1;
    border-color: transparent $color;
  }
}

/* Mixin for generating of css vertical gradients */
@mixin vert-gradient($color-stops...) {
  background-image: -webkit-linear-gradient(top, $color-stops);
  background-image: linear-gradient(to bottom, $color-stops);
}

/* Mixin for gradients with angle */
@mixin angle-gradient($deg, $color-stops...) {
  background-image: -webkit-linear-gradient($deg, $color-stops);
  background-image: linear-gradient($deg, $color-stops);
}

/* Mixin for generating of css horizontal gradient */
@mixin horizontal-to-center-gradient($color-stops...) {
  background-image: -webkit-linear-gradient(left, $color-stops);
  background-image: -moz-linear-gradient(left, $color-stops);
  background-image: linear-gradient(to right, $color-stops);
}

@mixin broken-image(
  $color: $brandTextColor,
  $fontSize: 1.4rem,
  $background: #fff,
  $icon: '\E801'
) {
  position: relative;

  &:after {
    content: $icon ' ' attr(alt);
    font-size: $fontSize;
    font-family: Fontello, serif;
    color: $color;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $background;
  }
}

/* Mixin for handlling rebate elevations across all modules */
@mixin rebate-elevation($textColor, $strikethroughBorder, $strikethroughShadow: null, $strikethroughRotation: null, $textFontWeight: null, $strikeThroughLeft: -.1em, $strikeThroughRight: -.1em) {
  .mn_elevationOldValue {
    position: relative;
    color: $textColor;
    font-weight: $textFontWeight;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: $strikeThroughLeft;
      right: $strikeThroughRight;
      border-top: $strikethroughBorder;
      margin-top: -1px;
      box-shadow: $strikethroughShadow;

      @if ($strikethroughRotation) {
        -webkit-transform: rotate($strikethroughRotation);
        transform: rotate($strikethroughRotation);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
      }
    }
  }
}

/* Mixin for handling icons in navigation */
@mixin nav-icon($color, $content) {
  position: relative;

  &:after {
    font-family: 'FontAwesome', serif;
    content: $content;
    color: $color;
    font-size: 1.5rem;
    position: absolute;
    right: 1rem;
    top: 50%;
    margin-top: -7px;
    pointer-events: none;
    line-height: 1;
  }
}

/* Mixin for custom select styles */
@mixin custom-select-arrow(
  $top: 0,
  $right: 0,
  $height: 3.8rem,
  $marginTop: 0,
  $arrowColor: #ccc,
  $backgroundColor: $brandWhite
) {
  &:after {
    position: absolute;
    top: $top;
    right: $right;
    display: inline-block;
    background: $backgroundColor;
    font-family: 'FontAwesome', serif;
    content: '\f107';
    color: $arrowColor;
    pointer-events: none;
    text-align: center;
    width: 2.4rem;
    height: $height;
    line-height: $height;
    font-size: 2.3rem;
    font-weight: 800;
    border: none;
    margin-top: $marginTop;
  }
}

@mixin custom-select-style(
  $borderColor: $brandWhite,
  $backGroundColor: $brandWhite
) {
  select {
    border: 1px solid $borderColor;
    background-color: $backGroundColor;
    line-height: 2rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
  }

  select::-ms-expand {
    display: none;
  }
}

@mixin custom-select(
  $top: .7rem,
  $right: 0,
  $height: 2.4rem,
  $marginTop: 0,
  $arrowColor: #ccc,
  $borderColor: #e9e9e9
) {
  position: relative;
  border: 1px solid $borderColor;
  padding: .1rem 0 .1rem .5rem;

  @include custom-select-arrow(
    $top:   $top,
    $right:  $right,
    $height: $height,
    $marginTop: $marginTop,
    $arrowColor: $arrowColor
  );

  select {
    padding: .7rem 2.5rem .7rem 1rem;
    color: #7c7c7c;
    outline: none;
  }

  @include custom-select-style();
}

@mixin image-fallback-text (
  $imagePosition: relative,
  $color: $brandNeutralDarkColor,
  $background: #fff,
  $padding: 1rem,
  $fontSize: 1.2rem,
  $content: 'No image available for \"' attr(alt) '\"'
) {
  position: $imagePosition;

  &:after {
    content: $content;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    min-height: 100%;
    font-size: $fontSize;
    line-height: 1.5;
    padding: 1rem;
    color: $color;
    background: $background;
    box-sizing: border-box;
  }
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    $browser: '-webkit-' !global;

    @content;
  }

  @-moz-keyframes #{$animationName} {
    $browser: '-moz-' !global;

    @content;
  }

  @-o-keyframes #{$animationName} {
    $browser: '-o-' !global;

    @content;
  }

  @keyframes #{$animationName} {
    $browser: '' !global;

    @content;
  }
}

$browser: null;

@mixin justify-align-content-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin justify-align-content-right() {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin justify-align-content-left() {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin justify-align-content-space-between() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin slick-box-shadow {
  content: '';
  display: block;
  height: 100%;
  z-index: 0;
  box-shadow: inset -48px 0 45px -11px #fff;
  position: absolute;
  right: -18px;
  top: 0;
  width: 6.5rem;
}

@mixin background-gradient-and-image ($imgUrl, $startColor, $endColor) {
  background: $imgUrl;/* fallback */
  background-image: -webkit-gradient(180deg, $startColor 0%, $endColor 100%), $imgUrl; /* Saf4+, Chrome */
  background-image: -webkit-linear-gradient(180deg, $startColor 0%, $endColor 100%), $imgUrl; /* Chrome 10+, Saf5.1+ */
  background-image: -moz-linear-gradient(180deg, $startColor 0%, $endColor 100%), $imgUrl; /* FF3.6+ */
  background-image: -ms-linear-gradient(180deg, $startColor 0%, $endColor 100%), $imgUrl; /* IE10 */
  background-image: -o-linear-gradient(180deg, $startColor 0%, $endColor 100%), $imgUrl; /* Opera 11.10+ */
  background-image: linear-gradient(180deg, $startColor 0%, $endColor 100%), $imgUrl;/* W3C */
}

@mixin list-layout-responsive($media, $columnsMap, $columnGapMap, $rowGapMap) {
  $columns: map-get($columnsMap, $media);
  $columnGap: map-get($columnGapMap, $media);
  $rowGap: map-get($rowGapMap, $media);

  @if ($columns == 'auto') {
    .mn_list {
      & > * {
        flex-basis: auto;
      }
    }
  }

  @if ($rowGap and $rowGap != ()) {
    .mn_list {
      row-gap: $rowGap;
    }
  }

  @if ($columnGap and $columnGap != ()) {
    .mn_list {
      column-gap: $columnGap;
    }
  }

  @if ($columns and $columns != () and $columns != 'auto') {
    // Use $brandFixedGap as a fallback value
    $gap: if($columnGap != null and $columnGap != (), $columnGap, $brandFixedGap);

    .mn_list {
      column-gap: $gap;
    }

    .mn_list > * {
      flex-basis: calc((100% / #{$columns}) - #{$gap} + (#{$gap} / #{$columns}));
    }
  }
}

@mixin list-layout($columns: $brandColumns, $columnGap: $brandColumnGap, $rowGap: $brandRowGap) {
  @include list-layout-responsive(small, $columns, $columnGap, $rowGap);

  @media #{$medium-screen} {
    @include list-layout-responsive(medium, $columns, $columnGap, $rowGap);
  }

  @media #{$large-screen} {
    @include list-layout-responsive(large, $columns, $columnGap, $rowGap);
  }

  @media #{$xlarge-screen} {
    @include list-layout-responsive(xlarge, $columns, $columnGap, $rowGap);
  }

  @media #{$xxlarge-screen} {
    @include list-layout-responsive(xxlarge, $columns, $columnGap, $rowGap);
  }
}

@mixin section-styles-responsive(
  $media,
  $sectionMargin: $sectionMargin,
  $sectionPadding: $sectionPadding,
  $sectionColor: $sectionColor,
  $sectionBackground: $sectionBackground,
  $sectionTitleMargin: $sectionTitleMargin,
  $sectionTitleJustifyContent: $sectionTitleJustifyContent,
  $sectionTitleAlignItems: $sectionTitleAlignItems,
  $sectionTitleH2Color: $sectionTitleH2Color,
  $sectionTitleH2FontSize: $sectionTitleH2FontSize,
  $sectionTitleH2FontWeight: $sectionTitleH2FontWeight,
  $sectionTitleH2LineHeight: $sectionTitleH2LineHeight,
  $sectionTitleH2LetterSpacing: $sectionTitleH2LetterSpacing,
  $sectionTitleH2TextAlign: $sectionTitleH2TextAlign,
  $sectionTitleH2FontFamily: $sectionTitleH2FontFamily,
) {
  margin: map-get($sectionMargin, $media);
  padding: map-get($sectionPadding, $media);
  background: map-get($sectionBackground, $media);
  color: map-get($sectionColor, $media);

  .mn_sectionTitle {
    margin: map-get($sectionTitleMargin, $media);
    justify-content: map-get($sectionTitleJustifyContent, $media);
    align-items: map-get($sectionTitleAlignItems, $media);

    h2 {
      color: map-get($sectionTitleH2Color, $media);
      font-size: map-get($sectionTitleH2FontSize, $media);
      line-height: map-get($sectionTitleH2LineHeight, $media);
      font-weight: map-get($sectionTitleH2FontWeight, $media);
      letter-spacing: map-get($sectionTitleH2LetterSpacing, $media);
      text-align: map-get($sectionTitleH2TextAlign, $media);
      font-family: map-get($sectionTitleH2FontFamily, $media);
    }
  }
}

@mixin section-styles(
  $sectionMargin: $sectionMargin,
  $sectionPadding: $sectionPadding,
  $sectionColor: $sectionColor,
  $sectionBackground: $sectionBackground,
  $sectionTitleMargin: $sectionTitleMargin,
  $sectionTitleJustifyContent: $sectionTitleJustifyContent,
  $sectionTitleAlignItems: $sectionTitleAlignItems,
  $sectionTitleH2Color: $sectionTitleH2Color,
  $sectionTitleH2FontSize: $sectionTitleH2FontSize,
  $sectionTitleH2FontWeight: $sectionTitleH2FontWeight,
  $sectionTitleH2LineHeight: $sectionTitleH2LineHeight,
  $sectionTitleH2LetterSpacing: $sectionTitleH2LetterSpacing,
  $sectionTitleH2TextAlign: $sectionTitleH2TextAlign,
  $sectionTitleH2FontFamily: $sectionTitleH2FontFamily,
) {
  @include section-styles-responsive(
    'small',
    $sectionMargin: $sectionMargin,
    $sectionPadding: $sectionPadding,
    $sectionColor: $sectionColor,
    $sectionBackground: $sectionBackground,
    $sectionTitleMargin: $sectionTitleMargin,
    $sectionTitleJustifyContent: $sectionTitleJustifyContent,
    $sectionTitleAlignItems: $sectionTitleAlignItems,
    $sectionTitleH2Color: $sectionTitleH2Color,
    $sectionTitleH2FontSize: $sectionTitleH2FontSize,
    $sectionTitleH2FontWeight: $sectionTitleH2FontWeight,
    $sectionTitleH2LineHeight: $sectionTitleH2LineHeight,
    $sectionTitleH2LetterSpacing: $sectionTitleH2LetterSpacing,
    $sectionTitleH2TextAlign: $sectionTitleH2TextAlign,
    $sectionTitleH2FontFamily: $sectionTitleH2FontFamily,
  );

  @media #{$medium-screen} {
    @include section-styles-responsive(
      'medium',
      $sectionMargin: $sectionMargin,
      $sectionPadding: $sectionPadding,
      $sectionColor: $sectionColor,
      $sectionBackground: $sectionBackground,
      $sectionTitleMargin: $sectionTitleMargin,
      $sectionTitleJustifyContent: $sectionTitleJustifyContent,
      $sectionTitleAlignItems: $sectionTitleAlignItems,
      $sectionTitleH2Color: $sectionTitleH2Color,
      $sectionTitleH2FontSize: $sectionTitleH2FontSize,
      $sectionTitleH2FontWeight: $sectionTitleH2FontWeight,
      $sectionTitleH2LineHeight: $sectionTitleH2LineHeight,
      $sectionTitleH2LetterSpacing: $sectionTitleH2LetterSpacing,
      $sectionTitleH2TextAlign: $sectionTitleH2TextAlign,
      $sectionTitleH2FontFamily: $sectionTitleH2FontFamily,
    );
  }

  @media #{$large-screen} {
    @include section-styles-responsive(
      'large',
      $sectionMargin: $sectionMargin,
      $sectionPadding: $sectionPadding,
      $sectionColor: $sectionColor,
      $sectionBackground: $sectionBackground,
      $sectionTitleMargin: $sectionTitleMargin,
      $sectionTitleJustifyContent: $sectionTitleJustifyContent,
      $sectionTitleAlignItems: $sectionTitleAlignItems,
      $sectionTitleH2Color: $sectionTitleH2Color,
      $sectionTitleH2FontSize: $sectionTitleH2FontSize,
      $sectionTitleH2FontWeight: $sectionTitleH2FontWeight,
      $sectionTitleH2LineHeight: $sectionTitleH2LineHeight,
      $sectionTitleH2LetterSpacing: $sectionTitleH2LetterSpacing,
      $sectionTitleH2TextAlign: $sectionTitleH2TextAlign,
      $sectionTitleH2FontFamily: $sectionTitleH2FontFamily,
    );
  }

  @media #{$xlarge-screen} {
    @include section-styles-responsive(
      'xlarge',
      $sectionMargin: $sectionMargin,
      $sectionPadding: $sectionPadding,
      $sectionColor: $sectionColor,
      $sectionBackground: $sectionBackground,
      $sectionTitleMargin: $sectionTitleMargin,
      $sectionTitleJustifyContent: $sectionTitleJustifyContent,
      $sectionTitleAlignItems: $sectionTitleAlignItems,
      $sectionTitleH2Color: $sectionTitleH2Color,
      $sectionTitleH2FontSize: $sectionTitleH2FontSize,
      $sectionTitleH2FontWeight: $sectionTitleH2FontWeight,
      $sectionTitleH2LineHeight: $sectionTitleH2LineHeight,
      $sectionTitleH2LetterSpacing: $sectionTitleH2LetterSpacing,
      $sectionTitleH2TextAlign: $sectionTitleH2TextAlign,
      $sectionTitleH2FontFamily: $sectionTitleH2FontFamily,
    );
  }

  @media #{$xxlarge-screen} {
    @include section-styles-responsive(
      'xxlarge',
      $sectionMargin: $sectionMargin,
      $sectionPadding: $sectionPadding,
      $sectionColor: $sectionColor,
      $sectionBackground: $sectionBackground,
      $sectionTitleMargin: $sectionTitleMargin,
      $sectionTitleJustifyContent: $sectionTitleJustifyContent,
      $sectionTitleAlignItems: $sectionTitleAlignItems,
      $sectionTitleH2Color: $sectionTitleH2Color,
      $sectionTitleH2FontSize: $sectionTitleH2FontSize,
      $sectionTitleH2FontWeight: $sectionTitleH2FontWeight,
      $sectionTitleH2LineHeight: $sectionTitleH2LineHeight,
      $sectionTitleH2LetterSpacing: $sectionTitleH2LetterSpacing,
      $sectionTitleH2TextAlign: $sectionTitleH2TextAlign,
      $sectionTitleH2FontFamily: $sectionTitleH2FontFamily,
    );
  }
}

@mixin section(
  $sectionMargin: (),
  $sectionPadding: (),
  $sectionBackground: (),
  $sectionColor: (),
  $sectionTitleMargin: (),
  $sectionTitleJustifyContent: (),
  $sectionTitleAlignItems: (),
  $sectionTitleH2Color: (),
  $sectionTitleH2FontSize: (),
  $sectionTitleH2FontWeight: (),
  $sectionTitleH2LineHeight: (),
  $sectionTitleH2LetterSpacing: (),
  $sectionTitleH2TextAlign: (),
  $sectionTitleH2FontFamily: (),
) {
  // Apply default margin/padding brand styles
  @include section-styles(
    $sectionMargin: $brandSectionMargin,
    $sectionPadding: $brandSectionPadding,
    $sectionColor: (),
    $sectionBackground: (),
    $sectionTitleMargin: (),
    $sectionTitleJustifyContent: (),
    $sectionTitleAlignItems: (),
    $sectionTitleH2Color: (),
    $sectionTitleH2FontSize: (),
    $sectionTitleH2FontWeight: (),
    $sectionTitleH2LineHeight: (),
    $sectionTitleH2LetterSpacing: (),
    $sectionTitleH2TextAlign: (),
    $sectionTitleH2FontFamily: (),
  );
  // Apply component-level styles if exist
  @include section-styles(
    $sectionMargin: $sectionMargin,
    $sectionPadding: $sectionPadding,
    $sectionColor: $sectionColor,
    $sectionBackground: $sectionBackground,
    $sectionTitleMargin: $sectionTitleMargin,
    $sectionTitleJustifyContent: $sectionTitleJustifyContent,
    $sectionTitleAlignItems: $sectionTitleAlignItems,
    $sectionTitleH2Color: $sectionTitleH2Color,
    $sectionTitleH2FontSize: $sectionTitleH2FontSize,
    $sectionTitleH2FontWeight: $sectionTitleH2FontWeight,
    $sectionTitleH2LineHeight: $sectionTitleH2LineHeight,
    $sectionTitleH2LetterSpacing: $sectionTitleH2LetterSpacing,
    $sectionTitleH2TextAlign: $sectionTitleH2TextAlign,
    $sectionTitleH2FontFamily: $sectionTitleH2FontFamily,
  );
}
