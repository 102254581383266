@import '~org/styles/mixins';
@import '~org/styles/variables';

$rebateColor: () !default;
$rebatePrefixColor: () !default;
$rebateSuffixColor: () !default;

$rebateElevationColor: inherit !default;
$rebateElevationStrikethroughPrimary: 2px solid #000 !default;
$rebateElevationStrikethroughSecondary: 2px solid #fff !default;
$rebateElevationStrikethroughShadow: () !default;
$rebateElevationStrikethroughRotation: () !default;
$rebateElevationTextFontWeight: () !default;

$rebateValueColor: () !default;
$rebateValueWithCurrencyFontWeight: () !default;

$rebateTierNameColor: $rebateValueColor;

.mn_merchantRebate {
  color: $rebateColor;

  .mn_tierName {
    color: $rebateTierNameColor;
  }

  .mn_rebatePrefix {
    color: $rebatePrefixColor;
  }

  .mn_rebateValueWithCurrency {
    font-weight: $rebateValueWithCurrencyFontWeight;

    .mn_rebateValue {
      color: $rebateValueColor;

      @include rebate-elevation(
        $rebateElevationColor,
        $rebateElevationStrikethroughPrimary,
        $rebateElevationStrikethroughShadow,
        $rebateElevationStrikethroughRotation,
        $rebateElevationTextFontWeight,
      );
    }
  }
}

.mn_rebateSuffix {
  color: $rebateSuffixColor;
}
