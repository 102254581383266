@import '~org/styles/variables';

.mn_holidayImage + .mn_headerMainBar {
  padding-top: 0;
}

.mn_pageHeader {
  background: $brandWhite;

  .mn_searchBar {
    position: relative;
    z-index: 1;
  }

  .mn_headerInner {
    position: relative;
    z-index: 101;
    transform: translateZ(0);
    transition: opacity .5s ease-in-out;
  }

  .mn_headerMainBar {
    position: relative;
    z-index: 3;
  }

  // Sticky
  &.mn_sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 101;
    transform: translateZ(0);

    .mn_headerInner {
      opacity: 1;
    }

    .mn_headerMainBar {
      transition: .2s padding ease-in-out, .2s background ease-in-out;

      .mn_brandLogo a {
        &:before,
        &:after {
          transition: all .5s ease-in-out;
        }
      }
    }
  }

  .mn_brandLinks {
    text-align: center;
    padding: 0 0 1rem;
    background-color: $brandWhite;
  }

  .mn_button {
    margin-left: 1.5rem;
    padding-left: 1.5rem;
    position: relative;
    color: $brandWhite;
    font-weight: bold;
    font-size: 1.2rem;
    background: none;
  }

  .mn_loginLink,
  .mn_logoutLink {
    margin: auto;
    display: block;
    width: 15rem;
    height: 3.5rem;
    font-size: 1.6rem;
    font-weight: normal;
    color: $brandWhite;
    background: $brandPrimaryColor;
    text-align: center;
    text-decoration: none;
    line-height: 3.5rem;

    &:hover {
      background: $brandButtonBackgroundColorHover;
    }
  }

  @media #{$medium-screen} {
    .mn_signUpLink {
      background: $brandPrimaryColor;
      width: 10rem;
    }

    .mn_brandLinks {
      padding: .4rem 0 0;
    }

    .mn_loginLink,
    .mn_logoutLink {
      width: 10rem;
      padding: 0;
      margin-right: 1rem;
      font-size: 1.3rem;
      font-weight: 700;
    }
  }
}

.mn_pageHeaderOffset {
  overflow: hidden;
  visibility: hidden;
  flex: 0 0 0;
}
