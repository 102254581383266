@import '~org/styles/variables';

.mn_sidebarTrigger {
  position: relative;
  display: block;
  cursor: pointer;
  outline: none;
  transition: opacity .5s ease-in-out, transform .5s ease-in-out;
  z-index: 2;
  border: none;
  background: none;
  -webkit-tap-highlight-color: transparent;
  line-height: 30px;
  color: $brandWhite;
  flex-shrink: 0;
  margin-right: -.5rem;

  .mn_sidebarTriggerClose {
    display: none;
  }

  &[aria-expanded='true'] {
    .mn_sidebarTriggerClose {
      display: block;
    }

    .mn_sidebarTriggerOpen {
      display: none;
    }
  }

  @media #{$medium-screen} {
    font-size: 1.8rem;
    margin: 0 -.1rem .1rem 0;
  }
}
