/* stylelint-disable */
// Copy of balloon-css@0.5.0 with modifications

@import '~org/styles/variables';

//
// Variables
// -----------------------------------------

$balloon-bg:             fade-out(#111, .1) !default;
$balloon-color:          #fff !default;
$balloon-border:         () !default;
$balloon-border-radius:  4px !default;
$balloon-base-size:      10px !default;
$balloon-svg-arrow-width:    18 !default;
$balloon-svg-arrow-height:   6 !default;
$balloon-arrow-height:   6px !default;
$balloon-arrow-margin-right: 5px !default;
$balloon-arrow-margin-bottom: 2px !default;
$balloonSVGFillColor:    "%23fff" !default;
$balloonSVGStrokeColor:  "%23e6e6e6" !default;
$balloonSVGStrokeOpacity: () !default;

//
// Mixins
// -----------------------------------------

@mixin svg-arrow ($color, $strokeColor, $position: up, $opacity: 1) {
  $originWidth: $balloon-svg-arrow-width;
  $originHeight: $balloon-svg-arrow-height;

  $strokeWidth: 2;
  $degrees: 0;
  $height: $originHeight;
  $width: $originWidth;

  @if ($position == down) {
    $degrees: 180 $originWidth $originHeight;
  } @else if ($position == left) {
    $degrees: -90 $originWidth $originWidth;
    $width: $originHeight;
    $height: $originWidth;
  } @else if ($position == right) {
    $degrees: 90 $originHeight + 2 $originHeight + 2;
    $width: $originHeight;
    $height: $originWidth;
  }

  background: no-repeat url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%22#{$width * 2 + $strokeWidth * 2}%22%20height%3D%22#{$height * 3}%22%3E%3Cpath%20opacity%3D%22#{$opacity}%22%20fill%3D%22#{$color}%22%20stroke%3D%22#{$strokeColor}%22%20stroke-width%3D%22#{$strokeWidth}%22%20transform%3D%22rotate(#{$degrees})%22%20d%3D%22M#{$strokeWidth/2,$strokeWidth/2}%20L#{($originWidth*2)-$strokeWidth,$strokeWidth/2}%20L#{$originWidth - ($strokeWidth/2),($originHeight * 3)-$strokeWidth}%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: $width + $strokeWidth*2 + px;
  height: $height + $strokeWidth*2 + px;
}

@mixin transition ($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform ($val) {
  -webkit-transform: $val;
  -moz-transform: $val;
  -ms-transform: $val;
  transform: $val;
}

@mixin transform-origin ($val) {
  -webkit-transform-origin: $val;
  -moz-transform-origin: $val;
  -ms-transform-origin: $val;
  transform-origin: $val;
}

@mixin opacity ($trans) {
  filter: alpha(opactiy=($trans * 100));
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$trans * 100})";
  -moz-opacity: $trans;
  -khtml-opacity: $trans;
  opacity: $trans;
}

@mixin base-effects () {
  @include opacity(0);
  pointer-events: none;
  @include transition(all .18s ease-out .18s);
}

@mixin no-effects () {
  @include transition(none);
}

@mixin normalized-text() {
  font-family: sans-serif !important;
  font-weight: normal !important;
  font-style: normal !important;
  text-shadow: none !important;
  font-size: $balloon-base-size + 2 !important;
}


//
// Styles
// -----------------------------------------

// IE 11 button bugfix
button[data-balloon] {
  overflow: visible;
}

[data-balloon] {
  // Fixing iOS Safari event issue.
  // More info at: https://goo.gl/w8JF4W
  cursor: pointer;

  &:after {
    @include base-effects();
    @include normalized-text();
    background: $balloon-bg;
    border: $balloon-border;
    border-radius: $balloon-border-radius;
    color: $balloon-color;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
    content: attr(data-balloon);
    padding: .5em 1em;
    position: absolute;
    white-space: nowrap;
    z-index: 10;
  }

  &:before {
    @include svg-arrow($balloonSVGFillColor, $balloonSVGStrokeColor, null, $balloonSVGStrokeOpacity);
    @include base-effects();

    content: '';
    position: absolute;
    z-index: 111;
  }

  &:hover, &[data-balloon-visible] {
    &:before,
    &:after {
      @include opacity(1);
      pointer-events: auto;
    }
  }

  &.font-awesome:after {
    font-family: FontAwesome;
  }

  &[data-balloon-break] {
    &:after {
      white-space: pre;
    }
  }

  &[data-balloon-blunt] {
    &:before,
    &:after {
      @include no-effects();
    }
  }

  &[data-balloon-pos="up"] {
    &:after {
      bottom: 100%;
      left: 50%;
      margin-bottom: 5px + $balloon-arrow-height;
      @include transform(translate(-50%, 10px));
      @include transform-origin(top);
    }
    &:before {
      bottom: 100%;
      left: 50%;
      margin-bottom: $balloon-arrow-margin-bottom;
      @include transform(translate(-50%, 10px));
      @include transform-origin(top);
    }

    &:hover, &[data-balloon-visible] {
      &:after {
        @include transform(translate(-50%, 0));
      }

      &:before {
        @include transform(translate(-50%, 0));
      }
    }
  }

  &[data-balloon-pos="up-left"] {
    &:after {
      bottom: 100%;
      left: 0;
      margin-bottom: 5px + $balloon-arrow-height;
      @include transform(translate(0, 10px));
      @include transform-origin(top);
    }
    &:before {
      bottom: 100%;
      left: 5px;
      margin-bottom: 5px;
      @include transform(translate(0, 10px));
      @include transform-origin(top);
    }

    &:hover, &[data-balloon-visible] {
      &:after {
        @include transform(translate(0, 0));
      }

      &:before {
        @include transform(translate(0, 0));
      }
    }
  }

  &[data-balloon-pos="up-right"] {
    &:after {
      bottom: 100%;
      right: 0;
      margin-bottom: 5px + $balloon-arrow-height;
      @include transform(translate(0, 10px));
      @include transform-origin(top);
    }
    &:before {
      bottom: 100%;
      right: 5px;
      margin-bottom: 5px;
      @include transform(translate(0, 10px));
      @include transform-origin(top);
    }

    &:hover, &[data-balloon-visible] {
      &:after {
        @include transform(translate(0, 0));
      }

      &:before {
        @include transform(translate(0, 0));
      }
    }
  }

  &[data-balloon-pos='down'] {
    &:after {
      left: 50%;
      margin-top: 5px + $balloon-arrow-height;
      top: 100%;
      @include transform(translate(-50%, -10px));
    }

    &:before {
      @include svg-arrow($balloonSVGFillColor, $balloonSVGStrokeColor, 'down');

      left: 50%;
      margin-top: 5px;
      top: 100%;
      @include transform(translate(-50%, -10px));
    }

    &:hover, &[data-balloon-visible] {
      &:after {
        @include transform(translate(-50%, 0));
      }

      &:before {
        @include transform(translate(-50%, 0));
      }
    }
  }

  &[data-balloon-pos='down-left'] {
    &:after {
      left: 0;
      margin-top: 5px + $balloon-arrow-height;
      top: 100%;
      @include transform(translate(0, -10px));
    }

    &:before {
      @include svg-arrow($balloonSVGFillColor, $balloonSVGStrokeColor, 'down');

      left: 5px;
      margin-top: 5px;
      top: 100%;
      @include transform(translate(0, -10px));
    }

    &:hover, &[data-balloon-visible] {
      &:after {
        @include transform(translate(0, 0));
      }

      &:before {
        @include transform(translate(0, 0));
      }
    }
  }

  &[data-balloon-pos='down-right'] {
    &:after {
      right: 0;
      margin-top: 5px + $balloon-arrow-height;
      top: 100%;
      @include transform(translate(0, -10px));
    }

    &:before {
      @include svg-arrow($balloonSVGFillColor, $balloonSVGStrokeColor, 'down');

      right: 5px;
      margin-top: 5px;
      top: 100%;
      @include transform(translate(0, -10px));
    }

    &:hover, &[data-balloon-visible] {
      &:after {
        @include transform(translate(0, 0));
      }

      &:before {
        @include transform(translate(0, 0));
      }
    }
  }

  &[data-balloon-pos='left'] {
    &:after {
      margin-right: 5px + $balloon-arrow-height;
      right: 100%;
      top: 50%;
      @include transform(translate(10px, -50%));
    }

    &:before {
      @include svg-arrow($balloonSVGFillColor, $balloonSVGStrokeColor, 'left');

      margin-right: $balloon-arrow-margin-right;
      right: 100%;
      top: 50%;
      @include transform(translate(10px, -50%));
    }

    &:hover, &[data-balloon-visible] {
      &:after {
        @include transform(translate(0, -50%));
      }

      &:before {
        @include transform(translate(0, -50%));
      }
    }

  }

  &[data-balloon-pos='right'] {
    &:after {

      left: 100%;
      margin-left: 5px + $balloon-arrow-height;
      top: 50%;
      @include transform(translate(-10px, -50%));
    }

    &:before {
      @include svg-arrow($balloonSVGFillColor, $balloonSVGStrokeColor, 'right');

      left: 100%;
      margin-left: 5px;
      top: 50%;
      @include transform(translate(-10px, -50%));
    }

    &:hover, &[data-balloon-visible] {
      &:after {
        @include transform(translate(0, -50%));
      }

      &:before {
        @include transform(translate(0, -50%));
      }
    }
  }

  &[data-balloon-length='small'] {
    &:after {
      white-space: normal;
      width: 80px;
    }
  }

  &[data-balloon-length='medium'] {
    &:after {
      white-space: normal;
      width: 150px;
    }
  }

  &[data-balloon-length='large'] {
    &:after {
      white-space: normal;
      width: 260px;
    }
  }

  &[data-balloon-length='xlarge'] {
    &:after {
      white-space: normal;
      width: 380px;

      @media screen and (max-width: 768px) {
        white-space: normal;
        width: 90vw;
      }
    }
  }

  &[data-balloon-length='fit'] {
    &:after {
      white-space: normal;
      width: 100%;
    }
  }
}
