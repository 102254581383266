@import '~org/styles/variables';

$navPopularStoresViewAllLinkMargin: () !default;
$navPopularStoresViewAllLinkColor: () !default;
$navPopularStoresViewAllLinkFontSize: () !default;
$navPopularStoresViewAllLinkFontWeight: () !default;
$navPopularStoresViewAllLinkPadding: () !default;

.mn_navPopularStoresSection {
  .mn_navDropdownMenu {
    li {
      .mn_viewAllPopularStoresLink {
        margin: $navPopularStoresViewAllLinkMargin;
        color: $navPopularStoresViewAllLinkColor;
        font-size: $navPopularStoresViewAllLinkFontSize;
        font-weight: $navPopularStoresViewAllLinkFontWeight;
        padding: $navPopularStoresViewAllLinkPadding;
      }
    }
  }
}
