@import '~core/styles/mixins';
@import '~org/styles/variables';

$headerOnboardingCongratsWrapPositionTop: () !default;
$headerOnboardingCongratsBoxShadow: inset 0 1px 3px 0 rgba(0, 0, 0, .5), 0 2px 6px 0 rgba(0, 0, 0, .5) !default;
$headerOnboardingCongratsTextAlign: center !default;
$headerOnboardingCongratsBackground: #fff !default;
$headerOnboardingCongratsWidth: calc(100% - 6rem) !default;
$headerOnboardingCongratsMaxHeight: () !default;
$headerOnboardingCongratsPositionLeft: calc(50% - #{$headerOnboardingCongratsWidth} / 2);

$headerOnboardingCongratsTitleAlignItems: center !default;
$headerOnboardingCongratsTitleFontSize: 4rem !default;
$headerOnboardingCongratsTitleFontWeight: 400 !default;
$headerOnboardingCongratsTitleLineHeight: 4rem !default;
$headerOnboardingCongratsTitleMargin: 2.2rem 0 0 !default;
$headerOnboardingCongratsIconDisplay: none !default;
$headerOnboardingCongratsIconMargin: 0 1rem 0 0 !default;
$headerOnboardingCongratsIconHeight: () !default;
$headerOnboardingCongratsIconWidth: () !default;
$headerOnboardingCongratsTitleDisplay: () !default;
$headerOnboardingCongratsIconPosition: () !default;
$headerOnboardingCongratsIconPositionTop: () !default;
$headerOnboardingCongratsIconPositionRight: () !default;
$headerOnboardingCongratsIconPositionWidth: () !default;
$headerOnboardingCongratsH2Color: () !default;
$headerOnboardingCongratsTitleJustifyContent: center !default;
$headerOnboardingCongratsTextMarginTop: 1.5rem !default;
$headerOnboardingCongratsTextFontSize: 2rem !default;
$headerOnboardingCongratsTextLineHeight: 2.7rem !default;
$headerOnboardingCongratsTextFontWeight: 400 !default;
$headerOnboardingCongratsTextColor: #000 !default;
$headerOnboardingCongratsTextPaddingBottom: 16px !default;
$headerOnboardingCongratsTextLetterSpacing: () !default;

@keyframes onboardingHeight {
  from {
    height: 0;
  }

  to {
    height: 130px;
  }
}

@keyframes onboardingOpacity {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.mn_onboardingCongratsWrap {
  position: relative;
  animation-delay: 5s;
  animation-duration: 1s;
  animation-name: onboardingOpacity;
  z-index: 999999;
  margin: 0 auto;
  top: $headerOnboardingCongratsWrapPositionTop;

  &.mn_contentSection {
    max-width: $xxlarge;
  }

  .mn_onboardingCongrats {
    box-shadow: $headerOnboardingCongratsBoxShadow;
    text-align: $headerOnboardingCongratsTextAlign;
    background: $headerOnboardingCongratsBackground;
    width: $headerOnboardingCongratsWidth;
    position: absolute;
    left: $headerOnboardingCongratsPositionLeft;
    z-index: 2;
    overflow: hidden;
    height: 0;
    max-height: $headerOnboardingCongratsMaxHeight;
    animation-delay: 1s;
    animation-duration: 2s;
    animation-name: onboardingHeight;
    animation-fill-mode: forwards;

    h2 {
      display: $headerOnboardingCongratsTitleDisplay;
      justify-content: $headerOnboardingCongratsTitleJustifyContent;
      align-items: $headerOnboardingCongratsTitleAlignItems;
      color: $headerOnboardingCongratsH2Color;
      font-size: $headerOnboardingCongratsTitleFontSize;
      font-weight: $headerOnboardingCongratsTitleFontWeight;
      line-height: $headerOnboardingCongratsTitleLineHeight;
      margin: $headerOnboardingCongratsTitleMargin;

      img {
        display: $headerOnboardingCongratsIconDisplay;
        position: $headerOnboardingCongratsIconPosition;
        top: $headerOnboardingCongratsIconPositionTop;
        right: $headerOnboardingCongratsIconPositionRight;
        margin: $headerOnboardingCongratsIconMargin;
        height: $headerOnboardingCongratsIconHeight;
        width: $headerOnboardingCongratsIconWidth;
      }
    }

    p {
      margin-top: $headerOnboardingCongratsTextMarginTop;
      font-size: $headerOnboardingCongratsTextFontSize;
      line-height: $headerOnboardingCongratsTextLineHeight;
      font-weight: $headerOnboardingCongratsTextFontWeight;
      color: $headerOnboardingCongratsTextColor;
      padding-bottom: $headerOnboardingCongratsTextPaddingBottom;
      letter-spacing: $headerOnboardingCongratsTextLetterSpacing;
    }
  }
}
