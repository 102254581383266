@import '~org/styles/variables';

$hybridSearchModalPositionTop: unset !default;
$hybridSearchModalPositionBottom: 0 !default;
$hybridSearchModalZindex: 1000 !default;
$hybridSearchModalPadding: 0 !default;

$hybridSearchModalContentBackground: #474747 !default;

$hybridSearchModalCloseZIndex: 100 !default;
$hybridSearchModalCloseColor: () !default;

$hybridSearchModalFormWrapPadding: 0 0 2rem 0 !default;
$hybridSearchModalFormWrapBackground: () !default;

$hybridSearchModalDropDownPosition: relative !default;
$hybridSearchModalDropDownPositionLeft: 0 !default;
$hybridSearchModalDropDownPositionRight: 0 !default;
$hybridSearchModalDropDownMinHeight: 10rem !default;
$hybridSearchModalDropDownMargin: 0 !default;

$hybridSearchModalFormPosition: relative !default;

$hybridSearchModalFormLabelFontSize: unset !default;

$hybridSearchModalSearchInputDisplay: block !default;
$hybridSearchModalSearchInputBackground: $brandBlack !default;
$hybridSearchModalSearchInputPadding: .4rem 3.5rem .4rem 1.5rem !default;
$hybridSearchModalSearchInputBorderRadius: 3rem !default;
$hybridSearchModalSearchInputBorder: none !default;
$hybridSearchModalSearchInputColor: $brandWhite !default;
$hybridSearchModalSearchInputFontSize: () !default;
$hybridSearchModalSearchInputFontWeight: () !default;
$hybridSearchModalSearchInputWidth: calc(100% - 3rem) !default;
$hybridSearchModalSearchInputHeight: 5.8rem !default;
$hybridSearchModalSearchInputOutline: 0 !default;
$hybridSearchModalSearchInputMargin: 0 auto !default;
$hybridSearchModalSearchInputFocusedOutlineOffset: () !default;

$hybridSearchModalSearchSubmitPosition: absolute !default;
$hybridSearchModalSearchSubmitPositionRight: 2.5rem !default;
$hybridSearchModalSearchSubmitPositionBottom: 50% !default;
$hybridSearchModalSearchSubmitTransform: translateY(50%) !default;
$hybridSearchModalSearchSubmitHeight: 4rem !default;
$hybridSearchModalSearchSubmitZIndex: () !default;

$hybridSearchModalSearchSubmitIconBeforeFontFamily: () !default;
$hybridSearchModalSearchSubmitIconBeforeContent: () !default;
$hybridSearchModalSearchSubmitIconBeforeColor: () !default;
$hybridSearchModalSearchSubmitIconBeforeFontStyle: () !default;
$hybridSearchModalSearchSubmitIconBeforeHeight: () !default;
$hybridSearchModalSearchSubmitIconBeforeWidth: () !default;

$hybridSearchModalRecentOrSimilarStoresTileFavoriteIconWrapOrder: () !default;
$hybridSearchModalRecentOrSimilarStoresTileFavoriteIconWrapMargin: () !default;
$hybridSearchModalRecentOrSimilarStoresTilePremierMerchantRebateTextAlign: () !default;

$hybridSearchModalSearchSubmitIconBeforeFontFamily: () !default;
$hybridSearchModalSearchSubmitIconBeforeContent: () !default;
$hybridSearchModalSearchSubmitIconBeforeColor: () !default;
$hybridSearchModalSearchSubmitIconBeforeFontStyle: () !default;

.mn_hybridSearchModal {
  .mn_modal {
    top: $hybridSearchModalPositionTop;
    bottom: $hybridSearchModalPositionBottom;
    z-index: $hybridSearchModalZindex;
    padding: $hybridSearchModalPadding;
  }

  .mn_modalContent {
    background: $hybridSearchModalContentBackground;
  }

  .mn_closeModal {
    z-index: $hybridSearchModalCloseZIndex;
    color: $hybridSearchModalCloseColor;
  }

  .mn_hybridSearchForm {
    padding: $hybridSearchModalFormWrapPadding;
    background: $hybridSearchModalFormWrapBackground;

    .mn_dropDownWrap {
      position: $hybridSearchModalDropDownPosition;
      left: $hybridSearchModalDropDownPositionLeft;
      right: $hybridSearchModalDropDownPositionRight;
      min-height: $hybridSearchModalDropDownMinHeight;
      margin: $hybridSearchModalDropDownMargin;
    }

    form {
      position: $hybridSearchModalFormPosition;
    }

    label {
      font-size: $hybridSearchModalFormLabelFontSize;
    }

    .mn_searchInput {
      display: $hybridSearchModalSearchInputDisplay;
      background: $hybridSearchModalSearchInputBackground;
      padding: $hybridSearchModalSearchInputPadding;
      border-radius: $hybridSearchModalSearchInputBorderRadius;
      border: $hybridSearchModalSearchInputBorder;
      color: $hybridSearchModalSearchInputColor;
      font-size: $hybridSearchModalSearchInputFontSize;
      font-weight: $hybridSearchModalSearchInputFontWeight;
      width: $hybridSearchModalSearchInputWidth;
      height: $hybridSearchModalSearchInputHeight;
      outline: $hybridSearchModalSearchInputOutline;
      margin: $hybridSearchModalSearchInputMargin;

      &:focus {
        outline-offset: $hybridSearchModalSearchInputFocusedOutlineOffset;
      }
    }

    .mn_searchSubmit {
      position: $hybridSearchModalSearchSubmitPosition;
      right: $hybridSearchModalSearchSubmitPositionRight;
      bottom: $hybridSearchModalSearchSubmitPositionBottom;
      transform: $hybridSearchModalSearchSubmitTransform;
      height: $hybridSearchModalSearchSubmitHeight;
      z-index: $hybridSearchModalSearchSubmitZIndex;

      i:before {
        font-family: $hybridSearchModalSearchSubmitIconBeforeFontFamily;
        content: $hybridSearchModalSearchSubmitIconBeforeContent;
        color: $hybridSearchModalSearchSubmitIconBeforeColor;
        font-style: $hybridSearchModalSearchSubmitIconBeforeFontStyle;
        height: $hybridSearchModalSearchSubmitIconBeforeHeight;
        width: $hybridSearchModalSearchSubmitIconBeforeWidth;
      }
    }

    .mn_recentStores,
    .mn_similarStores {
      .mn_itemContent {
        .mn_favoritesWrap {
          order: $hybridSearchModalRecentOrSimilarStoresTileFavoriteIconWrapOrder;
          margin: $hybridSearchModalRecentOrSimilarStoresTileFavoriteIconWrapMargin;
        }

        .mn_premierMerchantRebate {
          text-align: $hybridSearchModalRecentOrSimilarStoresTilePremierMerchantRebateTextAlign;
        }
      }
    }
  }
}
