@import '~org/styles/variables';

$addToFavoriteFirstTimePopUpModalBackground: () !default;
$addToFavoriteFirstTimePopUpTextAlign: () !default;
$addToFavoriteFirstTimePopUpModalWidth: (small: (), medium: 60rem) !default;
$addToFavoriteFirstTimePopUpModalPositionTop: () !default;

$addToFavoriteFirstTimePopUpModalCloseColor: () !default;

$addToFavoriteFirstTimePopUpTitleColor: (small: $brandQuaternaryColor, medium: $brandQuaternaryColor) !default;
$addToFavoriteFirstTimePopUpTitleFontSize: 3.6rem !default;
$addToFavoriteFirstTimePopUpTitleFontWeight: bold !default;
$addToFavoriteFirstTimePopUpTitleLineHeight: 5rem !default;
$addToFavoriteFirstTimePopUpTitleTextTransform: () !default;

$addToFavoriteFirstTimePopUpTextColor: () !default;
$addToFavoriteFirstTimePopUpTextFontWeight: () !default;
$addToFavoriteFirstTimePopUpTextFontSize: 1.8rem !default;
$addToFavoriteFirstTimePopUpTextLineHeight: 2.8rem !default;
$addToFavoriteFirstTimePopUpTextMargin: (small: 3rem 0, medium: ()) !default;
$addToFavoriteFirstTimePopUpTextDotDisplay: () !default;

$addToFavoriteFirstTimePopUpLinkColor: (small: #0daef0, medium: #0daef0) !default;
$addToFavoriteFirstTimePopUpLinkTextFontSize: () !default;
$addToFavoriteFirstTimePopUpLinkTextAfterContent: () !default;
$addToFavoriteFirstTimePopUpLinkTextAfterFontFamily: () !default;
$addToFavoriteFirstTimePopUpLinkTextAfterFontSize: () !default;
$addToFavoriteFirstTimePopUpLinkTextFontWeight: () !default;
$addToFavoriteFirstTimePopUpLinkTextDecoration: () !default;

$addToFavoriteFirstTimePopUpButtonFontSize: 1.6rem !default;
$addToFavoriteFirstTimePopUpButtonFontWeight: () !default;
$addToFavoriteFirstTimePopUpButtonWidth: 20rem !default;
$addToFavoriteFirstTimePopUpButtonMaxWidth: () !default;
$addToFavoriteFirstTimePopUpButtonPadding: 1.5rem 0 !default;
$addToFavoriteFirstTimePopUpButtonBackground: () !default;
$addToFavoriteFirstTimePopUpButtonColor: () !default;
$addToFavoriteFirstTimePopUpButtonBorder: () !default;
$addToFavoriteFirstTimePopUpButtonLetterSpacing: () !default;
$addToFavoriteFirstTimePopUpButtonLineHeight: () !default;

.mn_firstFavoriteModal {
  .mn_modal {
    background: map-get($addToFavoriteFirstTimePopUpModalBackground, small);
    text-align: $addToFavoriteFirstTimePopUpTextAlign;
    width: map-get($addToFavoriteFirstTimePopUpModalWidth, small);
    top: map-get($addToFavoriteFirstTimePopUpModalPositionTop, small);
  }

  .mn_closeModal {
    color: map-get($addToFavoriteFirstTimePopUpModalCloseColor, small);
  }

  h2 {
    color: map-get($addToFavoriteFirstTimePopUpTitleColor, small);
    font-size: $addToFavoriteFirstTimePopUpTitleFontSize;
    font-weight: $addToFavoriteFirstTimePopUpTitleFontWeight;
    line-height: $addToFavoriteFirstTimePopUpTitleLineHeight;
    text-transform: $addToFavoriteFirstTimePopUpTitleTextTransform;
  }

  p {
    color: map-get($addToFavoriteFirstTimePopUpTextColor, small);
    font-size: $addToFavoriteFirstTimePopUpTextFontSize;
    font-weight: $addToFavoriteFirstTimePopUpTextFontWeight;
    line-height: $addToFavoriteFirstTimePopUpTextLineHeight;
    margin: map-get($addToFavoriteFirstTimePopUpTextMargin, small);

    & > span {
      display: $addToFavoriteFirstTimePopUpTextDotDisplay;
    }
  }

  .mn_myAccountLink {
    color: map-get($addToFavoriteFirstTimePopUpLinkColor, small);
    font-size: $addToFavoriteFirstTimePopUpLinkTextFontSize;
    font-weight: $addToFavoriteFirstTimePopUpLinkTextFontWeight;
    text-decoration: $addToFavoriteFirstTimePopUpLinkTextDecoration;

    &:after {
      content: $addToFavoriteFirstTimePopUpLinkTextAfterContent;
      font-family: $addToFavoriteFirstTimePopUpLinkTextAfterFontFamily;
      font-size: $addToFavoriteFirstTimePopUpLinkTextAfterFontSize;
    }
  }

  .mn_button {
    padding: $addToFavoriteFirstTimePopUpButtonPadding;
    width: $addToFavoriteFirstTimePopUpButtonWidth;
    max-width: $addToFavoriteFirstTimePopUpButtonMaxWidth;
    font-size: $addToFavoriteFirstTimePopUpButtonFontSize;
    font-weight: $addToFavoriteFirstTimePopUpButtonFontWeight;
    background: $addToFavoriteFirstTimePopUpButtonBackground;
    color: $addToFavoriteFirstTimePopUpButtonColor;
    border: $addToFavoriteFirstTimePopUpButtonBorder;
    letter-spacing: $addToFavoriteFirstTimePopUpButtonLetterSpacing;
    line-height: $addToFavoriteFirstTimePopUpButtonLineHeight;
  }

  @media #{$medium-screen} {
    .mn_modal {
      width: map-get($addToFavoriteFirstTimePopUpModalWidth, medium);
      top: map-get($addToFavoriteFirstTimePopUpModalPositionTop, medium);
      background: map-get($addToFavoriteFirstTimePopUpModalBackground, medium);
    }

    .mn_closeModal {
      color: map-get($addToFavoriteFirstTimePopUpModalCloseColor, medium);
    }

    h2 {
      color: map-get($addToFavoriteFirstTimePopUpTitleColor, medium);
    }

    p {
      margin: map-get($addToFavoriteFirstTimePopUpTextMargin, medium);
      color: map-get($addToFavoriteFirstTimePopUpTextColor, medium);
    }

    .mn_myAccountLink {
      color: map-get($addToFavoriteFirstTimePopUpLinkColor, medium);
    }
  }
}
