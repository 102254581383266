@import '~org/styles/variables';

$hiwVideoModalPadding: (small: (), medium: ()) !default;

.mn_hiwVideoModal {
  .mn_modal {
    padding: map-get($hiwVideoModalPadding, small);
  }

  .mn_vidyardContainer {
    margin: auto;
    height: auto;
    overflow: hidden;
    width: 100%;
    display: block;
    position: relative;

    .mn_vidyard {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: 0 50%;
      background-size: 100%;

      .mn_vidyardInnerContainer {
        position: absolute;
        height: 100%;
        width: 100%;

        .mn_vidyardPlayerEmbed {
          opacity: 1;
          background-color: transparent;
          position: absolute;
          top: 0;
          left: 0;
          border: none;
        }
      }
    }
  }

  @media #{$medium-screen} {
    .mn_modal {
      padding: map-get($hiwVideoModalPadding, medium);
    }
  }
}
