@import '~org/styles/style';

$hybridQuickSearchDisplay: (small: flex, medium: ()) !default;
$hybridQuickSearchFlexDirection: (small: column-reverse, medium: column) !default;
$hybridQuickSearchBackground: (small: #474747, medium: ()) !default;
$hybridQuickSearchFontSize: (small: 1.6rem, medium: ()) !default;
$hybridQuickSearchLineHeight: (small: 2.4rem, medium: ()) !default;
$hybridQuickSearchColor: (small: #fff, medium: ()) !default;
$hybridQuickSearchPadding: (small: 4.5rem 2rem 2rem 2rem, medium: 2rem 2rem 1rem 2rem) !default;

$hybridQuickSearchNoResultsMargin: (small: (), medium: ()) !default;
$hybridQuickSearchNoResultsFontSize: (small: 2rem, medium: ()) !default;
$hybridQuickSearchNoResultsLineHeight: (small: 3.2rem, medium: ()) !default;
$hybridQuickSearchNoResultsFontWeight: (small: bold, medium: ()) !default;
$hybridQuickSearchNoResultsColor: () !default;

$hybridQuickSearchResultsDisplay: (small: flex, medium: ()) !default;
$hybridQuickSearchResultsFlexDirection: (small: column-reverse, medium: column) !default;
$hybridQuickSearchResultsMargin: (small: (), medium: 0 0 2rem 0) !default;

$hybridQuickSearchWrapPadding: 0 !default;

$hybridQuickSearchWrapTitleColor: #fff !default;
$hybridQuickSearchWrapTitleFontSize: 1.4rem !default;
$hybridQuickSearchWrapTitleLineHeight: 2.2rem !default;
$hybridQuickSearchWrapTitleFontWeight: bold !default;
$hybridQuickSearchWrapTitleLetterSpacing: 1px !default;
$hybridQuickSearchWrapTitleTextTransform: uppercase !default;
$hybridQuickSearchWrapTitleMargin: 0 !default;

$hybridQuickSearchItemMargin: 0 -2rem !default;
$hybridQuickSearchItemFontSize: 1.6rem !default;
$hybridQuickSearchItemLineHeight: 2.4rem !default;

$hybridQuickSearchItemIconPositionLeft: -3rem !default;
$hybridQuickSearchItemIconWidth: 1.6rem !default;
$hybridQuickSearchItemIconHeight: $hybridQuickSearchItemIconWidth !default;

$hybridQuickSearchItemMatchDisplay: block !default;
$hybridQuickSearchItemMatchColor: #fff !default;
$hybridQuickSearchItemMatchMinWidth: 100% !default;

$hybridQuickSearchItemMatchHoverTextDecoration: none !default;
$hybridQuickSearchItemMatchHoverBackground: rgba(255, 255, 255, .15) !default;

$hybridQuickSearchMerchantHighlightPosition: relative !default;
$hybridQuickSearchMerchantHighlightDisplay: flex !default;
$hybridQuickSearchMerchantHighlighthJustifyContent: space-between !default;

$hybridQuickSearchItemMatchMerchantPadding: (small: .5rem 2rem .5rem 5rem, medium: ()) !default;
$hybridQuickSearchItemMatchMerchantHoverBackground: () !default;
$hybridQuickSearchAnonymousItemMatchMerchantPadding: (small: .5rem 2rem .5rem 2rem, medium: ()) !default;

$hybridQuickSearchItemMatchProductDisplay: inline-block !default;
$hybridQuickSearchItemMatchProductColor: #fff !default;
$hybridQuickSearchItemMatchProductPadding: (small: .5rem 2rem, medium: ()) !default;
$hybridQuickSearchItemMatchProductHoverBackground: () !default;
$hybridQuickSearchItemMatchHighlightFontWeight: bold !default;

$hybridQuickSearchItemDirectMerchantPillPadding: .5rem 0 0 1.5rem !default;
$hybridQuickSearchItemDirectMerchantPillFontSize: 1.4rem !default;
$hybridQuickSearchItemDirectMerchantPillLineHeight: 1.8rem !default;

$hybridQuickSearchWrapLinkDisplay: inline-block !default;
$hybridQuickSearchWrapLinkMargin: 1rem 0 0 0 !default;
$hybridQuickSearchWrapLinkColor: #fff !default;
$hybridQuickSearchWrapLinkFontSize: 1.4rem !default;
$hybridQuickSearchWrapLinkFontWeight: () !default;
$hybridQuickSearchWrapLinkLineHeight: 2.6rem !default;
$hybridQuickSearchWrapLinkTextDecoration: underline !default;
$hybridQuickSearchWrapLinkTextTransform: () !default;
$hybridQuickSearchWrapLinkHoverTextDecoration: none !default;

$hybridQuickSearchRecentStoresFavoriteIconWidth: () !default;
$hybridQuickSearchRecentStoresFavoriteIconHeight: () !default;

$hybridQuickSearchRebateFontSize: 1.4rem !default;
$hybridQuickSearchRebateLineHeight: 2.4rem !default;
$hybridQuickSearchRebatePadding: 0 0 0 .5rem !default;
$hybridQuickSearchRebateFontWeight: bold !default;
$hybridQuickSearchRebateValueColor: #fff !default;

$hybridQuickSearchInstoreRebateFontSize: 1.4rem !default;
$hybridQuickSearchInstoreRebateLineHeight: 2.4rem !default;
$hybridQuickSearchInstoreRebateFontWeight: bold !default;

$hybridQuickSearchWrapDividerDisplay: block !default;
$hybridQuickSearchWrapDividerWidth: 100% !default;
$hybridQuickSearchWrapDividerHeight: 1px !default;
$hybridQuickSearchWrapDividerMargin: 2rem 0 !default;
$hybridQuickSearchWrapDividerBackground: #666 !default;

.mn_hybridQuickSearch {
  display: map-get($hybridQuickSearchDisplay, small);
  flex-direction: map-get($hybridQuickSearchFlexDirection, small);
  background: map-get($hybridQuickSearchBackground, small);
  font-size: map-get($hybridQuickSearchFontSize, small);
  line-height: map-get($hybridQuickSearchLineHeight, small);
  color: map-get($hybridQuickSearchColor, small);
  padding: map-get($hybridQuickSearchPadding, small);

  .mn_noResults {
    margin: map-get($hybridQuickSearchNoResultsMargin, small);
    font-size: map-get($hybridQuickSearchNoResultsFontSize, small);
    line-height: map-get($hybridQuickSearchNoResultsLineHeight, small);
    font-weight: map-get($hybridQuickSearchNoResultsFontWeight, small);
    color: $hybridQuickSearchNoResultsColor;
  }

  .mn_recentStores {
    .mn_favoriteIcon {
      width: $hybridQuickSearchRecentStoresFavoriteIconWidth;
      height: $hybridQuickSearchRecentStoresFavoriteIconHeight;
    }
  }

  .mn_quickSearchResults {
    display: map-get($hybridQuickSearchResultsDisplay, small);
    flex-direction: map-get($hybridQuickSearchResultsFlexDirection, small);
    margin: map-get($hybridQuickSearchResultsMargin, small);

    .mn_quickSearchWrap {
      padding: $hybridQuickSearchWrapPadding;

      &:after {
        content: '';
        display: $hybridQuickSearchWrapDividerDisplay;
        width: $hybridQuickSearchWrapDividerWidth;
        height: $hybridQuickSearchWrapDividerHeight;
        margin: $hybridQuickSearchWrapDividerMargin;
        background: $hybridQuickSearchWrapDividerBackground;
      }

      &:first-child:after {
        content: none;
      }

      .mn_quickSearchTitle {
        color: $hybridQuickSearchWrapTitleColor;
        font-size: $hybridQuickSearchWrapTitleFontSize;
        line-height: $hybridQuickSearchWrapTitleLineHeight;
        font-weight: $hybridQuickSearchWrapTitleFontWeight;
        letter-spacing: $hybridQuickSearchWrapTitleLetterSpacing;
        text-transform: $hybridQuickSearchWrapTitleTextTransform;
        margin: $hybridQuickSearchWrapTitleMargin;
      }

      .mn_quickSearchItems {
        .mn_quickSearchItem {
          margin: $hybridQuickSearchItemMargin;
          font-size: $hybridQuickSearchItemFontSize;
          line-height: $hybridQuickSearchItemLineHeight;

          .mn_favoriteIcon,
          .mn_instoreIcon {
            left: $hybridQuickSearchItemIconPositionLeft;
            top: 50%;
            position: absolute;
            transform: translateY(-50%);
          }

          .mn_favoriteIcon {
            width: $hybridQuickSearchItemIconWidth;
            height: $hybridQuickSearchItemIconHeight;
          }

          .mn_instoreIcon {
            width: $hybridQuickSearchItemIconWidth;
          }

          .mn_quickSearchMatch {
            display: $hybridQuickSearchItemMatchDisplay;
            color: $hybridQuickSearchItemMatchColor;
            min-width: $hybridQuickSearchItemMatchMinWidth;

            &:hover,
            &:focus {
              text-decoration: $hybridQuickSearchItemMatchHoverTextDecoration;
              background: $hybridQuickSearchItemMatchHoverBackground;
            }

            .mn_matchTextHighlight {
              font-weight: $hybridQuickSearchItemMatchHighlightFontWeight;
            }

            .mn_directMerchantPill {
              padding: $hybridQuickSearchItemDirectMerchantPillPadding;
              font-size: $hybridQuickSearchItemDirectMerchantPillFontSize;
              line-height: $hybridQuickSearchItemDirectMerchantPillLineHeight;
            }

            &.mn_quickSearchMerchantMatch {
              padding: map-get($hybridQuickSearchItemMatchMerchantPadding, small);

              &:hover,
              &:focus {
                background: $hybridQuickSearchItemMatchMerchantHoverBackground;
              }

              &.mn_isAnonymous {
                padding: map-get($hybridQuickSearchAnonymousItemMatchMerchantPadding, small);
              }

              .mn_quickSearchMatchHighlight {
                position: $hybridQuickSearchMerchantHighlightPosition;
                display: $hybridQuickSearchMerchantHighlightDisplay;
                justify-content: $hybridQuickSearchMerchantHighlighthJustifyContent;
              }
            }

            &.mn_quickSearchProductMatch {
              display: $hybridQuickSearchItemMatchProductDisplay;
              color: $hybridQuickSearchItemMatchProductColor;
              padding: map-get($hybridQuickSearchItemMatchProductPadding, small);

              &:hover,
              &:focus {
                background: $hybridQuickSearchItemMatchProductHoverBackground;
              }
            }
          }
        }
      }

      .mn_quickSearchLink {
        display: $hybridQuickSearchWrapLinkDisplay;
        margin: $hybridQuickSearchWrapLinkMargin;
        color: $hybridQuickSearchWrapLinkColor;
        font-size: $hybridQuickSearchWrapLinkFontSize;
        font-weight: $hybridQuickSearchWrapLinkFontWeight;
        line-height: $hybridQuickSearchWrapLinkLineHeight;
        text-decoration: $hybridQuickSearchWrapLinkTextDecoration;
        text-transform: $hybridQuickSearchWrapLinkTextTransform;

        &:hover {
          text-decoration: $hybridQuickSearchWrapLinkHoverTextDecoration;
        }
      }
    }

    &.mn_quickSearchResultsNonGsp {
      .mn_quickSearchWrap {
        &:after {
          content: none;
        }

        &.mn_quickSearchInstoreWrap {
          margin: 1rem 0 0 0;

          &:after {
            content: '';
          }
        }

        .mn_quickSearchLink {
          display: none;
        }
      }

      .mn_noResults {
        font-weight: normal;
      }
    }
  }

  // Rebates
  .mn_premierMerchantRebate {
    font-size: $hybridQuickSearchRebateFontSize;
    line-height: $hybridQuickSearchRebateLineHeight;
    padding: $hybridQuickSearchRebatePadding;
    font-weight: $hybridQuickSearchRebateFontWeight;
    white-space: nowrap;

    .mn_rebateValue {
      color: $hybridQuickSearchRebateValueColor;

      .mn_tieredPrefix {
        display: inline-block;

        &:first-letter {
          text-transform: uppercase;
        }
      }
    }

    .mn_rebateValueWithCurrency {
      font-size: $hybridQuickSearchRebateFontSize;
    }

    .mn_elevationOldValue {
      display: none;
    }

    .mn_elevationNowPrefix {
      display: none;
    }
  }

  .mn_instoreMerchantRebate {
    font-size: $hybridQuickSearchInstoreRebateFontSize;
    line-height: $hybridQuickSearchInstoreRebateLineHeight;
    font-weight: $hybridQuickSearchInstoreRebateFontWeight;
  }

  @media #{$medium-screen} {
    display: map-get($hybridQuickSearchDisplay, medium);
    flex-direction: map-get($hybridQuickSearchFlexDirection, medium);
    background: map-get($hybridQuickSearchBackground, medium);
    font-size: map-get($hybridQuickSearchFontSize, medium);
    line-height: map-get($hybridQuickSearchLineHeight, medium);
    color: map-get($hybridQuickSearchColor, medium);
    padding: map-get($hybridQuickSearchPadding, medium);

    .mn_noResults {
      margin: map-get($hybridQuickSearchNoResultsMargin, medium);
      font-size: map-get($hybridQuickSearchNoResultsFontSize, medium);
      line-height: map-get($hybridQuickSearchNoResultsLineHeight, medium);
      font-weight: map-get($hybridQuickSearchNoResultsFontWeight, medium);
    }

    .mn_quickSearchResults {
      display: map-get($hybridQuickSearchResultsDisplay, medium);
      flex-direction: map-get($hybridQuickSearchResultsFlexDirection, medium);
      margin: map-get($hybridQuickSearchResultsMargin, medium);

      .mn_quickSearchWrap {
        &:first-child:after {
          content: '';
        }

        &:last-child:after {
          content: none;
        }

        .mn_quickSearchItems .mn_quickSearchItem .mn_quickSearchMatch {
          &.mn_quickSearchMerchantMatch {
            padding: map-get($hybridQuickSearchItemMatchMerchantPadding, medium);

            &.mn_isAnonymous {
              padding: map-get($hybridQuickSearchAnonymousItemMatchMerchantPadding, medium);
            }
          }

          &.mn_quickSearchProductMatch {
            padding: map-get($hybridQuickSearchItemMatchProductPadding, medium);
          }
        }
      }

      &.mn_quickSearchResultsNonGsp {
        .mn_quickSearchWrap {
          &:after {
            content: none;
          }

          &.mn_quickSearchInstoreWrap {
            margin: 0;

            &:after {
              content: none;
            }

            &:before {
              content: '';
              display: $hybridQuickSearchWrapDividerDisplay;
              width: $hybridQuickSearchWrapDividerWidth;
              height: $hybridQuickSearchWrapDividerHeight;
              margin: $hybridQuickSearchWrapDividerMargin;
              background: $hybridQuickSearchWrapDividerBackground;
            }
          }
        }
      }
    }
  }
}
