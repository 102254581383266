@import '~org/styles/variables';

$productDetailsModalInfoMargin: (small: 0 0 2.4rem, medium: 0 0 0 4rem) !default;
$productDetailsModalInfoColor: () !default;
$productDetailsModalContentTextAlign: left !default;
$productDetailsModalContentMargin: 0 0 3rem !default;
$productDetailsModalProductNameColor: #000 !default;
$productDetailsModalProductNameLineHeight: 2.4rem !default;
$productDetailsModalProductNameMargin: (small: 1.9rem 0 1rem, medium: 0 0 1rem) !default;
$productDetailsModalProductNameFontWeight: 600 !default;
$productDetailsModalProductNameFontSize: (small: 1.6rem, medium: 1.6rem) !default;
$productDetailsModalCompareTextMargin: 0 0 .5rem !default;
$productDetailsModalDescriptionColor: #666 !default;
$productDetailsModalDescriptionFontSize: 1.2rem !default;
$productDetailsModalDescriptionLineHeight: 1.7rem !default;
$productDetailsModalListMargin: 0 0 1.5rem !default;
$productDetailsModalListPadding: 0 0 0 3rem !default;
$productDetailsModalCompareTextFontSize: (small: 1.4rem, medium: 1.4rem) !default;
$productDetailsModalListFontSize: (small: 1.4rem, medium: 1.4rem) !default;
$productDetailsModalDetailsButtonDisplay: () !default;
$productDetailsModalDetailsButtonBorder: none !default;
$productDetailsModalDetailsButtonFontSize: 1.2rem !default;
$productDetailsModalDetailsButtonFontWeight: () !default;
$productDetailsModalDetailsButtonLineHeight: 1.7rem !default;
$productDetailsModalDetailsButtonBackground: none !default;
$productDetailsModalDetailsButtonColor: #666 !default;
$productDetailsModalDetailsButtonTextDecoration: underline !default;
$productDetailsModalDetailsButtonPadding: 0 0 0 .5rem !default;
$productDetailsModalOfferCellBorder: 1px solid rgba(0, 0, 0, .15) !default;
$productDetailsModalOfferCellPadding: .5px 0 !default;
$productDetailsModalOfferPadding: 1rem 0 1.2rem !default;
$productDetailsModalOfferMargin: (small: 0 0 0 -.3rem, medium: 0) !default;
$productDetailsModalOfferInfoTextAlign: left !default;
$productDetailsModalClickLinkFontSize: 1.4rem !default;
$productDetailsModalClickLinkPadding: .7rem 1.7rem !default;
$productDetailsModalClickLinkBorderRadius: .6rem !default;
$productDetailsModalClickLinkMinWidth: 10.5rem !default;
$productDetailsModalClickLinkBorder: () !default;
$productDetailsModalClickLinkLineHeight: () !default;
$productDetailsModalClickLinkBackground: () !default;
$productDetailsModalClickLinkTextTransform: () !default;
$productDetailsModalClickLinkAfterContent: '' !default;
$productDetailsModalClickLinkAfterFontFamily: () !default;
$productDetailsModalClickLinkAfterFontSize: () !default;
$productDetailsModalClickLinkAfterFontWeight: () !default;
$productDetailsModalClickLinkAfterPaddingLeft: () !default;
$productDetailsModalClickLinkHoverBackground: () !default;
$productDetailsModalClickLinkHoverColor: () !default;
$productDetailsModalClickLinkFontWeight: () !default;
$productDetailsModalOfferInfoFontSize: 1.6rem !default;
$productDetailsModalOfferInfoMargin: () !default;
$productDetailsModalRebateFontSize: (small: 1.6rem, medium: 1.6rem) !default;
$productDetailsModalNameAndRebateLineHeight: 2.8rem !default;
$productDetailsModalPriceLineHeight: 2.4rem !default;
$productDetailsModalNameColor: () !default;
$productDetailsModalNameFontWeight: () !default;
$productDetailsModalNameFontSize: () !default;
$productDetailsModalPriceFontWeight: (small: 600, medium: 600) !default;
$productDetailsModalPriceFontSize: () !default;
$productDetailsModalPriceMargin: () !default;
$productDetailsModalRebatePrefixColor: () !default;
$productDetailsModalRebatePrefixFontWeight: 500 !default;
$productDetailsModalRebateElevationOldValueFontWeight: () !default;
$productDetailsModalRebateElevationOldValue: () !default;
$productDetailsModalRebateOldValueAfterContent: '' !default;
$productDetailsModalPillOfferInfoMargin: () !default;
$productDetailsModalTableHeadBackground: #f7f7f7 !default;
$productDetailsModalTableHeadColor: () !default;
$productDetailsModalTableHeadFontSize: 1.2rem !default;
$productDetailsModalTableHeadTextTransform: uppercase !default;
$productDetailsModalTableHeadLineHeight: 2.2rem !default;
$productDetailsModalTableHeadRowMargin: () !default;
$productDetailsModalTableHeadCellPadding: 1.9rem 2.1rem !default;
$productDetailsModalPriceColor: () !default;
$productDetailsModalPillWidth: 2rem !default;
$productDetailsModalPillBorderRadius: () !default;
$productDetailsModalPillTextTransform: () !default;
$productDetailsModalPillLabelFontSize: () !default;
$productDetailsModalPillLabelFontWeight: () !default;
$productDetailsModalSalePillBackground: () !default;
$productDetailsModalSalePillLabelColor: () !default;
$productDetailsModalPillLabelPadding: () !default;
$productDetailsModalExtraRewardsPillBackground: () !default;
$productDetailsModalTableHeadMerchNameWidth: 28% !default;
$productDetailsModalTableHeadBuyPriceWidth: 10% !default;
$productDetailsModalTableHeadRebTxtWidth: 17% !default;
$productDetailsModalTableHeadActionLinkWidth: 20% !default;
$productDetailsModalTableBodyAlignContent: () !default;
$productDetailsModalTableBodyMerchNameWidth: 37% !default;
$productDetailsModalTableBodyBuyPriceWidth: 15% !default;
$productDetailsModalTableBodyRebateWrapWidth: 32% !default;

.mn_productDetailsModal {
  .mn_mainContent {
    text-align: $productDetailsModalContentTextAlign;

    img {
      margin: auto;
      max-height: 30rem;
      max-width: 30rem;
    }

    .mn_productInfo {
      margin: map-get($productDetailsModalInfoMargin, small);
      color: $productDetailsModalInfoColor;

      .mn_productName {
        color: $productDetailsModalProductNameColor;
        line-height: $productDetailsModalProductNameLineHeight;
        margin: map-get($productDetailsModalProductNameMargin, small);
        font-weight: $productDetailsModalProductNameFontWeight;
        font-size: map-get($productDetailsModalProductNameFontSize, small);
      }

      .mn_compare {
        margin: $productDetailsModalCompareTextMargin;
        font-size: map-get($productDetailsModalCompareTextFontSize, small);
      }

      ul {
        margin: $productDetailsModalListMargin;
        padding: $productDetailsModalListPadding;
        font-size: map-get($productDetailsModalListFontSize, small);
      }

      .mn_descriptTxt {
        color: $productDetailsModalDescriptionColor;
        font-size: $productDetailsModalDescriptionFontSize;
        line-height: $productDetailsModalDescriptionLineHeight;
      }

      .mn_moreDetails {
        display: $productDetailsModalDetailsButtonDisplay;
        border: $productDetailsModalDetailsButtonBorder;
        font-size: $productDetailsModalDetailsButtonFontSize;
        font-weight: $productDetailsModalDetailsButtonFontWeight;
        line-height: $productDetailsModalDetailsButtonLineHeight;
        background: $productDetailsModalDetailsButtonBackground;
        color: $productDetailsModalDetailsButtonColor;
        text-decoration: $productDetailsModalDetailsButtonTextDecoration;
        padding: $productDetailsModalDetailsButtonPadding;
        cursor: pointer;
      }
    }
  }

  .mn_productDataTable {
    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;

      tr {
        td {
          border-bottom: $productDetailsModalOfferCellBorder;
          padding: $productDetailsModalOfferCellPadding;
        }

        &:first-child {
          td {
            border-top: $productDetailsModalOfferCellBorder;
          }
        }

        .mn_productOffer {
          display: flex;
          justify-content: space-between;
          padding: $productDetailsModalOfferPadding;
          margin: map-get($productDetailsModalOfferMargin, small);
          position: relative;

          .mn_productOfferInfo {
            text-align: $productDetailsModalOfferInfoTextAlign;
            font-size: $productDetailsModalOfferInfoFontSize;
            margin: map-get($productDetailsModalOfferInfoMargin, small);

            p {
              margin: 0;
            }

            .mn_merchName,
            .mn_rebateWrap,
            .mn_buyPrice {
              line-height: $productDetailsModalNameAndRebateLineHeight;
            }

            .mn_merchName {
              color: $productDetailsModalNameColor;
              font-weight: $productDetailsModalNameFontWeight;
              font-size: $productDetailsModalNameFontSize;
            }

            .mn_buyPrice {
              font-weight: map-get($productDetailsModalPriceFontWeight, small);
              font-size: map-get($productDetailsModalPriceFontSize, small);
              color: $productDetailsModalPriceColor;
              margin: map-get($productDetailsModalPriceMargin, small);
            }

            .mn_premierMerchantRebate {
              font-size: map-get($productDetailsModalRebateFontSize, small);

              .mn_rebatePrefix,
              .mn_rebateValueWithCurrency {
                color: $productDetailsModalRebatePrefixColor;
                font-weight: $productDetailsModalRebatePrefixFontWeight;
                font-size: map-get($productDetailsModalRebateFontSize, small);

                .mn_elevationOldValue {
                  color: $productDetailsModalRebateElevationOldValue;
                  font-weight: $productDetailsModalRebateElevationOldValueFontWeight;
                }

                .mn_elevationNewValue {
                  font-size: map-get($productDetailsModalRebateFontSize, small);
                }
              }

              .mn_inlineRebate .mn_elevationOldValue:after {
                content: $productDetailsModalRebateOldValueAfterContent;
              }
            }
          }

          .mn_extraRewardsPill + .mn_productOfferInfo,
          .mn_salePill + .mn_productOfferInfo {
            margin: $productDetailsModalPillOfferInfoMargin;
          }

          .mn_extraRewardsPill,
          .mn_salePill {
            top: 0;
            bottom: 0;
            left: 0;
            width: $productDetailsModalPillWidth;
            display: flex;
            justify-content: center;
            transform: none;
            height: unset;
            border-radius: $productDetailsModalPillBorderRadius;
            text-transform: $productDetailsModalPillTextTransform;

            .mn_pillLabel {
              transform: rotate(-90deg);
              align-items: center;
              justify-content: center;
              display: flex;
              font-size: $productDetailsModalPillLabelFontSize;
              font-weight: $productDetailsModalPillLabelFontWeight;
              padding: $productDetailsModalPillLabelPadding;

              &:after,
              &:before {
                display: none;
              }
            }
          }

          .mn_salePill {
            background: $productDetailsModalSalePillBackground;

            .mn_pillLabel {
              color: $productDetailsModalSalePillLabelColor;
            }
          }

          .mn_extraRewardsPill {
            background: $productDetailsModalExtraRewardsPillBackground;
          }

          .mn_merchantClickUrlLink {
            font-size: $productDetailsModalClickLinkFontSize;
            padding: $productDetailsModalClickLinkPadding;
            border-radius: $productDetailsModalClickLinkBorderRadius;
            min-width: $productDetailsModalClickLinkMinWidth;
            border: $productDetailsModalClickLinkBorder;
            line-height: $productDetailsModalClickLinkLineHeight;
            font-weight: $productDetailsModalClickLinkFontWeight;
            background: $productDetailsModalClickLinkBackground;
            text-transform: $productDetailsModalClickLinkTextTransform;

            &:after {
              content: $productDetailsModalClickLinkAfterContent;
              font-family: $productDetailsModalClickLinkAfterFontFamily;
              font-size: $productDetailsModalClickLinkAfterFontSize;
              font-weight: $productDetailsModalClickLinkAfterFontWeight;
              padding-left: $productDetailsModalClickLinkAfterPaddingLeft;
            }

            &:hover {
              background: $productDetailsModalClickLinkHoverBackground;
              color: $productDetailsModalClickLinkHoverColor;
            }
          }
        }
      }
    }

    thead {
      display: none;
    }
  }

  @media #{$medium-screen} {
    .mn_mainContent {
      display: flex;
      margin: $productDetailsModalContentMargin;

      .mn_productInfo {
        margin: map-get($productDetailsModalInfoMargin, medium);

        .mn_productName {
          margin: map-get($productDetailsModalProductNameMargin, medium);
          font-size: map-get($productDetailsModalProductNameFontSize, medium);
        }

        .mn_compare {
          font-size: map-get($productDetailsModalCompareTextFontSize, medium);
        }

        ul {
          font-size: map-get($productDetailsModalListFontSize, medium);
        }
      }
    }

    .mn_productDataTable {
      table {
        thead {
          display: table-header-group;
          background: $productDetailsModalTableHeadBackground;
          color: $productDetailsModalTableHeadColor;
          font-size: $productDetailsModalTableHeadFontSize;
          text-transform: $productDetailsModalTableHeadTextTransform;
          line-height: $productDetailsModalTableHeadLineHeight;

          tr {
            display: flex;
            justify-content: space-between;
            margin: $productDetailsModalTableHeadRowMargin;

            th {
              text-align: left;
              padding: $productDetailsModalTableHeadCellPadding;

              &.mn_merchName {
                width: $productDetailsModalTableHeadMerchNameWidth;
              }

              &.mn_buyPrice {
                width: $productDetailsModalTableHeadBuyPriceWidth;
              }

              &.mn_rebTxt {
                width: $productDetailsModalTableHeadRebTxtWidth;
              }

              &.mn_actionLink {
                width: $productDetailsModalTableHeadActionLinkWidth;
              }
            }
          }
        }

        tbody {
          .mn_productOffer {
            width: 100%;
            padding: 2rem 0;
            align-items: center;
            margin: map-get($productDetailsModalOfferMargin, medium);

            .mn_productOfferInfo {
              width: 72%;
              display: flex;
              justify-content: space-between;
              margin: 0 0 0 3rem;

              .mn_merchName {
                width: $productDetailsModalTableBodyMerchNameWidth;
                text-align: left;
                align-content: $productDetailsModalTableBodyAlignContent;
              }

              .mn_buyPrice {
                width: $productDetailsModalTableBodyBuyPriceWidth;
                text-align: left;
                align-content: $productDetailsModalTableBodyAlignContent;
                font-weight: map-get($productDetailsModalPriceFontWeight, medium);
                font-size: map-get($productDetailsModalPriceFontSize, medium);
                margin: map-get($productDetailsModalPriceMargin, medium);
              }

              .mn_rebateWrap {
                width: $productDetailsModalTableBodyRebateWrapWidth;
              }

              .mn_premierMerchantRebate {
                font-size: map-get($productDetailsModalRebateFontSize, medium);
              }
            }

            .mn_actionLink {
              padding: 0 1.5rem 0 0;
            }
          }
        }
      }
    }
  }
}
