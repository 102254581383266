@import '~org/styles/variables';

$wistiaVideoYoutubeVideoBackgroundColor: () !default;
$wistiaVideoExitYoutubeContentBorder: none !default;
$wistiaVideoExitYoutubeContentBackground: none !default;
$wistiaVideoExitYoutubeContentBeforeColor: () !default;
$wistiaVideoExitYoutubeContentBeforeFontSize: () !default;
$wistiaVideoExitYoutubeContentBeforeLetterSpacing: () !default;
$wistiaVideoExitYoutubeContentBeforeFontFamily: () !default;
$wistiaVideoExitYoutubeContentBeforeContent: () !default;

.mn_youtubeVideo {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $wistiaVideoYoutubeVideoBackgroundColor;

  .mn_youtubeVideoContent,
  .mn_wistiaContent {
    width: 100%;
  }

  .mn_exitYoutubeContent {
    z-index: 1;
    border: $wistiaVideoExitYoutubeContentBorder;
    background: $wistiaVideoExitYoutubeContentBackground;
    margin: 10px 10px 0 0;
    position: absolute;
    top: 0;
    right: 0;

    &:before {
      color: $wistiaVideoExitYoutubeContentBeforeColor;
      font-size: $wistiaVideoExitYoutubeContentBeforeFontSize;
      letter-spacing: $wistiaVideoExitYoutubeContentBeforeLetterSpacing;
      font-family: $wistiaVideoExitYoutubeContentBeforeFontFamily;
      content: $wistiaVideoExitYoutubeContentBeforeContent;
    }
  }

  .wistia_responsive_padding {
    padding: 56.25% 0 0 0;
    position: relative;

    .wistia_responsive_wrapper {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      .wistia_embed {
        height: 100%;
        position: relative;
        width: 100%;

        .wistia_swatch {
          height: 100%;
          left: 0;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          top: 0;
          transition: opacity 200ms;
          width: 100%;

          &.wistia_swatch_opacity {
            opacity: 1;
          }

          img {
            filter: blur(5px);
            height: 100%;
            object-fit: contain;
            width: 100%;
          }
        }
      }
    }
  }
}
