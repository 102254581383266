@import '~org/styles/variables';

.mn_mobileNavWelcomeMessage {
  text-align: left;
  padding: 1.5rem 2rem;
  color: $brandWhite;
  background: $brandHeaderColor;
  position: relative;

  .mn_userInfoWrap {
    padding: .5rem 0;
    margin: 0 0 1rem;
    display: flex;
    align-items: baseline;
    font-size: 1.2rem;
  }

  .mn_accountLink {
    padding-right: 1rem;
    position: relative;
  }

  .mn_navLogoutLink {
    color: $brandWhite;
    font-weight: 400;
    text-decoration: underline;
  }

  .mn_button {
    display: block;
    max-width: 22rem;
    text-transform: none;
    padding: 0;
    margin: 0 auto;
    height: 4.8rem;
    line-height: 4.8rem;

    &.mn_logoutLink {
      margin: 1rem auto 0;
    }
  }

  p {
    margin: 0;
  }

  strong {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
}
