@import '~org/styles/variables';

.mn_brandFooter {
  text-align: center;
  background-color: $brandTertiaryColor;
  color: $brandWhite;

  a {
    color: $brandWhite;
  }

  .mn_usaaLogoWrap {
    .mn_usaaLogo {
      width: 15rem;
      height: 8.7rem;
      margin: 0 auto;
    }
  }

  .mn_links {
    background: $brandSecondaryColor;
  }

  ul {
    display: inline-block;
    background: $brandSecondaryColor;
    width: 100%;
    font-size: 1.4rem;
    padding: 3rem 0;
    margin: 0;

    li {
      display: inline-block;
      padding: 0 1rem;
    }
  }

  .mn_footerTermText {
    font-size: 1.3rem;
    padding: 3rem 0;
    text-align: left;
  }

  .mn_backToTopButton {
    margin: 0 auto 3rem;
    background: none;
    border: none;
    color: $brandWhite;
    text-decoration: underline;
    text-align: center;

    &:hover {
      cursor: pointer;
    }
  }

  @media #{$medium-screen} {
    ul {
      font-size: 1.5rem;
    }

    .mn_footerTermText {
      padding: 3rem 0;
    }
  }
}

@media #{$less-than-medium-screen} {
  .mn_VHP .mn_backToTopButton {
    margin: 0 auto;
    padding: 0 0 3rem;
  }

  .mn_MHP .mn_brandFooter {
    padding-bottom: 4rem;
  }
}
