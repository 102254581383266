@import '~org/styles/variables';

$hybridSearchFormInputBackground: none !default;
$hybridSearchFormBackgroundColor: () !default;
$hybridSearchFormInputColor: $brandTextColor !default;
$hybridSearchFormInputFontSize: 1.6rem !default;
$hybridSearchFormInputFontWeight: () !default;
$hybridSearchFormInputLineHeight: 4.5rem !default;
$hybridSearchFormInputHeight: 4.5rem !default;
$hybridSearchFormInputTextTransform: () !default;
$hybridSearchFormInputPadding: () !default;
$hybridSearchFormInputBorderRadius: 0 !default;
$hybridSearchFormInputBorder: 1px solid transparent !default;
$hybridSearchFormInputOutline: 0 !default;
$hybridSearchFormInputBorderWidth: () !default;
$hybridSearchFormSubmitButtonColor: $brandNeutralDarkColor !default;
$hybridSearchFormSubmitButtonFontSize: 2.5rem !default;
$hybridSearchFormSubmitButtonHeight: 4.5rem !default;
$hybridSearchFormSubmitButtonLineHeight: 4.5rem !default;
$hybridSearchFormSubmitButtonWidth: 3.5rem !default;
$hybridSearchFormSubmitButtonRight: 0 !default;
$hybridSearchFormSubmitButtonLeft: () !default;
$hybridSearchFormSubmitButtonTop: () !default;
$hybridSearchFormFocusBorder: 1px solid $brandNeutralDarkColor !default;
$hybridSearchFormFocusBorderWidth: () !default;
$hybridSearchFormFocusPosition: () !default;
$hybridSearchFormFocusZIndex: () !default;

$hybridSearchFormSubmitButtonIconBeforeFontFamily: () !default;
$hybridSearchFormSubmitButtonIconBeforeContent: () !default;
$hybridSearchFormSubmitButtonIconBeforeFontSize: () !default;
$hybridSearchFormSubmitButtonIconBeforePositionRight: () !default;
$hybridSearchFormSubmitButtonIconBeforePosition: () !default;
$hybridSearchFormSubmitButtonIconBeforePositionTop: () !default;
$hybridSearchFormSubmitButtonIconBeforeColor: () !default;
$hybridSearchFormSubmitButtonIconBeforeFontStyle: () !default;
$hybridSearchFormSubmitButtonIconBeforeZIndex: () !default;
$hybridSearchFormSubmitButtonOnFocusIconBeforeColor: () !default;

$hybridSearchFormOnFocusAfterContent: () !default;
$hybridSearchFormOnFocusAfterDisplay: () !default;
$hybridSearchFormOnFocusAfterPosition: () !default;
$hybridSearchFormOnFocusAfterPositionBottom: () !default;
$hybridSearchFormOnFocusAfterHeight: () !default;
$hybridSearchFormOnFocusAfterBackground: () !default;
$hybridSearchFormOnFocusAfterWidth: () !default;
$hybridSearchFormOnFocusAfterPositionLeft: () !default;
$hybridSearchFormOnFocusAfterZIndex: () !default;

$hybridSearchFormFocusBackground: () !default;
$hybridSearchFormFocusColor: () !default;
$hybridSearchFormFocusPlaceholderColor: () !default;
$hybridSearchFormPosition: (small: relative, medium: absolute, large: absolute) !default;
$hybridSearchFormPositionLeft: (small: 0) !default;
$hybridSearchFormPositionRight: (small: 0) !default;
$hybridSearchFormPositionTop: () !default;
$hybridSearchFormBackground: () !default;
$hybridSearchFormMargin: 1rem 0 0 !default;
$hybridSearchFormBoxShadow: (small: (), medium: ()) !default;
$hybridSearchFormWidth: () !default;
$hybridSearchFormBorder: () !default;

$hybridSearchFormPlaceholderColor: initial !default;
$hybridSearchFormPlaceholderLetterSpacing: () !default;
$hybridSearchFormPlaceholderTextTransform: () !default;
$hybridSearchFormPlaceholderFontWeight: () !default;
$hybridSearchFormPlaceholderFontSize: () !default;
$hybridSearchFormPlaceholderOpacity: () !default;

$hybridSearchFormDesktopInputBorder: none !default;
$hybridSearchFormDesktopInputBorderRadius: () !default;
$hybridSearchFormDesktopInputBorderBottom: .75px solid rgba(212, 212, 208, .5) !default;
$hybridSearchFormDesktopInputFontSize: 1.2rem !default;
$hybridSearchFormDesktopInputHeight: 2.4rem !default;
$hybridSearchFormDesktopInputPadding: .4rem 3.5rem .4rem .2rem !default;
$hybridSearchFormDesktopInputCursor: () !default;
$hybridSearchFormDesktopHoverOutline: () !default;
$hybridSearchFormDesktopHoverBoxShadow: () !default;
$hybridSearchFormDesktopHoverBackground: () !default;
$hybridSearchFormDesktopHoverBorder: () !default;
$hybridSearchFormDesktopHoverBorderWidth: () !default;

$hybridSearchFormDesktopSubmitPositionTop: 0 !default;
$hybridSearchFormDesktopSubmitPositionLeft: () !default;
$hybridSearchFormDesktopSubmitHeight: 2.4rem !default;
$hybridSearchFormDesktopSubmitLineheight: 2.4rem !default;
$hybridSearchFormDesktopSubmitFontSize: 1.6rem !default;
$hybridSearchFormDesktopSubmitWidth: 1.6rem !default;

$hybridSearchFormDesktopDropDownPositionLeft: -1rem !default;
$hybridSearchFormDesktopDropDownPositionRight: -13rem !default;
$hybridSearchFormDesktopDropDownPositionTop: 2.5rem !default;
$hybridSearchFormDesktopDropDownWidth: () !default;
$hybridSearchFormDesktopDropDownBoxShadow: () !default;
$hybridSearchFormDesktopDropDownBorder: () !default;

$recentStoresWithQuickSearchMarginTop: () !default;

.mn_hybridSearchForm {
  position: relative;
  background: $hybridSearchFormBackgroundColor;

  label {
    font-size: 0;
  }

  .mn_searchInput {
    background: $hybridSearchFormInputBackground;
    padding: $hybridSearchFormInputPadding;
    border-radius: $hybridSearchFormInputBorderRadius;
    border: $hybridSearchFormInputBorder;
    border-width: $hybridSearchFormInputBorderWidth;
    color: $hybridSearchFormInputColor;
    width: 100%;
    font-size: $hybridSearchFormInputFontSize;
    font-weight: $hybridSearchFormInputFontWeight;
    line-height: $hybridSearchFormInputLineHeight;
    height: $hybridSearchFormInputHeight;
    outline: $hybridSearchFormInputOutline;
    text-transform: $hybridSearchFormInputTextTransform;

    &::-webkit-input-placeholder {
      color: $hybridSearchFormPlaceholderColor;
      letter-spacing: $hybridSearchFormPlaceholderLetterSpacing;
      text-transform: $hybridSearchFormPlaceholderTextTransform;
      font-weight: $hybridSearchFormPlaceholderFontWeight;
      font-size: $hybridSearchFormPlaceholderFontSize;
      opacity: $hybridSearchFormPlaceholderOpacity;
    }

    &::-moz-placeholder {
      color: $hybridSearchFormPlaceholderColor;
      letter-spacing: $hybridSearchFormPlaceholderLetterSpacing;
      text-transform: $hybridSearchFormPlaceholderTextTransform;
      font-weight: $hybridSearchFormPlaceholderFontWeight;
      font-size: $hybridSearchFormPlaceholderFontSize;
      opacity: $hybridSearchFormPlaceholderOpacity;
    }

    &:-moz-placeholder {
      color: $hybridSearchFormPlaceholderColor;
      letter-spacing: $hybridSearchFormPlaceholderLetterSpacing;
      text-transform: $hybridSearchFormPlaceholderTextTransform;
      font-weight: $hybridSearchFormPlaceholderFontWeight;
      font-size: $hybridSearchFormPlaceholderFontSize;
      opacity: $hybridSearchFormPlaceholderOpacity;
    }

    &:-ms-input-placeholder {
      color: $hybridSearchFormPlaceholderColor;
      letter-spacing: $hybridSearchFormPlaceholderLetterSpacing;
      text-transform: $hybridSearchFormPlaceholderTextTransform;
      font-weight: $hybridSearchFormPlaceholderFontWeight;
      font-size: $hybridSearchFormPlaceholderFontSize;
      opacity: $hybridSearchFormPlaceholderOpacity;
    }

    &:focus {
      border: $hybridSearchFormFocusBorder;
      border-width: $hybridSearchFormFocusBorderWidth;
      background: $hybridSearchFormFocusBackground;
      color: $hybridSearchFormFocusColor;
      position: $hybridSearchFormFocusPosition;
      z-index: $hybridSearchFormFocusZIndex;
    }

    &:focus::placeholder {
      color: $hybridSearchFormFocusPlaceholderColor;
    }
  }

  .mn_searchSubmit {
    position: absolute;
    right: $hybridSearchFormSubmitButtonRight;
    left: $hybridSearchFormSubmitButtonLeft;
    top: $hybridSearchFormSubmitButtonTop;
    height: $hybridSearchFormSubmitButtonHeight;
    width: $hybridSearchFormSubmitButtonWidth;
    text-align: center;
    background: none;
    border: none;
    padding: 0;
    color: $hybridSearchFormSubmitButtonColor;
    font-size: $hybridSearchFormSubmitButtonFontSize;
    line-height: $hybridSearchFormSubmitButtonLineHeight;

    i:before {
      font-family: $hybridSearchFormSubmitButtonIconBeforeFontFamily;
      content: $hybridSearchFormSubmitButtonIconBeforeContent;
      font-size: $hybridSearchFormSubmitButtonIconBeforeFontSize;
      right: $hybridSearchFormSubmitButtonIconBeforePositionRight;
      position: $hybridSearchFormSubmitButtonIconBeforePosition;
      top: $hybridSearchFormSubmitButtonIconBeforePositionTop;
      color: $hybridSearchFormSubmitButtonIconBeforeColor;
      font-style: $hybridSearchFormSubmitButtonIconBeforeFontStyle;
      z-index: $hybridSearchFormSubmitButtonIconBeforeZIndex;
    }
  }

  .mn_dropDownWrap {
    position: map_get($hybridSearchFormPosition, small);
    left: map_get($hybridSearchFormPositionLeft, small);
    right: map_get($hybridSearchFormPositionRight, small);
    top: map_get($hybridSearchFormPositionTop, small);
    z-index: 11;
    background: $hybridSearchFormBackground;
    margin: $hybridSearchFormMargin;
    box-shadow: map_get($hybridSearchFormBoxShadow, small);
    overflow: hidden;

    @media #{$medium-screen} {
      position: map_get($hybridSearchFormPosition, medium);
      left: map_get($hybridSearchFormPositionLeft, medium);
      right: map_get($hybridSearchFormPositionRight, medium);
      top: map_get($hybridSearchFormPositionTop, medium);
      box-shadow: map_get($hybridSearchFormBoxShadow, medium);
      width: $hybridSearchFormWidth;
      border: $hybridSearchFormBorder;
    }

    @media #{$xlarge-screen} {
      position: map_get($hybridSearchFormPosition, large);
      left: map_get($hybridSearchFormPositionLeft, large);
      right: map_get($hybridSearchFormPositionRight, large);
      top: map_get($hybridSearchFormPositionTop, large);
    }
  }

  &.mn_searchOnFocus {
    &:after {
      content: $hybridSearchFormOnFocusAfterContent;
      display: $hybridSearchFormOnFocusAfterDisplay;
      position: $hybridSearchFormOnFocusAfterPosition;
      bottom: $hybridSearchFormOnFocusAfterPositionBottom;
      height: $hybridSearchFormOnFocusAfterHeight;
      background: $hybridSearchFormOnFocusAfterBackground;
      width: $hybridSearchFormOnFocusAfterWidth;
      left: $hybridSearchFormOnFocusAfterPositionLeft;
      z-index: $hybridSearchFormOnFocusAfterZIndex;
    }

    .mn_icon:before {
      color: $hybridSearchFormSubmitButtonOnFocusIconBeforeColor;
    }
  }
}

/* Desktop header Search */
.mn_searchAndNavWrap {
  .mn_hybridSearchForm {
    .mn_searchInput {
      border: $hybridSearchFormDesktopInputBorder;
      border-radius: $hybridSearchFormDesktopInputBorderRadius;
      border-bottom: $hybridSearchFormDesktopInputBorderBottom;
      font-size: $hybridSearchFormDesktopInputFontSize;
      height: $hybridSearchFormDesktopInputHeight;
      padding: $hybridSearchFormDesktopInputPadding;
      cursor: $hybridSearchFormDesktopInputCursor;
      display: block;

      &:focus {
        outline: $hybridSearchFormDesktopHoverOutline;
        box-shadow: $hybridSearchFormDesktopHoverBoxShadow;
        background: $hybridSearchFormDesktopHoverBackground;
        border: $hybridSearchFormDesktopHoverBorder;
        border-width: $hybridSearchFormDesktopHoverBorderWidth;
      }
    }

    .mn_searchSubmit {
      top: $hybridSearchFormDesktopSubmitPositionTop;
      left: $hybridSearchFormDesktopSubmitPositionLeft;
      height: $hybridSearchFormDesktopSubmitHeight;
      line-height: $hybridSearchFormDesktopSubmitLineheight;
      font-size: $hybridSearchFormDesktopSubmitFontSize;
      width: $hybridSearchFormDesktopSubmitWidth;
    }

    .mn_dropDownWrap {
      left: $hybridSearchFormDesktopDropDownPositionLeft;
      right: $hybridSearchFormDesktopDropDownPositionRight;
      top: $hybridSearchFormDesktopDropDownPositionTop;
      width: $hybridSearchFormDesktopDropDownWidth;
      box-shadow: $hybridSearchFormDesktopDropDownBoxShadow;
      border: $hybridSearchFormDesktopDropDownBorder;
    }
  }
}
