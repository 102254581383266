@import '~org/styles/mixins';
@import '~org/styles/variables';

$rebateV2Color: () !default;
$rebateV2PrefixColor: () !default;
$rebateV2SuffixColor: () !default;
$rebateV2SuffixFontWeight: () !default;

$rebateV2ValueColor: () !default;
$rebateV2ValueWithCurrencyFontWeight: () !default;
$rebateV2CurrencyColor: () !default;
$rebateV2CurrencyFontWeight: () !default;
$rebateV2OldValueColor: #666 !default;
$rebateV2OldValueLineHeight: 1.9rem !default;
$rebateV2OldValueFontSize: 1.2rem !default;
$rebateV2NewValueFontSize: 1.4rem !default;
$rebateV2NewValueFontWeight: 500 !default;
$rebateV2NewValueFontColor: () !default;
$rebateV2NewValueLineHeight: 1.6rem !default;
$rebateV2NewValueLetterSpacing: () !default;
$rebateV2NewValueTextTransform: () !default;

$rebateV2TierNameColor: $rebateV2ValueColor !default;
$rebateV2TierNameFontWeight: $rebateV2NewValueFontWeight !default;
$rebateV2TierNameFontSize: $rebateV2NewValueFontSize !default;
$rebateV2TierNameTextTransform: $rebateV2NewValueTextTransform !default;

.mn_merchantRebateV2 {
  color: $rebateV2Color;

  .mn_tierName {
    color: $rebateV2TierNameColor;
    font-weight: $rebateV2TierNameFontWeight;
    font-size: $rebateV2TierNameFontSize;
    text-transform: $rebateV2TierNameTextTransform;
  }

  .mn_rebatePrefix {
    color: $rebateV2PrefixColor;
  }

  .mn_rebateSuffix {
    color: $rebateV2SuffixColor;
  }

  .mn_rebateValueWithCurrency {
    font-weight: $rebateV2ValueWithCurrencyFontWeight;

    .mn_rebateValue {
      color: $rebateV2ValueColor;

      .mn_elevationOldValue {
        color: $rebateV2OldValueColor;
        font-size: $rebateV2OldValueFontSize;
        line-height: $rebateV2OldValueLineHeight;
      }

      .mn_elevationNewValue {
        font-size: $rebateV2NewValueFontSize;
        font-weight: $rebateV2NewValueFontWeight;
        color: $rebateV2NewValueFontColor;
        line-height: $rebateV2NewValueLineHeight;
        letter-spacing: $rebateV2NewValueLetterSpacing;
        text-transform: $rebateV2NewValueTextTransform;

        &:first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
}
