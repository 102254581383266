@import '~org/styles/variables';

.mn_searchTriggerWrapper {
  position: relative;

  button {
    display: block;
    width: 3rem;
    padding: 0;
    margin: .3rem 0 0;
    background: none;
    border: none;

    .mn_searchTriggerClose {
      display: none;
    }

    .mn_searchTriggerOpen {
      position: relative;
      top: -.2rem;
    }

    &[aria-expanded='true'] {
      .mn_searchTriggerClose {
        display: block;
      }

      .mn_searchTriggerOpen {
        display: none;
      }
    }
  }
}
