@import '~org/styles/variables';

$searchFormInputBackground: none !default;
$searchFormBackgroundColor: () !default;
$searchFormInputColor: $brandTextColor !default;
$searchFormInputFontSize: 1.6rem !default;
$searchFormInputFontWeight: () !default;
$searchFormInputLineHeight: 4.5rem !default;
$searchFormInputHeight: 4.5rem !default;
$searchFormInputTextTransform: () !default;
$searchFormInputPadding: () !default;
$searchFormInputBorderRadius: 0 !default;
$searchFormInputBorder: 1px solid transparent !default;
$searchFormInputBorderWidth: () !default;
$searchFormSubmitButtonColor: $brandNeutralDarkColor !default;
$searchFormSubmitButtonFontSize: 2.5rem !default;
$searchFormSubmitButtonHeight: 4.5rem !default;
$searchFormSubmitButtonLineHeight: 4.5rem !default;
$searchFormSubmitButtonWidth: 3.5rem !default;
$searchFormSubmitButtonRight: 0 !default;
$searchFormSubmitButtonLeft: () !default;
$searchFormSubmitButtonTop: () !default;
$searchFormFocusBorderColor: $brandNeutralDarkColor !default;
$searchFormFocusBackground: () !default;
$searchFormFocusPlaceholderColor: () !default;
$searchFormPosition: (small: relative, medium: absolute, large: absolute) !default;
$searchFormPositionLeft: (small: 0) !default;
$searchFormPositionRight: (small: 0) !default;
$searchFormPositionTop: () !default;
$searchFormBackground: () !default;
$searchFormMargin: 1rem 0 0 !default;
$searchFormBoxShadow: (small: (), medium: ()) !default;

$searchFieldPlaceholderColor: initial !default;
$searchFieldPlaceholderLetterSpacing: () !default;
$searchFieldPlaceholderTextTransform: () !default;
$searchFieldPlaceholderFontWeight: () !default;
$searchFieldPlaceholderFontSize: () !default;
$searchFieldPlaceholderOpacity: () !default;

$searchFormDesktopInputBorder: none !default;
$searchFormDesktopInputBorderRadius: () !default;
$searchFormDesktopInputBorderBottom: .75px solid rgba(212, 212, 208, .5) !default;
$searchFormDesktopInputFontSize: 1.2rem !default;
$searchFormDesktopInputHeight: 2.4rem !default;
$searchFormDesktopInputPadding: .4rem 3.5rem .4rem .2rem !default;
$searchFormDesktopInputCursor: () !default;
$searchFormDesktopHoverOutline: () !default;
$searchFormDesktopHoverBoxShadow: () !default;

$searchFormDesktopSubmitPositionTop: 0 !default;
$searchFormDesktopSubmitPositionLeft: () !default;
$searchFormDesktopSubmitHeight: 2.4rem !default;
$searchFormDesktopSubmitLineheight: 2.4rem !default;
$searchFormDesktopSubmitFontSize: 1.6rem !default;
$searchFormDesktopSubmitWidth: 1.6rem !default;

$searchFormDesktopDropDownPositionLeft: -1rem !default;
$searchFormDesktopDropDownPositionRight: -13rem !default;
$searchFormDesktopDropDownPositionTop: 2.5rem !default;
$searchFormDesktopDropDownWidth: () !default;
$searchFormDesktopDropDownBoxShadow: () !default;

$recentStoresWithQuickSearchMarginTop: () !default;

.mn_searchForm {
  position: relative;
  background: $searchFormBackgroundColor;

  label {
    font-size: 0;
  }

  .mn_searchInput {
    background: $searchFormInputBackground;
    padding: $searchFormInputPadding;
    border-radius: $searchFormInputBorderRadius;
    border: $searchFormInputBorder;
    border-width: $searchFormInputBorderWidth;
    color: $searchFormInputColor;
    width: 100%;
    font-size: $searchFormInputFontSize;
    font-weight: $searchFormInputFontWeight;
    line-height: $searchFormInputLineHeight;
    height: $searchFormInputHeight;
    outline: 0;
    text-transform: $searchFormInputTextTransform;

    &::-webkit-input-placeholder {
      color: $searchFieldPlaceholderColor;
      letter-spacing: $searchFieldPlaceholderLetterSpacing;
      text-transform: $searchFieldPlaceholderTextTransform;
      font-weight: $searchFieldPlaceholderFontWeight;
      font-size: $searchFieldPlaceholderFontSize;
      opacity: $searchFieldPlaceholderOpacity;
    }

    &::-moz-placeholder {
      color: $searchFieldPlaceholderColor;
      letter-spacing: $searchFieldPlaceholderLetterSpacing;
      text-transform: $searchFieldPlaceholderTextTransform;
      font-weight: $searchFieldPlaceholderFontWeight;
      font-size: $searchFieldPlaceholderFontSize;
      opacity: $searchFieldPlaceholderOpacity;
    }

    &:-moz-placeholder {
      color: $searchFieldPlaceholderColor;
      letter-spacing: $searchFieldPlaceholderLetterSpacing;
      text-transform: $searchFieldPlaceholderTextTransform;
      font-weight: $searchFieldPlaceholderFontWeight;
      font-size: $searchFieldPlaceholderFontSize;
      opacity: $searchFieldPlaceholderOpacity;
    }

    &:-ms-input-placeholder {
      color: $searchFieldPlaceholderColor;
      letter-spacing: $searchFieldPlaceholderLetterSpacing;
      text-transform: $searchFieldPlaceholderTextTransform;
      font-weight: $searchFieldPlaceholderFontWeight;
      font-size: $searchFieldPlaceholderFontSize;
      opacity: $searchFieldPlaceholderOpacity;
    }

    &:focus {
      border-color: $searchFormFocusBorderColor;
      background: $searchFormFocusBackground;
    }

    &:focus::placeholder {
      color: $searchFormFocusPlaceholderColor;
    }
  }

  .mn_searchSubmit {
    position: absolute;
    right: $searchFormSubmitButtonRight;
    left: $searchFormSubmitButtonLeft;
    top: $searchFormSubmitButtonTop;
    height: $searchFormSubmitButtonHeight;
    width: $searchFormSubmitButtonWidth;
    text-align: center;
    background: none;
    border: none;
    padding: 0;
    color: $searchFormSubmitButtonColor;
    font-size: $searchFormSubmitButtonFontSize;
    line-height: $searchFormSubmitButtonLineHeight;
  }

  .mn_dropDownWrap {
    position: map_get($searchFormPosition, small);
    left: map_get($searchFormPositionLeft, small);
    right: map_get($searchFormPositionRight, small);
    top: map_get($searchFormPositionTop, small);
    z-index: 11;
    background: $searchFormBackground;
    margin: $searchFormMargin;
    box-shadow: map_get($searchFormBoxShadow, small);
    overflow: hidden;

    @media #{$medium-screen} {
      position: map_get($searchFormPosition, medium);
      left: map_get($searchFormPositionLeft, medium);
      right: map_get($searchFormPositionRight, medium);
      top: map_get($searchFormPositionTop, medium);
      box-shadow: map_get($searchFormBoxShadow, medium);
    }

    @media #{$xlarge-screen} {
      position: map_get($searchFormPosition, large);
      left: map_get($searchFormPositionLeft, large);
      right: map_get($searchFormPositionRight, large);
      top: map_get($searchFormPositionTop, large);
    }

    &:empty {
      display: none;
    }

    .mn_quickSearch:not(:empty) {
      & + .mn_recentStores {
        margin-top: $recentStoresWithQuickSearchMarginTop;
      }
    }
  }
}

/* Desktop header Search */
.mn_searchAndNavWrap {
  .mn_searchForm {
    .mn_searchInput {
      border: $searchFormDesktopInputBorder;
      border-radius: $searchFormDesktopInputBorderRadius;
      border-bottom: $searchFormDesktopInputBorderBottom;
      font-size: $searchFormDesktopInputFontSize;
      height: $searchFormDesktopInputHeight;
      padding: $searchFormDesktopInputPadding;
      cursor: $searchFormDesktopInputCursor;
      display: block;

      &:hover,
      &:focus {
        outline: $searchFormDesktopHoverOutline;
        box-shadow: $searchFormDesktopHoverBoxShadow;
      }
    }

    .mn_searchSubmit {
      top: $searchFormDesktopSubmitPositionTop;
      left: $searchFormDesktopSubmitPositionLeft;
      height: $searchFormDesktopSubmitHeight;
      line-height: $searchFormDesktopSubmitLineheight;
      font-size: $searchFormDesktopSubmitFontSize;
      width: $searchFormDesktopSubmitWidth;
    }

    .mn_dropDownWrap {
      left: $searchFormDesktopDropDownPositionLeft;
      right: $searchFormDesktopDropDownPositionRight;
      top: $searchFormDesktopDropDownPositionTop;
      width: $searchFormDesktopDropDownWidth;
      box-shadow: $searchFormDesktopDropDownBoxShadow;
    }
  }
}
