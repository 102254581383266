@import '~org/styles/variables';

.mn_headerMainBar {
  padding: 0 0 1.5rem;
  background: $brandWhite;

  .mn_contentSection {
    max-width: $xxlarge;
    height: 4.5rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 3rem 0 2rem;
    margin: 0 auto;
  }

  .mn_searchAndNavWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 57.5rem;
    margin-left: 2.5rem;
    margin-top: 1rem;
    margin-right: 3.5rem;

    .mn_searchBar {
      padding: 0;
      margin: 0 2rem 0 0;

      .mn_searchForm {
        margin: 0;

        .mn_searchInput {
          background: 0 0;
          border-bottom: 1px solid $brandLightGray2;
          color: $brandHeaderColor;
          font-size: 1.6rem;
          font-weight: 700;
          line-height: 2.5rem;
          letter-spacing: -.6px;
          height: 3.5rem;
          border-radius: 0;

          &:focus {
            background: $brandLightColor;
            border-bottom: 2px solid $brandHeaderColor;
            border-radius: 0;
            outline: 0;
          }
        }

        .mn_dropDownWrap {
          left: -8rem;
          right: 0;
          top: 3.5rem;
          margin: 0;
          box-shadow: 0 2px 4px 0 $brandLightBlue3;
        }
      }

      .mn_searchSubmit {
        position: absolute;
        top: 0;
        right: -.7rem;
        background: 0 0;
        border: none;
        height: 3.5rem;
        line-height: 4.5rem;
        font-size: 2.2rem;
        width: 3rem;
        padding: .2rem 0 0 0;

        .mn_usaaSVGIconSearch:before {
          height: 2rem;
          width: 2rem;
        }
      }
    }
  }

  .mn_headerTopNavigation {
    display: flex;
    flex: 0 0 auto;
    padding: 0;
    margin: 0 2rem 0 10%;
    align-items: center;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      border-right: .75px solid rgba(212, 212, 208, .5);
      top: -.2rem;
      bottom: -.2rem;
      right: -1.5rem;
    }

    .mn_searchTrigger {
      outline: none;

      &[aria-expanded='true'] {
        &:before {
          content: '\e90c';
        }
      }
    }
  }

  .mn_navigationButtonsWrap {
    display: flex;
  }

  .mn_usaaLogoWrap {
    padding: 0 0 0 1.5rem;

    .mn_usaaLogo {
      display: inline-block;
      width: 12.3rem;
    }
  }

  @media #{$medium-screen} {
    padding: 0 0 4.5rem;

    .mn_contentSection {
      padding: 5rem 1rem 0;
    }
  }

  @media #{$large-screen} {
    .mn_contentSection {
      padding: 5rem 3rem 0;
    }
  }

  @media #{$xlarge-screen} {
    padding: 0 0 4.5rem;

    .mn_contentSection {
      padding: 5.5rem 3rem 0;
    }

    .mn_usaaLogoWrap {
      padding: 0 0 0 1.2rem;

      .mn_usaaLogo {
        width: 15rem;
      }
    }
  }
}
