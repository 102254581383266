@import '~org/styles/variables';

.mn_navOffersTOF {
  a {
    position: relative;

    .mn_extraRewardsPill {
      height: 2rem;
      width: 10.3rem;
      left: calc(100% + .1rem);
      top: 50%;
      transform: translateY(-50%);
      line-height: 2rem;
    }
  }
}
