@import '~org/styles/variables';

$navCategoryFeaturedItemMargin: 0 -1rem .6rem !default;
$navCategoryFeaturedItemPadding: .7rem 1rem .7rem !default;
$navCategoryFeaturedItemWidth: () !default;
$navCategoryFeaturedItemHoverColor: () !default;
$navCategoryFeaturedItemHoverTextDecoration: () !default;
$navCategoryFeaturedItemHoverColorBackground: rgba(0, 0, 0, .05) !default;
$navCategoryFeaturedItemTitleFont: () !default;
$navCategoryFeaturedItemTitleFontSize: () !default;
$navCategoryFeaturedItemTitleFontWeight: () !default;
$navCategoryFeaturedItemTitleColor: #fff !default;
$navCategoryFeaturedItemTitleMargin: 0 !default;
$navCategoryFeaturedItemTitleTextTransform: () !default;
$navCategoryFeaturedItemTitleLetterSpacing: () !default;
$navCategoryFeaturedItemTitleDisplay: () !default;
$navCategoryFeaturedItemRebateFontSize: () !default;
$navCategoryFeaturedItemRebateColor: () !default;
$navCategoryFeaturedItemRebateTextTransform: () !default;
$navCategoryFeaturedItemRebateFontWeight: () !default;
$navCategoryFeaturedItemRebateDisplay: () !default;
$navCategoryFeaturedItemInlineRebateDisplay: () !default;
$navCategoryFeaturedItemInlineRebateFontSize: () !default;
$navCategoryFeaturedItemRebateOldValueFontSize: () !default;
$navCategoryFeaturedItemRebateOldValueColor: () !default;
$navCategoryFeaturedItemRebateOldTextTransform: () !default;
$navCategoryFeaturedItemRebatePrefixColor: () !default;
$navCategoryFeaturedItemRebatePrefixTextTransform: () !default;
$navCategoryFeaturedItemRebatePrefixFontWeight: () !default;
$navCategoryFeaturedItemRebateTieredPrefixTextTransform: () !default;
$navCategoryFeaturedFooterLinkFontSize: 1.4rem !default;
$navCategoryFeaturedFooterLinkMargin: 2rem 0 0 !default;
$navCategoryFeaturedFooterLinkColor: #fff !default;
$navCategoryFeaturedFooterLinkPadding: () !default;
$navCategoryFeaturedFooterLinkFontWeight: () !default;
$navCategoryFeaturedFooterLinkLetterSpacing: () !default;
$navCategoryFeaturedFooterLinkCategoryTextTransform: () !default;
$navCategoryFeaturedFooterLinkHoverColor: () !default;
$navCategoryFeaturedItemElevationNewValueColor: #fff !default;
$navCategoryFeaturedItemElevationNewValueFontWeight: bold !default;
$navCategoryFeaturedItemBorderBottom: () !default;

.mn_navCategoryFeaturedSection {
  .mn_navCategoryFeaturedItem {
    display: block;
    margin: $navCategoryFeaturedItemMargin;
    padding: $navCategoryFeaturedItemPadding;
    border-bottom: $navCategoryFeaturedItemBorderBottom;
    width: $navCategoryFeaturedItemWidth;
    text-decoration: none;
    background: none;
    transition: background .5s ease-out;
    position: relative;

    h3 {
      font: $navCategoryFeaturedItemTitleFont;
      font-size: $navCategoryFeaturedItemTitleFontSize;
      font-weight: $navCategoryFeaturedItemTitleFontWeight;
      color: $navCategoryFeaturedItemTitleColor;
      margin: $navCategoryFeaturedItemTitleMargin;
      text-transform: $navCategoryFeaturedItemTitleTextTransform;
      letter-spacing: $navCategoryFeaturedItemTitleLetterSpacing;
      display: $navCategoryFeaturedItemTitleDisplay;
    }

    .mn_premierMerchantRebate {
      font-size: $navCategoryFeaturedItemRebateFontSize;
      color: $navCategoryFeaturedItemRebateColor;
      font-weight: $navCategoryFeaturedItemRebateFontWeight;
      display: $navCategoryFeaturedItemRebateDisplay;

      .mn_inlineRebate {
        display: $navCategoryFeaturedItemInlineRebateDisplay;
        font-size: $navCategoryFeaturedItemInlineRebateFontSize;
      }

      .mn_elevationOldValue {
        font-size: $navCategoryFeaturedItemRebateOldValueFontSize;
        color: $navCategoryFeaturedItemRebateOldValueColor;
        text-transform: $navCategoryFeaturedItemRebateOldTextTransform;
      }

      .mn_rebatePrefix {
        font-weight: $navCategoryFeaturedItemRebatePrefixFontWeight;
        color: $navCategoryFeaturedItemRebatePrefixColor;
        text-transform: $navCategoryFeaturedItemRebatePrefixTextTransform;
      }

      .mn_rebateValue,
      .mn_elevationNewValue {
        color: $navCategoryFeaturedItemRebateColor;
        text-transform: $navCategoryFeaturedItemRebateTextTransform;
      }

      .mn_tieredPrefix {
        &:first-letter {
          text-transform: $navCategoryFeaturedItemRebateTieredPrefixTextTransform;
        }
      }
    }

    &:hover {
      color: $navCategoryFeaturedItemHoverColor;
      text-decoration: $navCategoryFeaturedItemHoverTextDecoration;
      background: $navCategoryFeaturedItemHoverColorBackground;

      h3 {
        color: $navCategoryFeaturedItemHoverColor;
      }

      .mn_premierMerchantRebate {
        .mn_rebatePrefix,
        .mn_elevationOldValue,
        .mn_rebateValue,
        .mn_elevationNewValue {
          color: $navCategoryFeaturedItemHoverColor;
          text-decoration: $navCategoryFeaturedItemHoverTextDecoration;
        }
      }
    }
  }

  .mn_navCategoryFeaturedFooter {
    .mn_seeAllLink {
      font-size: $navCategoryFeaturedFooterLinkFontSize;
      margin: $navCategoryFeaturedFooterLinkMargin;
      color: $navCategoryFeaturedFooterLinkColor;
      padding: $navCategoryFeaturedFooterLinkPadding;
      display: inline-block;
      font-weight: $navCategoryFeaturedFooterLinkFontWeight;
      letter-spacing: $navCategoryFeaturedFooterLinkLetterSpacing;

      span {
        text-transform: $navCategoryFeaturedFooterLinkCategoryTextTransform;
      }

      &:hover {
        color: $navCategoryFeaturedFooterLinkHoverColor;
      }
    }
  }
}
