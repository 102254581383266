@import '~org/styles/variables';

$buttonRatingTextLineHeight: 2.2rem !default;
$buttonRatingTextPadding: 0 1rem !default;
$buttonRatingTextMargin: 0 1rem 0 0 !default;
$buttonRatingTextBorderRight: 1px solid #ccc !default;
$buttonRatingStarsWidth: 10.5rem;

.mn_buttonRating {
  display: flex;
  justify-content: center;
  align-items: center;

  .mn_ratingText {
    line-height: $buttonRatingTextLineHeight;
    padding: $buttonRatingTextPadding;
    margin: $buttonRatingTextMargin;
    border-right: $buttonRatingTextBorderRight;
  }

  .mn_stars {
    width: $buttonRatingStarsWidth;
  }
}
