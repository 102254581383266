/* stylelint-disable no-descending-specificity */
@import '~org/styles/variables.scss';

$emailSuccessMessagePadding: 3rem !default;
$emailSuccessMessageColor: () !default;
$emailSuccessMessageTextAlign: center !default;
$emailSuccessMessageBackground: () !default;
$emailSuccessMessageBorder: () !default;
$emailSuccessMessageBorderWidth: () !default;
$emailSuccessMessageBorderRadius: () !default;

$emailSuccessMessageAfterDisplay: () !default;
$emailSuccessMessageAfterContent: () !default;
$emailSuccessMessageAfterPosition: () !default;
$emailSuccessMessageAfterPositionLeft: () !default;
$emailSuccessMessageAfterPositionTop: () !default;
$emailSuccessMessageAfterHeight: () !default;
$emailSuccessMessageAfterWidth: () !default;
$emailSuccessMessageAfterBackground: () !default;

$emailSuccessMessageH2FontSize: () !default;
$emailSuccessMessageH2Margin: 0 0 1rem !default;
$emailSuccessMessageH2Color: () !default;
$emailSuccessMessageH2FontWeight: bold !default;
$emailSuccessMessageH2LineHeight: () !default;
$emailSuccessMessageOlayDescFontWeight: () !default;
$emailSuccessMessageOlayDescFontSize: () !default;
$emailSuccessMessageOlayDescLineHeight: () !default;

$emailSuccessMessageCloseButtonPosition: absolute !default;
$emailSuccessMessageCloseButtonPositionRight: 2rem !default;
$emailSuccessMessageCloseButtonPositionTop: 1.5rem !default;
$emailSuccessMessageCloseButtonWidth: () !default;
$emailSuccessMessageCloseButtonHeight: () !default;
$emailSuccessMessageCloseButtonBackground: none !default;
$emailSuccessMessageCloseButtonBorderRadius: () !default;
$emailSuccessModalCloseButtonWidth: () !default;
$emailSuccessModalCloseButtonHeight: () !default;
$emailSuccessModalCloseButtonBorderRadius: () !default;
$emailSuccessModalCloseButtonBackground: () !default;

$emailSuccessMessageCloseButtonBeforeColor: () !default;
$emailSuccessMessageCloseButtonBeforeFontFamily: () !default;
$emailSuccessMessageCloseButtonBeforeContent: () !default;
$emailSuccessMessageCloseButtonBeforeFontSize: () !default;
$emailSuccessMessageCloseButtonBeforeLineHeight: () !default;
$emailSuccessMessageCloseButtonBeforeFontWeight: () !default;
$emailSuccessMessageCloseButtonBeforeLetterSpacing: () !default;
$emailSuccessMessageCloseButtonBeforeOpacity: () !default;
$emailSuccessMessageCloseButtonHoverBeforeOpacity: () !default;

$emailSuccessModalBackground: () !default;
$emailSuccessModalTextAlign: () !default;
$emailSuccessModalCloseButtonColor: () !default;
$emailSuccessModalContentH2Color: () !default;
$emailSuccessModalContentH2FontSize: () !default;
$emailSuccessModalContentH2FontWeight: () !default;
$emailSuccessModalContentH2Margin: () !default;
$emailSuccessModalContentTextColor: () !default;
$emailSuccessModalContentTextFontSize: () !default;
$emailSuccessModalContentTextLineHeight: () !default;
$emailSuccessModalBorder: () !default;
$emailSuccessModalBorderWidth: () !default;
$emailSuccessModalBorderRadius: () !default;

.mn_emailOptInSuccessModal {
  .mn_modal {
    background: $emailSuccessModalBackground;
    border: $emailSuccessModalBorder;
    border-width: $emailSuccessModalBorderWidth;
    border-radius: $emailSuccessModalBorderRadius;
    text-align: $emailSuccessModalTextAlign;

    .mn_closeModal {
      color: $emailSuccessModalCloseButtonColor;
      width: $emailSuccessModalCloseButtonWidth;
      height: $emailSuccessModalCloseButtonHeight;
      border-radius: $emailSuccessModalCloseButtonBorderRadius;
      background: $emailSuccessModalCloseButtonBackground;
    }

    .mn_modalContent {
      h2 {
        color: $emailSuccessModalContentH2Color;
        font-size: $emailSuccessModalContentH2FontSize;
        font-weight: $emailSuccessModalContentH2FontWeight;
        margin: $emailSuccessModalContentH2Margin;
      }

      p {
        color: $emailSuccessModalContentTextColor;
        font-size: $emailSuccessModalContentTextFontSize;
        line-height: $emailSuccessModalContentTextLineHeight;
      }
    }
  }
}

@keyframes onboardingFadeDown {
  from {
    max-height: 28rem;
  }

  to {
    max-height: 0;
  }
}

.mn_emailOptInSuccessMessageWrapper {
  animation-delay: 999994s;
  animation-duration: 999991s;
  animation-name: onboardingFadeDown;
  animation-fill-mode: forwards;
}

.mn_emailOptInSuccessMessage {
  position: relative;
  color: $emailSuccessMessageColor;
  padding: $emailSuccessMessagePadding;
  text-align: $emailSuccessMessageTextAlign;
  background: $emailSuccessMessageBackground;
  border: $emailSuccessMessageBorder;
  border-width: $emailSuccessMessageBorderWidth;
  border-radius: $emailSuccessMessageBorderRadius;
  z-index: 102;

  &:after {
    display: $emailSuccessMessageAfterDisplay;
    content: $emailSuccessMessageAfterContent;
    position: $emailSuccessMessageAfterPosition;
    left: $emailSuccessMessageAfterPositionLeft;
    top: $emailSuccessMessageAfterPositionTop;
    height: $emailSuccessMessageAfterHeight;
    width: $emailSuccessMessageAfterWidth;
    background: $emailSuccessMessageAfterBackground;
  }

  h2 {
    font-size: $emailSuccessMessageH2FontSize;
    margin: $emailSuccessMessageH2Margin;
    color: $emailSuccessMessageH2Color;
    font-weight: $emailSuccessMessageH2FontWeight;
    line-height: $emailSuccessMessageH2LineHeight;
  }

  .mn_emailOlayDesc {
    font-weight: $emailSuccessMessageOlayDescFontWeight;
    font-size: $emailSuccessMessageOlayDescFontSize;
    line-height: $emailSuccessMessageOlayDescLineHeight;
  }

  .mn_closeModal {
    position: $emailSuccessMessageCloseButtonPosition;
    right: $emailSuccessMessageCloseButtonPositionRight;
    top: $emailSuccessMessageCloseButtonPositionTop;
    width: $emailSuccessMessageCloseButtonWidth;
    height: $emailSuccessMessageCloseButtonHeight;
    cursor: pointer;
    padding: 0;
    background: $emailSuccessMessageCloseButtonBackground;
    border-radius: $emailSuccessMessageCloseButtonBorderRadius;
    border: none;

    &:before {
      color: $emailSuccessMessageCloseButtonBeforeColor;
      font-family: $emailSuccessMessageCloseButtonBeforeFontFamily;
      content: $emailSuccessMessageCloseButtonBeforeContent;
      font-size: $emailSuccessMessageCloseButtonBeforeFontSize;
      line-height: $emailSuccessMessageCloseButtonBeforeLineHeight;
      font-weight: $emailSuccessMessageCloseButtonBeforeFontWeight;
      letter-spacing: $emailSuccessMessageCloseButtonBeforeLetterSpacing;
      opacity: $emailSuccessMessageCloseButtonBeforeOpacity;
      display: block;
    }

    &:hover {
      &:before {
        opacity: $emailSuccessMessageCloseButtonHoverBeforeOpacity;
      }
    }
  }
}
