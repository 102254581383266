/* stylelint-disable no-descending-specificity */
@import '~org/styles/variables';

$buttonOverlayFontFamily: () !default;
$buttonOverlayWidth: 90rem !default;
$buttonOverlayHeight: 65rem !default;
$buttonOverlayPadding: 0 !default;
$buttonOverlayBorderRadius: () !default;
$buttonOverlayBorderBottom: () !default;
$buttonOverlayPositionTop: calc(50% - #{$buttonOverlayHeight}/2) !default;

$buttonOverlayCloseButtonPostionRight: 1.5rem !default;

$buttonOverlayContentHeight: 246.8rem !default;
$buttonOverlayContentBackground: () !default;
$buttonOverlayBlockPadding: 0 14rem !default;
$buttonOverlayBlockOnePositionTop: 34.5rem !default;
$buttonOverlayBlockTwoPositionTop: 67rem !default;
$buttonOverlayBlockTwoPadding: () !default;
$buttonOverlayBlockThreePositionTop: 125rem !default;
$buttonOverlayBlockThreePadding: () !default;
$buttonOverlayBlockThreeHeaderColor: () !default;
$buttonOverlayHiwHintsColor: () !default;
$buttonOverlayHiwHintsSubheaderColor: $buttonOverlayHiwHintsColor !default;
$buttonOverlayHiwHintsSubheaderFontFamily: $buttonOverlayFontFamily !default;
$buttonOverlayHiwHintsTextColor: $buttonOverlayHiwHintsColor !default;
$buttonOverlayHiwHintsBlockOneTextMarginTop: () !default;
$buttonOverlayBlockFourPositionTop: 218rem !default;
$buttonOverlayBlockFourPadding: () !default;
$buttonOverlayBlockFourTextMargin: () !default;
$buttonOverlayButtonBackground: () !default;
$buttonOverlayButtonColor: () !default;

$buttonOverlayEarningNoteColor: () !default;
$buttonOverlayEarningNoteFontSize: () !default;
$buttonOverlayEarningNoteLetterSpacing: () !default;
$buttonOverlayEarningNoteLineHeight: () !default;
$buttonOverlayEarningNoteTextAlign: () !default;

$buttonOverlayMottoColor: () !default;
$buttonOverlayMottoFontSize: 1.4rem !default;
$buttonOverlayMottoFontWeight: bold !default;
$buttonOverlayMottoLetterSpacing: () !default;
$buttonOverlayMottoTextTransform: () !default;
$buttonOverlayHeaderMargin: .5rem 0 1rem 0 !default;
$buttonOverlayHeaderFontSize: 3.2rem !default;
$buttonOverlayHeaderFontWeight: () !default;
$buttonOverlayHeaderColor: () !default;
$buttonOverlayHeaderLetterSpacing: () !default;
$buttonOverlayHeaderFontFamily: $buttonOverlayFontFamily !default;
$buttonOverlayHeaderTextTransform: () !default;

$buttonOverlayTextFontSize: 1.6rem !default;
$buttonOverlayTextLineHeight: 2.4rem !default;
$buttonOverlayTextMarginBottom: 2rem !default;

$buttonOverlaySupFontSize: .8rem !default;

$buttonOverlayButtonMinWidth: 25.8rem !default;
$buttonOverlayButtonMinHeight: 4.8rem !default;
$buttonOverlayButtonFontSize: 1.6rem !default;
$buttonOverlayButtonFontWeight: () !default;
$buttonOverlayButtonTextTransform: () !default;
$buttonOverlayButtonBorder: () !default;
$buttonOverlayButtonBoxShadow: () !default;
$buttonOverlayButtonBorderRadius: () !default;
$buttonOverlayButtonSecondaryBackground: () !default;
$buttonOverlayButtonSecondaryColor: () !default;

$buttonOverlayRatingMargin: 3rem 0 0 0 !default;
$buttonOverlayRatingTextColor: () !default;
$buttonOverlayRatingTextFontSize: 1.4rem !default;
$buttonOverlayRatingTextLineHeight: 2.2rem !default;
$buttonOverlayRatingTextPadding: 0 1rem !default;
$buttonOverlayRatingTextMargin: 0 1rem 0 0 !default;
$buttonOverlayRatingTextBorderRight: 1px solid #ccc !default;
$buttonOverlayRatingTextTextTransform: () !default;
$buttonOverlayRatingTextLetterSpacing: () !default;
$buttonOverlayRatingImageWidth: 10.5rem !default;

$buttonOverlayHiwHintsMargin: 24rem 0 2rem 0 !default;
$buttonOverlayHiwHintsSubheaderFontWeight: () !default;
$buttonOverlayHiwHintsHeaderFontSize: 2.4rem !default;
$buttonOverlayHiwHintsBorder: 1px solid #fff !default;
$buttonOverlayHiwHintsSubheaderMargin: 0 0 1rem 0 !default;
$buttonOverlayHiwHintsTextMargin: () !default;

$buttonOverlayMerchantLogosMargin: 0 -3rem 2rem !default;
$buttonOverlayMerchantLogosHeight: 19.5rem !default;
$buttonOverlayMerchantLogoWidth: 14rem !default;
$buttonOverlayMerchantLogoHeight: 4.5rem !default;
$buttonOverlayMerchantLogoMargin: 1rem 0 !default;
$buttonOverlayMerchantLogoPadding: 0 1rem !default;

$buttonOverlayScrollbarTrackPieceBackground: rgba(212, 212, 208, .5) !default;
$buttonOverlayScrollbarThumBackground: #7c878e !default;

.mn_buttonScrollingModal {
  .mn_modal {
    font-family: $buttonOverlayFontFamily;
    width: $buttonOverlayWidth;
    height: $buttonOverlayHeight;
    padding: $buttonOverlayPadding;
    border-radius: $buttonOverlayBorderRadius;
    border-bottom: $buttonOverlayBorderBottom;
    top: $buttonOverlayPositionTop;
    overflow: hidden;

    .mn_closeModal {
      right: $buttonOverlayCloseButtonPostionRight;
    }

    .mn_modalContentBody {
      height: 100%;
    }

    .mn_lightBoxContent {
      max-height: 100%;
      overflow-y: auto;
      position: relative;
      z-index: 0;
    }

    .mn_buttonContent {
      height: $buttonOverlayContentHeight;
      position: relative;
      background: $buttonOverlayContentBackground;
      background-size: cover;
    }

    .mn_buttonBlock {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      padding: $buttonOverlayBlockPadding;

      &.mn_buttonBlockOne {
        top: $buttonOverlayBlockOnePositionTop;
      }

      &.mn_buttonBlockTwo {
        top: $buttonOverlayBlockTwoPositionTop;
        padding: $buttonOverlayBlockTwoPadding;
      }

      &.mn_buttonBlockThree {
        top: $buttonOverlayBlockThreePositionTop;
        padding: $buttonOverlayBlockThreePadding;

        h2 {
          color: $buttonOverlayBlockThreeHeaderColor;
        }

        .mn_hiwHints {
          display: flex;
          margin: $buttonOverlayHiwHintsMargin;

          div {
            flex-basis: 33.3%;
            padding: 0 10px;

            &:nth-child(1) {
              p {
                margin-top: $buttonOverlayHiwHintsBlockOneTextMarginTop;
              }
            }

            &:nth-child(2) {
              border-left: $buttonOverlayHiwHintsBorder;
              border-right: $buttonOverlayHiwHintsBorder;
            }

            h3 {
              font-size: $buttonOverlayHiwHintsHeaderFontSize;
              font-weight: $buttonOverlayHiwHintsSubheaderFontWeight;
              margin: $buttonOverlayHiwHintsSubheaderMargin;
              color: $buttonOverlayHiwHintsSubheaderColor;
              font-family: $buttonOverlayHiwHintsSubheaderFontFamily;
            }

            p {
              margin: $buttonOverlayHiwHintsTextMargin;
              color: $buttonOverlayHiwHintsTextColor;
            }
          }
        }

        .mn_button {
          background: $buttonOverlayButtonBackground;
          color: $buttonOverlayButtonColor;
        }
      }

      &.mn_buttonBlockFour {
        top: $buttonOverlayBlockFourPositionTop;
        padding: $buttonOverlayBlockFourPadding;

        p {
          margin: $buttonOverlayBlockFourTextMargin;
        }
      }

      .mn_buttonSecondary {
        background: $buttonOverlayButtonSecondaryBackground;
        color: $buttonOverlayButtonSecondaryColor;
      }

      .mn_earningNote {
        color: $buttonOverlayEarningNoteColor;
        font-size: $buttonOverlayEarningNoteFontSize;
        letter-spacing: $buttonOverlayEarningNoteLetterSpacing;
        line-height: $buttonOverlayEarningNoteLineHeight;
        text-align: $buttonOverlayEarningNoteTextAlign;
      }

      .mn_motto {
        color: $buttonOverlayMottoColor;
        font-size: $buttonOverlayMottoFontSize;
        font-weight: $buttonOverlayMottoFontWeight;
        letter-spacing: $buttonOverlayMottoLetterSpacing;
        text-transform: $buttonOverlayMottoTextTransform;
      }

      h2 {
        margin: $buttonOverlayHeaderMargin;
        font-size: $buttonOverlayHeaderFontSize;
        font-weight: $buttonOverlayHeaderFontWeight;
        color: $buttonOverlayHeaderColor;
        letter-spacing: $buttonOverlayHeaderLetterSpacing;
        font-family: $buttonOverlayHeaderFontFamily;
        text-transform: $buttonOverlayHeaderTextTransform;
      }

      p {
        font-size: $buttonOverlayTextFontSize;
        line-height: $buttonOverlayTextLineHeight;
        margin-bottom: $buttonOverlayTextMarginBottom;
      }

      sup {
        font-size: $buttonOverlaySupFontSize;
        vertical-align: super;
      }

      .mn_button {
        min-width: $buttonOverlayButtonMinWidth;
        min-height: $buttonOverlayButtonMinHeight;
        font-size: $buttonOverlayButtonFontSize;
        box-shadow: $buttonOverlayButtonBoxShadow;
        border-radius: $buttonOverlayButtonBorderRadius;
        font-weight: $buttonOverlayButtonFontWeight;
        text-transform: $buttonOverlayButtonTextTransform;
        border: $buttonOverlayButtonBorder;
      }

      .mn_buttonRating {
        margin: $buttonOverlayRatingMargin;

        .mn_ratingText {
          color: $buttonOverlayRatingTextColor;
          font-size: $buttonOverlayRatingTextFontSize;
          line-height: $buttonOverlayRatingTextLineHeight;
          padding: $buttonOverlayRatingTextPadding;
          margin: $buttonOverlayRatingTextMargin;
          border-right: $buttonOverlayRatingTextBorderRight;
          text-transform: $buttonOverlayRatingTextTextTransform;
          letter-spacing: $buttonOverlayRatingTextLetterSpacing;
        }

        .mn_stars {
          width: $buttonOverlayRatingImageWidth;
        }
      }
    }

    // Button Overlay Merchant Logos
    .mn_buttonOverlayMerchantLogos {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: $buttonOverlayMerchantLogosMargin;
      height: $buttonOverlayMerchantLogosHeight;

      .mn_merchantLogo {
        width: $buttonOverlayMerchantLogoWidth;
        height: $buttonOverlayMerchantLogoHeight;
        margin: $buttonOverlayMerchantLogoMargin;
        padding: $buttonOverlayMerchantLogoPadding;

        .mn_logoWrapper {
          display: flex;
          width: 100%;
          height: 100%;

          img {
            margin: auto;
          }
        }
      }
    }

    // Scrollbar
    .mn_lightBoxContent::-webkit-scrollbar {
      width: 50px;
    }

    .mn_lightBoxContent::-webkit-scrollbar-track-piece {
      background: $buttonOverlayScrollbarTrackPieceBackground;
      border-radius: 25px;
      border: 15px solid transparent;
      background-clip: content-box;
    }

    .mn_lightBoxContent::-webkit-scrollbar-thumb {
      background: $buttonOverlayScrollbarThumBackground;
      border-radius: 25px;
      border: 15px solid transparent;
      background-clip: content-box;
    }

    .mn_lightBoxContent::-webkit-scrollbar-button:start:decrement {
      height: 30%;
      display: block;
      background: transparent;
    }
  }
}
