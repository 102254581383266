@import '~org/styles/variables';

$categoryFlyoutMargin: 4rem 0 0 !default;
$categoryFlyoutInnerFlex: 0 0 30rem !default;
$categoryFlyoutInnerWidth: 30rem !default;
$categoryFlyoutTitleFontSize: () !default;
$categoryFlyoutTitleColor: () !default;
$categoryFlyoutTitleFontWeight: () !default;
$categoryFlyoutTitleMargin: () !default;

$categoryFlyoutMerchantTitleFontSize: () !default;
$categoryFlyoutMerchantTitleMargin: () !default;
$categoryFlyoutMerchantTitleColor: () !default;
$categoryFlyoutMerchantTitleFontWeight: () !default;
$categoryFlyoutMerchantTitleDisplay: () !default;
$categoryFlyoutInfoHoverColor: () !default;
$categoryFlyoutInfoHoverColorTextDecoration: () !default;

$categoryFlyoutTitleRebateDisplay: block !default;
$categoryFlyoutTitleRebateTextAlign: center !default;
$categoryFlyoutTitleRebateMargin: 0 !default;
$categoryFlyoutTitleRebatePadding: .8rem 0 !default;
$categoryFlyoutTitleRebateBorder: 2px solid #6244bb !default;
$categoryFlyoutTitleRebateBackground: #fff !default;
$categoryFlyoutTitleRebateFontSize: 1.6rem !default;
$categoryFlyoutTitleRebateTextTransform: none !default;

$categoryFlyoutTitleInlineRebateDisplay: () !default;

$categoryFlyoutTitleRebateOldValueDisplay: () !default;
$categoryFlyoutTitleRebateOldValueFontSize: () !default;
$categoryFlyoutTitleRebateOldValueColor: () !default;
$categoryFlyoutTitleRebateNewValueFontSize: () !default;
$categoryFlyoutTitleRebateNewValueColor: () !default;
$categoryFlyoutTitleRebateNewValueFontWeight: () !default;
$categoryFlyoutTitleRebateNewValueLineHeight: 2rem !default;

.mn_categoryFlyout {
  margin: $categoryFlyoutMargin;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  .mn_categoryFlyoutInner {
    flex: $categoryFlyoutInnerFlex;
    width: $categoryFlyoutInnerWidth;

    h2 {
      font-size: $categoryFlyoutTitleFontSize;
      color: $categoryFlyoutTitleColor;
      font-weight: $categoryFlyoutTitleFontWeight;
      margin: $categoryFlyoutTitleMargin;
    }

    a {
      display: block;
      text-decoration: none;
      color: inherit;

      img {
        display: block;
      }

      h3 {
        font-size: $categoryFlyoutMerchantTitleFontSize;
        margin: $categoryFlyoutMerchantTitleMargin;
        color: $categoryFlyoutMerchantTitleColor;
        font-weight: $categoryFlyoutMerchantTitleFontWeight;
        display: $categoryFlyoutMerchantTitleDisplay;
      }
    }

    .mn_merchantRebateV2 {
      display: $categoryFlyoutTitleRebateDisplay;
      text-align: $categoryFlyoutTitleRebateTextAlign;
      margin: $categoryFlyoutTitleRebateMargin;
      padding: $categoryFlyoutTitleRebatePadding;
      border: $categoryFlyoutTitleRebateBorder;
      background: $categoryFlyoutTitleRebateBackground;
      font-size: $categoryFlyoutTitleRebateFontSize;
      text-transform: $categoryFlyoutTitleRebateTextTransform;

      .mn_inlineRebate {
        display: $categoryFlyoutTitleInlineRebateDisplay;
      }

      .mn_rebateValue {
        .mn_elevationOldValue {
          display: $categoryFlyoutTitleRebateOldValueDisplay;
          font-size: $categoryFlyoutTitleRebateOldValueFontSize;
          color: $categoryFlyoutTitleRebateOldValueColor;
        }

        .mn_elevationNewValue {
          font-size: $categoryFlyoutTitleRebateNewValueFontSize;
          color: $categoryFlyoutTitleRebateNewValueColor;
          font-weight: $categoryFlyoutTitleRebateNewValueFontWeight;
          line-height: $categoryFlyoutTitleRebateNewValueLineHeight;
        }
      }
    }

    a .mn_categoryFlyoutInfo:hover {
      h3 {
        color: $categoryFlyoutInfoHoverColor;
        text-decoration: $categoryFlyoutInfoHoverColorTextDecoration;
      }

      .mn_merchantRebateV2 {
        .mn_rebatePrefix,
        .mn_elevationOldValue,
        .mn_rebateValue,
        .mn_elevationNewValue {
          color: $categoryFlyoutInfoHoverColor;
          text-decoration: $categoryFlyoutInfoHoverColorTextDecoration;
        }
      }
    }
  }
}
