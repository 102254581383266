@import '~org/styles/variables';

$navBannerButtonBannerBackground: () !default;
$navBannerButtonBannerBackgroundSize: 13rem 13rem !default;
$navBannerButtonBannerMargin:  4rem 2rem 0 0 !default;
$navBannerButtonBannerPadding: 0 0 0 6rem !default;
$navBannerFavoritesBannerBackground: () !default;
$navBannerFavoritesBannerBackgroundSize: 13rem 13rem !default;
$navBannerButtonBannerImagePositionTop: -1rem !default;
$navBannerButtonBannerImagePositionLeft: 0 !default;
$navBannerButtonBannerImageWidth: 12rem !default;
$navBannerButtonBannerImageHeight: 12rem !default;
$navBannerButtonBannerImageBorderRadius: 100% !default;
$navBannerButtonBannerImageBoxShadow: -1px 2px 3px 1px rgba(#000, .3) !default;
$navBannerButtonBannerImageBorder: #fff solid 1px !default;
$navBannerButtonBannerImageBoxSizing: () !default;
$navBannerButtonBannerImageTransform: () !default;
$navBannerContentBackground: () !default;
$navBannerContentPadding: 1rem 2rem 1rem 9.5rem !default;
$navBannerContentBorder: () !default;
$navBannerContentBorderRadius: () !default;
$navBannerContentBoxShadow: () !default;
$navBannerContentWidth: () !default;
$navBannerContentHeight: () !default;
$navBannerContentFontSize: 1.6rem !default;
$navBannerContentColor: () !default;
$navBannerContentLetterSpacing: () !default;
$navBannerTitleMargin: .5rem 0 1rem !default;
$navBannerTitleFontSize: 2.1rem !default;
$navBannerTitleLetterSpacing: () !default;
$navBannerTitleFontWeight: bold !default;
$navBannerTitleTextTransform: () !default;
$navBannerTitleColor: () !default;
$navBannerLinkWrapDisplay: () !default;
$navBannerLinkColor: () !default;
$navBannerLinkFontSize: () !default;
$navBannerLinkTextDecoration: () !default;
$navBannerHoverLinkTextDecoration: underline !default;
$navBannerLinkFontWeight: () !default;
$navBannerHoverLinkColor: () !default;
$navBannerTextLineHeight: () !default;
$navBannerTextMargin: () !default;

.mn_navBanner {
  position: relative;
  padding: $navBannerButtonBannerPadding;
  margin: $navBannerButtonBannerMargin;

  &:before {
    content: '';
    position: absolute;
    top: $navBannerButtonBannerImagePositionTop;
    left: $navBannerButtonBannerImagePositionLeft;
    width: $navBannerButtonBannerImageWidth;
    height: $navBannerButtonBannerImageHeight;
    border-radius: $navBannerButtonBannerImageBorderRadius;
    box-shadow: $navBannerButtonBannerImageBoxShadow;
    border: $navBannerButtonBannerImageBorder;
    box-sizing: $navBannerButtonBannerImageBoxSizing;
    transform: $navBannerButtonBannerImageTransform;
  }

  &.mn_navButtonBanner:before {
    background: $navBannerButtonBannerBackground;
    background-size: $navBannerButtonBannerBackgroundSize;
  }

  &.mn_navFavoritesBanner:before {
    background: $navBannerFavoritesBannerBackground;
    background-size: $navBannerFavoritesBannerBackgroundSize;
  }

  .mn_navBannerContent {
    background: $navBannerContentBackground;
    padding: $navBannerContentPadding;
    font-size: $navBannerContentFontSize;
    color: $navBannerContentColor;
    letter-spacing: $navBannerContentLetterSpacing;
    border: $navBannerContentBorder;
    border-radius: $navBannerContentBorderRadius;
    box-shadow: $navBannerContentBoxShadow;
    width: $navBannerContentWidth;
    height: $navBannerContentHeight;

    h3 {
      margin: $navBannerTitleMargin;
      font-size: $navBannerTitleFontSize;
      letter-spacing: $navBannerTitleLetterSpacing;
      font-weight: $navBannerTitleFontWeight;
      text-transform: $navBannerTitleTextTransform;
      color: $navBannerTitleColor;
    }

    p {
      line-height: $navBannerTextLineHeight;
      margin: $navBannerTextMargin;
    }

    .mn_navBannerLinkWrap {
      display: $navBannerLinkWrapDisplay;
    }

    a {
      color: $navBannerLinkColor;
      font-weight: $navBannerLinkFontWeight;
      font-size: $navBannerLinkFontSize;
      text-decoration: $navBannerLinkTextDecoration;

      &:hover {
        text-decoration: $navBannerHoverLinkTextDecoration;
        color: $navBannerHoverLinkColor;
      }
    }
  }
}
