/* stylelint-disable no-duplicate-selectors */
@import '~core/styles/mixins';
@import '~org/styles/variables';

$flipTileBorder: () !default;
$flipTileBorderRadius: 0 !default;
$flipTileBorderWidth: 1px 0 1px 0 !default;
$flipTileMargin: 0 0 -1px 0 !default;

$flipTileItemWidth: 100% !default;
$flipTileItemHeight: 6rem !default;
$flipTileItemBorderRadius: 0 !default;
$flipTileItemPadding: 0 2.4rem 0 1.4rem !default;
$flipTileItemBorderBottom: () !default;

$flipTileLogoWrapMargin: -.6rem auto -.6rem 1rem !default;
$flipTileLogoWrapFlex: 0 0 8.8rem !default;
$flipTileLogoWrapPadding: .6rem !default;
$flipTileLogoWrapBackground: #fff !default;
$flipTileLogoWrapBorderRadius: .4rem !default;

$flipTileFavoriteIconDisplay: () !default;
$flipTileFavoriteIconFlex: 0 0 2.1rem !default;
$flipTileFavoriteIconOrder: () !default;
$flipTileFavoriteIconHeight: (small: 1.8rem, medium: 1.8rem) !default;
$flipTileFavoriteIconWidth: $flipTileFavoriteIconHeight !default;
$flipTileFavoriteIconMargin: .7rem 0 0 !default;

$flipTileButtonWrapPadding: () !default;
$flipTileButtonPadding: 0 !default;
$flipTileButtonHeight: 3.5rem !default;
$flipTileButtonWidth: 15rem !default;
$flipTileButtonLineHeight: 3.1rem !default;
$flipTileButtonFontSize: 1.6rem !default;
$flipTileButtonFontWeight: () !default;
$flipTileButtonLetterSpacing: .04px !default;
$flipTileButtonTextTransform: () !default;
$flipTileButtonBackground: () !default;
$flipTileButtonMargin: () !default;
$flipTileButtonHoverBackground: () !default;

$flipTilePremierRebateFontWeight: 500 !default;
$flipTilePremierRebatePadding: 0 0 0 1rem !default;
$flipTilePremierRebateTextAlign: right !default;
$flipTilePremierRebateFontSize: 1.4rem !default;
$flipTilePremierRebateLineHeight: 1.8rem !default;
$flipTilePremierRebateMargin: () !default;
$flipTilePremierRebateColor: () !default;
$flipTilePremierRebateTextTransform: () !default;
$flipTilePremierRebateLetterSpacing: () !default;
$flipTilePremierRebatePrefixColor: () !default;
$flipTilePremierRebatePrefixFontWeight: () !default;
$flipTilePremierRebateValueWithCurrencyFontWeight: () !default;
$flipTilePremierRebateValueWithCurrencyTextTransform: () !default;
$flipTilePremierRebateElevationOldValueAfterContent: () !default;
$flipTilePremierRebateValueWithCurrencyColor: () !default;
$flipTilePremierRebateElevationOldValueFontWeight: $flipTilePremierRebateValueWithCurrencyFontWeight !default;
$flipTilePremierRebateInlineElevationNewValueWhiteSpace: () !default;
$flipTilePremierRebateInlineElevationNewValueFontWeight: () !default;
$flipTilePremierRebateInlineElevationNewValueWhiteSpace: () !default;
$flipTilePremierRebateValueColor: () !default;
$flipTilePremierRebateTieredPrefixFirstLetterTextTransform: () !default;
$flipTileBorder: () !default;
$flipTileBorderWidth: () !default;
$flipTileJustifyContent: space-between !default;

.mn_flipTile {
  border: $flipTileBorder;
  border-radius: $flipTileBorderRadius;
  border-width: $flipTileBorderWidth;
  margin: $flipTileMargin;

  .mn_itemContent {
    border-bottom: $flipTileItemBorderBottom;
  }

  .mn_itemContent,
  .mn_front,
  .mn_back {
    width: $flipTileItemWidth;
    height: $flipTileItemHeight;
    border-radius: $flipTileItemBorderRadius;
  }

  .mn_front,
  .mn_back {
    padding: $flipTileItemPadding;
  }

  .mn_logoWrap {
    margin: $flipTileLogoWrapMargin;
    flex: $flipTileLogoWrapFlex;
    padding: $flipTileLogoWrapPadding;
    background: $flipTileLogoWrapBackground;
    border-radius: $flipTileLogoWrapBorderRadius;
    box-sizing: content-box;

    img {
      width: 88px;
      height: 31px;
    }
  }

  .mn_favoritesWrap {
    display: $flipTileFavoriteIconDisplay;
    flex: $flipTileFavoriteIconFlex;
    order: $flipTileFavoriteIconOrder;
    margin: $flipTileFavoriteIconMargin;

    .mn_favoriteIcon {
      width: map-get($flipTileFavoriteIconWidth, small);
      height: map-get($flipTileFavoriteIconHeight, small);
    }
  }

  .mn_buttonWrap {
    margin: $flipTileButtonWrapPadding;

    .mn_button {
      padding: $flipTileButtonPadding;
      height: $flipTileButtonHeight;
      width: $flipTileButtonWidth;
      line-height: $flipTileButtonLineHeight;
      font-size: $flipTileButtonFontSize;
      font-weight: $flipTileButtonFontWeight;
      letter-spacing: $flipTileButtonLetterSpacing;
      background: $flipTileButtonBackground;
      margin: $flipTileButtonMargin;

      &:hover {
        background: $flipTileButtonHoverBackground;
      }
    }
  }

  .mn_premierMerchantRebate {
    font-weight: $flipTilePremierRebateFontWeight;
    padding: $flipTilePremierRebatePadding;
    text-align: $flipTilePremierRebateTextAlign;
    font-size: $flipTilePremierRebateFontSize;
    line-height: $flipTilePremierRebateLineHeight;
    margin: $flipTilePremierRebateMargin;
    color: $flipTilePremierRebateColor;
    text-transform: $flipTilePremierRebateTextTransform;
    letter-spacing: $flipTilePremierRebateLetterSpacing;

    .mn_rebatePrefix {
      color: $flipTilePremierRebatePrefixColor;
      font-weight: $flipTilePremierRebatePrefixFontWeight;
    }

    .mn_rebateValueWithCurrency {
      font-weight: $flipTilePremierRebateValueWithCurrencyFontWeight;

      .mn_elevationOldValue {
        color: $flipTilePremierRebateValueWithCurrencyColor;
        font-weight: $flipTilePremierRebateElevationOldValueFontWeight;
        text-transform: $flipTilePremierRebateValueWithCurrencyTextTransform;

        &:after {
          content: $flipTilePremierRebateElevationOldValueAfterContent;
        }
      }
    }

    .mn_inlineRebate .mn_elevationNewValue {
      white-space: $flipTilePremierRebateInlineElevationNewValueWhiteSpace;
      font-weight: $flipTilePremierRebateInlineElevationNewValueFontWeight;
    }

    .mn_rebateValue {
      color: $flipTilePremierRebateValueColor;

      .mn_tieredPrefix:first-letter {
        text-transform: $flipTilePremierRebateTieredPrefixFirstLetterTextTransform;
      }
    }
  }

  @media #{$medium-screen} {
    .mn_favoritesWrap {
      .mn_favoriteIcon {
        width: map-get($flipTileFavoriteIconWidth, medium);
        height: map-get($flipTileFavoriteIconHeight, medium);
      }
    }
  }
}

/* flip functionality */
.mn_flipTile {
  perspective: 1000px;
  -ms-transform: perspective(1000px) rotateY(0);
  overflow: hidden;
  border: $flipTileBorder;
  border-width: $flipTileBorderWidth;

  .mn_front,
  .mn_back {
    display: flex;
    justify-content: $flipTileJustifyContent;
    align-items: center;
    transition: all .6s;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  .mn_itemContent {
    transform-style: preserve-3d;
  }

  .mn_front {
    z-index: 2;
    transform: rotateX(0deg); // for firefox 31
  }

  .mn_back {
    transform: rotateX(-180deg);
  }

  .mn_flipper {
    position: relative;
    transition: .6s;
    transform-style: preserve-3d;
  }

  &.mn_focused .mn_front {
    transform: rotateX(180deg);
  }

  &.mn_focused .mn_back {
    transform: rotateX(0deg);
    z-index: 5;
  }
}
