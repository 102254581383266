@import '~org/styles/variables';
@import '~core/styles/balloon';

$favoriteIconWidth: 1.5rem !default;
$favoriteIconHeight: 1.5rem !default;

$favoriteIconFillColor: #ccc !default;
$favoriteIconStrokeColor: () !default;
$favoriteIconStrokeWidth: 0 !default;

$favoriteIconFavoritedFillColor: #69b3e7 !default;
$favoriteIconFavoritedStrokeColor: () !default;
$favoriteIconFavoritedStrokeWidth: 0 !default;

$favoriteIconDisabledFillColor: #ccc !default;
$favoriteIconDisabledStrokeColor: () !default;
$favoriteIconDisabledStrokeWidth: 0 !default;

$favoriteIconFontBeforeContent: () !default;
$favoriteIconFontBeforeDisplay: none !default;
$favoriteIconFontBeforeFontFamily: () !default;
$favoriteIconFontBeforeFontStyle: () !default;
$favoriteIconFontBeforeFontSize: () !default;
$favoriteIconFontBeforeColor: () !default;
$favoriteIconFontFavoritedBeforeContent: () !default;

.mn_favoriteIcon {
  border: none;
  cursor: pointer;
  display: inline-block;
  width: $favoriteIconWidth;
  height: $favoriteIconHeight;
  font-size: 0;
  padding: 0;
  background: none;
  position: relative;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    path {
      fill: $favoriteIconFillColor;
      stroke: $favoriteIconStrokeColor;
      stroke-width: $favoriteIconStrokeWidth;
    }
  }

  .mn_favoriteFontIcon {
    &:before {
      content: $favoriteIconFontBeforeContent;
      display: $favoriteIconFontBeforeDisplay;
      font-family: $favoriteIconFontBeforeFontFamily;
      font-style: $favoriteIconFontBeforeFontStyle;
      font-size: $favoriteIconFontBeforeFontSize;
      color: $favoriteIconFontBeforeColor;
    }
  }

  &.mn_favorited {
    svg path {
      fill: $favoriteIconFavoritedFillColor;
      stroke: $favoriteIconFavoritedStrokeColor;
      stroke-width: $favoriteIconFavoritedStrokeWidth;
    }

    .mn_favoriteFontIcon {
      &:before {
        content: $favoriteIconFontFavoritedBeforeContent;
      }
    }
  }

  &[disabled] svg path {
    fill: $favoriteIconDisabledFillColor;
    stroke: $favoriteIconDisabledStrokeColor;
    stroke-width: $favoriteIconDisabledStrokeWidth;
  }

  &.mn_spin {
    svg path {
      animation: spin 2s infinite linear;
      transform-origin: 500px 344px;
    }
  }
}

[data-balloon] {
  position: absolute;
  width: 2.2rem;
  z-index: 1001;

  &:before {
    z-index: 21;
  }

  &:after {
    z-index: 20;
  }

  &[data-balloon-pos='right'] {
    transform: translateY(.9rem);
  }

  &[data-balloon-pos='left'] {
    transform: translateY(.9rem);
  }

  &[data-balloon-pos='up'] {
    transform: translateY(.3rem);
  }

  &.mn_balloonPrimary {
    &-enter {
      transition: opacity 200ms ease-out;
      opacity: .01;

      &-active {
        opacity: 1;
      }
    }

    &-exit {
      transition: opacity 200ms ease-in;

      &-active {
        opacity: .01;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
