@import '~org/styles/variables';

$favoriteAlertModalMaxWidth: (small: (), medium: 56rem) !default;
$favoriteAlertModalInnerWrapTextAlign: (small: center, medium: left) !default;

$favoriteAlertModalH2FontSize: 3.2rem !default;
$favoriteAlertModalH2LineHeight: 3.6rem !default;
$favoriteAlertModalH2FontWeight: 500 !default;

$favoriteAlertModalTextFontSize: 1.6rem !default;
$favoriteAlertModalTextLineHeight: 2.4rem !default;
$favoriteAlertModalTextMargin: 3rem 0 !default;

$favoriteAlertModalFormJustifyContent: (small: center, medium: flex-start) !default;
$favoriteAlertModalFormGap: 1rem !default;

$favoriteAlertModalButtonWidth: 21rem !default;
$favoriteAlertModalButtonPadding: 1.4rem !default;

$favoriteAlertModalSecondaryButtonPadding: 1rem !default;
$favoriteAlertModalSecondaryButtonLineHeight: 1.5 !default;
$favoriteAlertModalSecondaryButtonWidth: 21rem !default;

.mn_favoriteAlertModal {
  .mn_modal {
    max-width: map-get($favoriteAlertModalMaxWidth, small);
  }

  .mn_modalContent {
    .mn_favoritesAlertOverlayInnerWrap {
      text-align: map-get($favoriteAlertModalInnerWrapTextAlign, small);

      h2 {
        font-size: $favoriteAlertModalH2FontSize;
        line-height: $favoriteAlertModalH2LineHeight;
        font-weight: $favoriteAlertModalH2FontWeight;
      }

      p {
        font-size: $favoriteAlertModalTextFontSize;
        line-height: $favoriteAlertModalTextLineHeight;
        margin: $favoriteAlertModalTextMargin;
      }

      form {
        display: flex;
        flex-wrap: wrap;
        justify-content: map-get($favoriteAlertModalFormJustifyContent, small);
        gap: $favoriteAlertModalFormGap;
      }

      .mn_button {
        width: $favoriteAlertModalButtonWidth;
        padding: $favoriteAlertModalButtonPadding;
        flex-shrink: 0;
      }

      .mn_secondaryButton {
        padding: $favoriteAlertModalSecondaryButtonPadding;
        line-height: $favoriteAlertModalSecondaryButtonLineHeight;
        width: $favoriteAlertModalSecondaryButtonWidth;
      }
    }
  }

  @media #{$medium-screen} {
    .mn_modal {
      max-width: map-get($favoriteAlertModalMaxWidth, medium);
    }

    .mn_modalContent {
      .mn_favoritesAlertOverlayInnerWrap {
        text-align: map-get($favoriteAlertModalInnerWrapTextAlign, medium);

        form {
          justify-content: map-get($favoriteAlertModalFormJustifyContent, medium);
        }
      }
    }
  }
}
