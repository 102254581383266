// After migrating all react clients to the new Hybrid Search, all $searchModal... related
// styles should be eliminated and only $hybrydSearchModal... are going to be used
@import '~org/styles/variables';

$searchModalBackground: #aeaeae !default;
$searchModalPositionTop: unset !default;
$searchModalPositionBottom: 0 !default;
$searchModalPositionPadding: 0 !default;

$searchModalCloseTop: -3rem !default;
$searchModalCloseFontSize: 2.3rem !default;
$searchModalCloseColor: #fff !default;

$searchModalSearchFormBackground: #333 !default;
$searchModalSearchFormPadding: 1rem 0 !default;
$searchModalSearchFormDropDownBoxShadow: () !default;
$searchModalSearchFormDropDowPadding: () !default;
$searchModalSearchFromLabelFontSize: unset !default;

$searchModalQuickSearchItemDisplay: () !default;

$searchModalSearchInputDisplay: block !default;
$searchModalSearchInputBackground: $brandBlack !default;
$searchModalSearchInputPadding: .4rem 3.5rem .4rem 1.5rem !default;
$searchModalSearchInputBorderRadius: 3rem !default;
$searchModalSearchInputBorder: none !default;
$searchModalSearchInputColor: $brandWhite !default;
$searchModalSearchInputWidth: calc(100% - 3rem) !default;
$searchModalSearchInputFontSize: () !default;
$searchModalSearchInputFontWeight: () !default;
$searchModalSearchInputLineHeight: 4.5rem !default;
$searchModalSearchInputHeight: 5.8rem !default;
$searchModalSearchInputOutline: 0 !default;
$searchModalSearchInputTextTransform: () !default;
$searchModalSearchInputMargin: 0 auto 0 auto !default;
$searchModalSearchInputBoxShadow: () !default;
$searchModalSearchInputFocusBackground: () !default;

$searchModalSearchSubmitPositionRight: 2.5rem !default;
$searchModalSearchSubmitPositionBottom: 1.5rem !default;
$searchModalSearchSubmitPositionLeft: () !default;
$searchModalSearchSubmitPositionTop: auto !default;
$searchModalSearchSubmitHeight: 4rem !default;
$searchModalSearchSubmitBackground: () !default;
$searchModalSearchSubmitIconDisplay: () !default;

$searchModalFlipTileBorder: () !default;

$searchModalRecentOrSimilarStoresBackground: none !default;
$searchModalRecentOrSimilarStoresPadding: 0 0 2rem 0 !default;
$searchModalRecentOrSimilarStoresSectHeadPadding: () !default;
$searchModalRecentOrSimilarStoresSectHeadBorderBottom: () !default;
$searchModalRecentOrSimilarStoresSectHeadJustifyContent: () !default;
$searchModalRecentOrSimilarStoresHeaderFontSize: () !default;
$searchModalRecentOrSimilarStoresHeaderFontWeight: () !default;
$searchModalRecentOrSimilarStoresHeaderColor: () !default;
$searchModalRecentOrSimilarStoresHeaderTextTransform: () !default;
$searchModalRecentOrSimilarStoresHeaderMargin: () !default;
$searchModalRecentOrSimilarStoresHeaderLetterSpacing: () !default;
$searchModalRecentOrSimilarStoresTileBorderBottom: () !default;
$searchModalRecentOrSimilarStoresTilePadding: () !default;
$searchModalRecentOrSimilarStoresTileDisplay: () !default;
$searchModalRecentOrSimilarStoresTileJustifyContent: () !default;
$searchModalRecentOrSimilarStoresTileLogoWrapMargin: () !default;
$searchModalRecentOrSimilarStoresTileLogoWrapBorder: () !default;
$searchModalRecentOrSimilarStoresTileLogoWrapBoxShadow: () !default;
$searchModalRecentOrSimilarStoresTileLogoWrapPadding: () !default;
$searchModalRecentOrSimilarStoresTileLogoWrapBorderRadius: () !default;
$searchModalRecentOrSimilarStoresTileRebateMargin: () !default;
$searchModalRecentOrSimilarStoresTileRebateColor: () !default;
$searchModalRecentOrSimilarStoresTileRebatePadding: () !default;
$searchModalRecentOrSimilarStoresTileRebateTextAlign: () !default;
$searchModalRecentOrSimilarStoresTileRebateFontSize: () !default;
$searchModalRecentOrSimilarStoresTileRebateWidth: () !default;
$searchModalRecentOrSimilarStoresTileRebateElevationOldValueColor: () !default;
$searchModalRecentOrSimilarStoresTileRebateElevationOldFontSize: () !default;
$searchModalRecentOrSimilarStoresTileRebateValueColor: () !default;
$searchModalRecentOrSimilarStoresTileRebateFontWeight: () !default;
$searchModalRecentOrSimilarStoresTileRebateFontSize: () !default;
$searchModalRecentOrSimilarStoresTileFavoriteIconWrapOrder: () !default;
$searchModalRecentOrSimilarStoresTileFavoriteIconWidth: () !default;
$searchModalRecentOrSimilarStoresTileFavoriteIconHeight: $searchModalRecentOrSimilarStoresTileFavoriteIconWidth !default;
$searchModalRecentOrSimilarStoresTileFavoriteIconWrapMargin: () !default;
$searchModalRecentOrSimilarStoresTileFavoriteIconWrapPosition: () !default;

$searchModalSimilarStoresTileBorder: () !default;
$searchModalSimilarStoresTileItemBorder: () !default;

$searchModalRecentStoresLinkDisplay: none !default;

$searchModalQuickSearchMargin: () !default;
$searchModalQuickSearchResultsDisplay: flex !default;
$searchModalQuickSearchResultsFlexDirection: column !default;
$searchModalQuickSearchResultsBackground: none !default;
$searchModalQuickSearchResultsMaxHeight: none !default;
$searchModalQuickSearchResultsBorder: () !default;
$searchModalQuickSearchResultsPadding: () !default;

$searchModalProductLinkWrapFontSize: 1.4rem !default;
$searchModalProductLinkWrapMargin: .5rem 0 !default;
$searchModalProductLinkWrapPadding: 1rem 5% !default;
$searchModalProductLinkWrapBackground: none !default;
$searchModalProductLinkWrapBorderTop: 1px solid rgba(186, 197, 210, .25) !default;
$searchModalProductLinkWrapBorderBottom: $searchModalProductLinkWrapBorderTop !default;
$searchModalProductLinkWrapTextAlign: left !default;
$searchModalProductLinkColor: () !default;
$searchModalProductLinkFontWeight: () !default;
$searchModalProductLinkFontSize: () !default;
$searchModalProductLinkKeywordsFontWeight: () !default;

$searchModalProductLinkAfterContent: () !default;
$searchModalProductLinkAfterFontFamily: () !default;
$searchModalProductLinkAfterFontSize: () !default;
$searchModalProductLinkAfterVericalAlign: middle !default;
$searchModalProductLinkAfterDisplay: inline-block !default;
$searchModalProductLinkAfterMargin: () !default;
$searchModalProductLinkAfterFontWeight: () !default;

$searchModalQuickSearchMatchDisplay: inline-block !default;
$searchModalQuickSearchMatchTextAlign: left !default;
$searchModalQuickSearchMatchPadding: () !default;
$searchModalQuickSearchMatchBorderBottom: none !default;
$searchModalQuickSearchMatchColor: () !default;
$searchModalQuickSearchMatchBackground: () !default;
$searchModalQuickSearchMatchFontSize: () !default;
$searchModalQuickSearchMatchWidth: () !default;
$searchModalQuickSearchMatchHoverBackground: () !default;
$searchModalQuickSearchContentJustifyContent: () !default;
$searchModalQuickSearchContentDashDisplay: () !default;
$searchModalQuickSearchContentDisplay: () !default;
$searchModalQuickSearchContentFlexDirection: () !default;

$searchModalQuickSearchMatchRebateFontSize: () !default;
$searchModalQuickSearchMatchRebateFontWeight: () !default;
$searchModalQuickSearchMatchRebateColor: () !default;
$searchModalQuickSearchMatchRebateTextAlign: () !default;
$searchModalQuickSearchMatchRebateWhiteSpace: () !default;
$searchModalQuickSearchMatchMatchTextLetterSpacing: normal !default;
$searchModalQuickSearchMatchMatchTextTextTransform: none !default;
$searchModalQuickSearchMatchMatchTextColor: () !default;
$searchModalQuickSearchMatchMatchTextMargin: () !default;
$searchModalQuickSearchMatchMatchTextFontSize: () !default;
$searchModalQuickSearchMatchHighlightColor: () !default;
$searchModalQuickSearchDashDisplay: () !default;
$searchModalQuickSearchDashColor: () !default;

$searchModalQuickSearchFavoriteIconPosition: () !default;
$searchModalQuickSearchFavoriteIconWidth: () !default;
$searchModalQuickSearchFavoriteIconHeight: () !default;
$searchModalQuickSearchFavoriteIconPositionLeft: () !default;
$searchModalQuickSearchFavoriteIconPositionTop: () !default;
$searchModalQuickSearchFavoriteIconMargin: () !default;

$searchModalQuickSearchMatchRebateValueColor: () !default;
$searchModalQuickSearchMatchRebateValueFontSize: () !default;

$searchModalQuickSearchMatchingTitleFontSize: 1.4rem !default;
$searchModalQuickSearchMatchingTitleTextAlign: left !default;
$searchModalQuickSearchMatchingTitlePadding: 1.5rem 5% !default;
$searchModalQuickSearchMatchingTitleMargin: 0 !default;
$searchModalQuickSearchMatchingTitleColor: () !default;
$searchModalQuickSearchMatchingTitleTextTransform: () !default;
$searchModalQuickSearchMatchingTitleTextLetterSpacing: () !default;
$searchModalQuickSearchMatchingTitleTextOrder: () !default;
$searchModalQuickSearchMatchingTitleFontWeight: () !default;

$searchModalQuickSearchFooterPadding: 1rem !default;
$searchModalQuickSearchFooterBackground: none !default;
$searchModalQuickSearchFooterColor: #fff !default;
$searchModalQuickSearchFooterOrder: () !default;
$searchModalQuickSearchFooterTextAlign: () !default;
$searchModalQuickSearchFooterBorderTop: () !default;
$searchModalQuickSearchFooterBorderBottom: () !default;
$searchModalQuickSearchFooterFontSize: () !default;
$searchModalQuickSearchFooterLineHeight: () !default;
$searchModalQuickSearchFooterNoResultsBorderBottom: () !default;
$searchModalQuickSearchFooterNoResultsBorderTop: () !default;
$searchModalQuickSearchFooterSpanFontWeight: () !default;

$searchModalQuickSearchItemsWrapperOrder: () !default;
$searchModalQuickSearchItemsWrapperPadding: () !default;
$searchModalQuickSearchInstoreMatchingTitleTextOrder: () !default;
$searchModalQuickInstoreMatchesWrapperOrder: () !default;
$searchModalQuickInstoreMatchesWrapperPadding: () !default;
$searchModalQuickInstoreMatchesWrapperBorderBottom: () !default;
$searchModalQuickInstoreMatchBeforeBackgroundImage: () !default;
$searchModalQuickInstoreMatchBeforeWidth: () !default;
$searchModalQuickInstoreMatchBeforeHeight: () !default;
$searchModalQuickInstoreMatchRebateFontSize: () !default;
$searchModalQuickInstoreMatchRebateFontWeight: () !default;

$searchModalQuickSearchItemFavoriteIconWidth: () !default;
$searchModalQuickSearchItemFavoriteIconHeight: () !default;
$searchModalQuickSearchItemFavoriteIconPositionTop: () !default;
$searchModalQuickSearchItemFavoriteIconFontBeforeFontSize: () !default;
$searchModalRecentOrSimilarStoresTileFavoriteIconBeforeFontSize: () !default;

.mn_searchModal {
  background: $searchModalBackground;

  .mn_modal {
    top: $searchModalPositionTop;
    bottom: $searchModalPositionBottom;
    padding: $searchModalPositionPadding;
  }

  .mn_closeModal {
    top: $searchModalCloseTop;
    font-size: $searchModalCloseFontSize;
    color: $searchModalCloseColor;
  }

  .mn_searchForm {
    background: $searchModalSearchFormBackground;
    padding: $searchModalSearchFormPadding;

    .mn_dropDownWrap {
      box-shadow: $searchModalSearchFormDropDownBoxShadow;
      padding: $searchModalSearchFormDropDowPadding;
      position: relative;
      left: 0;
      right: 0;
    }

    label {
      font-size: $searchModalSearchFromLabelFontSize;
    }

    .mn_searchInput {
      display: $searchModalSearchInputDisplay;
      background: $searchModalSearchInputBackground;
      padding: $searchModalSearchInputPadding;
      border-radius: $searchModalSearchInputBorderRadius;
      border: $searchModalSearchInputBorder;
      color: $searchModalSearchInputColor;
      width: $searchModalSearchInputWidth;
      font-size: $searchModalSearchInputFontSize;
      font-weight: $searchModalSearchInputFontWeight;
      line-height: $searchModalSearchInputLineHeight;
      height: $searchModalSearchInputHeight;
      outline: $searchModalSearchInputOutline;
      text-transform: $searchModalSearchInputTextTransform;
      margin: $searchModalSearchInputMargin;
      box-shadow: $searchModalSearchInputBoxShadow;

      &:focus {
        background: $searchModalSearchInputFocusBackground;
      }
    }

    .mn_searchSubmit {
      right: $searchModalSearchSubmitPositionRight;
      bottom: $searchModalSearchSubmitPositionBottom;
      left: $searchModalSearchSubmitPositionLeft;
      top: $searchModalSearchSubmitPositionTop;
      height: $searchModalSearchSubmitHeight;
      background: $searchModalSearchSubmitBackground;

      i {
        &:before {
          display: $searchModalSearchSubmitIconDisplay;
        }
      }
    }

    .mn_recentStores,
    .mn_similarStores {
      background: $searchModalRecentOrSimilarStoresBackground;
      padding: $searchModalRecentOrSimilarStoresPadding;

      .mn_sectHead {
        padding: $searchModalRecentOrSimilarStoresSectHeadPadding;
        border-bottom: $searchModalRecentOrSimilarStoresSectHeadBorderBottom;
        justify-content: $searchModalRecentOrSimilarStoresSectHeadJustifyContent;

        h3 {
          font-size: $searchModalRecentOrSimilarStoresHeaderFontSize;
          font-weight: $searchModalRecentOrSimilarStoresHeaderFontWeight;
          color: $searchModalRecentOrSimilarStoresHeaderColor;
          text-transform: $searchModalRecentOrSimilarStoresHeaderTextTransform;
          margin: $searchModalRecentOrSimilarStoresHeaderMargin;
          letter-spacing: $searchModalRecentOrSimilarStoresHeaderLetterSpacing;
        }
      }

      .mn_flipTile {
        border: $searchModalFlipTileBorder;
      }

      .mn_itemContent {
        padding: $searchModalRecentOrSimilarStoresTilePadding;
        border-bottom: $searchModalRecentOrSimilarStoresTileBorderBottom;

        .mn_front,
        .mn_back {
          border-bottom: $searchModalRecentOrSimilarStoresTileBorderBottom;
          padding: $searchModalRecentOrSimilarStoresTilePadding;
          display: $searchModalRecentOrSimilarStoresTileDisplay;
          justify-content: $searchModalRecentOrSimilarStoresTileJustifyContent;
        }

        .mn_favoritesWrap {
          order: $searchModalRecentOrSimilarStoresTileFavoriteIconWrapOrder;
          margin: $searchModalRecentOrSimilarStoresTileFavoriteIconWrapMargin;
          position: $searchModalRecentOrSimilarStoresTileFavoriteIconWrapPosition;

          .mn_favoriteIcon {
            width: $searchModalRecentOrSimilarStoresTileFavoriteIconWidth;
            height: $searchModalRecentOrSimilarStoresTileFavoriteIconHeight;

            .mn_favoriteFontIcon:before {
              font-size: $searchModalRecentOrSimilarStoresTileFavoriteIconBeforeFontSize;
            }
          }
        }

        .mn_logoWrap {
          margin: $searchModalRecentOrSimilarStoresTileLogoWrapMargin;
          border: $searchModalRecentOrSimilarStoresTileLogoWrapBorder;
          box-shadow: $searchModalRecentOrSimilarStoresTileLogoWrapBoxShadow;
          padding: $searchModalRecentOrSimilarStoresTileLogoWrapPadding;
          border-radius: $searchModalRecentOrSimilarStoresTileLogoWrapBorderRadius;
        }

        .mn_rebateWrap,
        .mn_premierMerchantRebate {
          margin: $searchModalRecentOrSimilarStoresTileRebateMargin;
          color: $searchModalRecentOrSimilarStoresTileRebateColor;
          padding: $searchModalRecentOrSimilarStoresTileRebatePadding;
          text-align: $searchModalRecentOrSimilarStoresTileRebateTextAlign;
          font-size: $searchModalRecentOrSimilarStoresTileRebateFontSize;
          width: $searchModalRecentOrSimilarStoresTileRebateWidth;

          .mn_elevationOldValue {
            color: $searchModalRecentOrSimilarStoresTileRebateElevationOldValueColor;
            font-size: $searchModalRecentOrSimilarStoresTileRebateElevationOldFontSize;
          }

          .mn_rebatePrefix,
          .mn_rebateValue {
            color: $searchModalRecentOrSimilarStoresTileRebateValueColor;
            font-weight: $searchModalRecentOrSimilarStoresTileRebateFontWeight;
            font-size: $searchModalRecentOrSimilarStoresTileRebateFontSize;
          }
        }
      }
    }

    .mn_similarStoreTile {
      border: $searchModalSimilarStoresTileBorder;

      .mn_itemContent {
        border: $searchModalSimilarStoresTileItemBorder;
      }
    }

    .mn_viewAllRecentStoresLinkBottom {
      display: $searchModalRecentStoresLinkDisplay;
    }

    .mn_quickSearch {
      margin: $searchModalQuickSearchMargin;
      display: flex;
      flex-direction: column;

      .mn_productLinkWrap {
        order: 1;
        font-size: $searchModalProductLinkWrapFontSize;
        margin: $searchModalProductLinkWrapMargin;
        padding: $searchModalProductLinkWrapPadding;
        background: $searchModalProductLinkWrapBackground;
        border-top: $searchModalProductLinkWrapBorderTop;
        border-bottom: $searchModalProductLinkWrapBorderBottom;
        text-align: $searchModalProductLinkWrapTextAlign;

        .mn_productLink {
          color: $searchModalProductLinkColor;
          font-weight: $searchModalProductLinkFontWeight;
          font-size: $searchModalProductLinkFontSize;

          &:after {
            content: $searchModalProductLinkAfterContent;
            font-family: $searchModalProductLinkAfterFontFamily;
            font-size: $searchModalProductLinkAfterFontSize;
            vertical-align: $searchModalProductLinkAfterVericalAlign;
            display: $searchModalProductLinkAfterDisplay;
            margin: $searchModalProductLinkAfterMargin;
            font-weight: $searchModalProductLinkAfterFontWeight;
          }

          .mn_keywords {
            font-weight: $searchModalProductLinkKeywordsFontWeight;
          }
        }
      }

      .mn_quickSearchResults {
        order: 2;
        display: $searchModalQuickSearchResultsDisplay;
        flex-direction: $searchModalQuickSearchResultsFlexDirection;
        background: $searchModalQuickSearchResultsBackground;
        max-height: $searchModalQuickSearchResultsMaxHeight;
        border: $searchModalQuickSearchResultsBorder;
        padding: $searchModalQuickSearchResultsPadding;

        .mn_quickSearchMatch {
          display: $searchModalQuickSearchMatchDisplay;
          text-align: $searchModalQuickSearchMatchTextAlign;
          padding: $searchModalQuickSearchMatchPadding;
          border-bottom: $searchModalQuickSearchMatchBorderBottom;
          color: $searchModalQuickSearchMatchColor;
          font-size: $searchModalQuickSearchMatchFontSize;
          background: $searchModalQuickSearchMatchBackground;
          width: $searchModalQuickSearchMatchWidth;

          &:hover,
          &:focus {
            background: $searchModalQuickSearchMatchHoverBackground;
          }

          .mn_quickSearchContent {
            display: $searchModalQuickSearchContentDisplay;
            justify-content: $searchModalQuickSearchContentJustifyContent;
            flex-direction: $searchModalQuickSearchContentFlexDirection;

            .mn_dash {
              display: $searchModalQuickSearchContentDashDisplay;
            }
          }

          .mn_merchantRebate,
          .mn_premierMerchantRebate {
            font-size: $searchModalQuickSearchMatchRebateFontSize;
            font-weight: $searchModalQuickSearchMatchRebateFontWeight;
            color: $searchModalQuickSearchMatchRebateColor;
            text-align: $searchModalQuickSearchMatchRebateTextAlign;
            white-space: $searchModalQuickSearchMatchRebateWhiteSpace;

            .mn_rebateValue {
              color: $searchModalQuickSearchMatchRebateValueColor;
              font-size: $searchModalQuickSearchMatchRebateValueFontSize;
            }
          }
        }

        .mn_matchText {
          letter-spacing: $searchModalQuickSearchMatchMatchTextLetterSpacing;
          text-transform: $searchModalQuickSearchMatchMatchTextTextTransform;
          color: $searchModalQuickSearchMatchMatchTextColor;
          margin: $searchModalQuickSearchMatchMatchTextMargin;
          font-size: $searchModalQuickSearchMatchMatchTextFontSize;

          .mn_matchTextHighlight {
            color: $searchModalQuickSearchMatchHighlightColor;
          }
        }

        .mn_dash {
          display: $searchModalQuickSearchDashDisplay;
          color: $searchModalQuickSearchDashColor;
        }

        .mn_favoriteIcon {
          position: $searchModalQuickSearchFavoriteIconPosition;
          width: $searchModalQuickSearchFavoriteIconWidth;
          height: $searchModalQuickSearchFavoriteIconHeight;
          left: $searchModalQuickSearchFavoriteIconPositionLeft;
          top: $searchModalQuickSearchFavoriteIconPositionTop;
          margin: $searchModalQuickSearchFavoriteIconMargin;
        }

        .mn_quickSearchMatchingTitle,
        .mn_quickSearchInstoreMatchingTitle {
          font-size: $searchModalQuickSearchMatchingTitleFontSize;
          text-align: $searchModalQuickSearchMatchingTitleTextAlign;
          padding: $searchModalQuickSearchMatchingTitlePadding;
          margin: $searchModalQuickSearchMatchingTitleMargin;
          color: $searchModalQuickSearchMatchingTitleColor;
          text-transform: $searchModalQuickSearchMatchingTitleTextTransform;
          letter-spacing: $searchModalQuickSearchMatchingTitleTextLetterSpacing;
          font-weight: $searchModalQuickSearchMatchingTitleFontWeight;
        }

        .mn_quickSearchMatchingTitle {
          order: $searchModalQuickSearchMatchingTitleTextOrder;
        }

        .mn_quickSearchInstoreMatchingTitle {
          order: $searchModalQuickSearchInstoreMatchingTitleTextOrder;
        }
      }

      .mn_quickSearchItemsWrapper {
        order: $searchModalQuickSearchItemsWrapperOrder;
        padding: $searchModalQuickSearchItemsWrapperPadding;

        .mn_quickSearchItem {
          display: $searchModalQuickSearchItemDisplay;

          .mn_favoriteIcon {
            width: $searchModalQuickSearchItemFavoriteIconWidth;
            height: $searchModalQuickSearchItemFavoriteIconHeight;
            top: $searchModalQuickSearchItemFavoriteIconPositionTop;

            .mn_favoriteFontIcon {
              &:before {
                font-size: $searchModalQuickSearchItemFavoriteIconFontBeforeFontSize;
              }
            }
          }
        }
      }

      .mn_instoreMatchesWrapper {
        order: $searchModalQuickInstoreMatchesWrapperOrder;
        padding: $searchModalQuickInstoreMatchesWrapperPadding;
        border-bottom: $searchModalQuickInstoreMatchesWrapperBorderBottom;

        .mn_instoreMatch {
          &:before {
            background-image: $searchModalQuickInstoreMatchBeforeBackgroundImage;
            width: $searchModalQuickInstoreMatchBeforeWidth;
            height: $searchModalQuickInstoreMatchBeforeHeight;
          }

          .mn_instoreMerchantRebate {
            font-size: $searchModalQuickInstoreMatchRebateFontSize;
            font-weight: $searchModalQuickInstoreMatchRebateFontWeight;
          }
        }
      }

      .mn_quickSearchFooter {
        padding: $searchModalQuickSearchFooterPadding;
        background: $searchModalQuickSearchFooterBackground;
        color: $searchModalQuickSearchFooterColor;
        order: $searchModalQuickSearchFooterOrder;
        text-align: $searchModalQuickSearchFooterTextAlign;
        border-top: $searchModalQuickSearchFooterBorderTop;
        border-bottom: $searchModalQuickSearchFooterBorderBottom;
        font-size: $searchModalQuickSearchFooterFontSize;
        line-height: $searchModalQuickSearchFooterLineHeight;

        span {
          font-weight: $searchModalQuickSearchFooterSpanFontWeight;
        }

        &.mn_noResults {
          border-bottom: $searchModalQuickSearchFooterNoResultsBorderBottom;
          border-top: $searchModalQuickSearchFooterNoResultsBorderTop;
        }
      }
    }
  }
}
