@import 'variables';
@import 'interstitial';
@import 'icons';
@import 'mixins';
@import '~core/styles/responsive-utilities';
@import '~core/styles/grid';
@import '~core/styles/mixins';
@import '~core/styles/defaults';

/* Fonts */
@import 'fonts';
@import '~core/fonts/fontello/css/fontello';
@import '~org/fonts/usaa-categories/css/usaa_categories';

/* Defaults */

html {
  width: 100%;

  &.mn_isIphoneSafari {
    overflow-y: initial;
  }

  &.mn_open {
    @media #{$less-than-medium-screen} {
      overflow-y: hidden;
    }
  }
}

.mn_noScroll {
  position: absolute;
  overflow: hidden;
  width: 100%;
}

.mn_pageWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
}

ul {
  ol {
    list-style-type: none;
  }
}

sup { line-height: 1; }

select {
  -webkit-appearance: none;
  border-radius: 0;
}

.mn_elevationNewValue .mn_rebateValue {
  white-space: nowrap;
}

/** Page wrappers **/
.mn_wrapAll {
  height: 100%;
  width: 100%;
  position: relative;
}

.mn_featuredDeals {
  @media #{$medium-screen} {
    .mn_sectionTitle:after {
      content: none;
    }
  }
}

.mn_onboarding,
.mn_onboardingCongrats {
  sup {
    line-height: 0;
  }
}

.mn_VHP {
  .mn_sectionTitle {
    align-items: center;

    a.mn_viewAllLink {
      &:after {
        display: none;
      }
    }
  }

  .mn_pageHeader {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .25);
  }

  .mn_howItWorks {
    .fa-play-circled:before {
      color: $brandBlack;
      font-size: 1.8rem;
      margin-right: 1rem;
    }
  }

  @media #{$medium-screen} {
    .mn_featuredOffersLogo .mn_sectionTitle {
      flex-direction: row;
      justify-content: space-between;
    }

    .mn_brandFooter {
      border-top: 1px solid $brandWhite;
    }

    .mn_headerMainBar {
      padding: 0 0 4rem;
    }

    .mn_holidayImage {
      margin: 0 0 -.1rem;
    }
  }

  @media #{$xlarge-screen} {
    .mn_headerMainBar {
      padding: 0 0 4.5rem;
    }
  }
}

.mn_MHP {
  .mn_letterboxTOFCarousel {
    .mn_pillLabel {
      &:after {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }

  .mn_newMembers {
    .mn_emailOptInWrapper {
      .mn_decorationLine {
        border-bottom: 1px solid rgba(186, 197, 210, .75);
        position: absolute;
        bottom: 0;
        width: 81%;
        left: calc(50% - 80% / 2);
      }
    }
  }

  .mn_similarStoreTile .mn_favoritesWrap {
    display: none;
  }

  .mn_onboarding .mn_stepLaterLink:after {
    display: none;
  }

  .mn_earningsSnapshot {
    position: relative;

    &:before {
      content: '';
      display: block;
      width: calc(100% - 3rem);
      height: 1px;
      background: rgba(186, 197, 210, .25);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

html.mn_MHP {
  @media #{$less-than-medium-screen} {
    .mn_dropDownWrap .mn_recentStores .mn_flipTile {
      .mn_itemContent {
        border-bottom: none;
      }
    }
  }
}

.mn_searchBar {
  .mn_quickSearch {
    .mn_quickSearchResults {
      border-width: 0;

      &.mn_noResult {
        display: flex;
        flex-direction: column;

        .mn_productLinkWrap {
          order: 2;
        }

        .mn_quickSearchResults {
          order: 1;
          border-width: 0;
        }
      }
    }
  }
}

html.mn_VHP {
  .mn_quickSearch {
    .mn_quickSearchContent {
      justify-content: flex-start;
    }

    .mn_merchantRebate {
      color: $brandGray6;

      .mn_rebateValueWithCurrency {
        .mn_rebateValue,
        .mn_rebateCurrency {
          color: $brandGray6;

          .mn_elevationOldValue {
            color: $brandGray6;
          }
        }

        .mn_rebateValue {
          @include rebate-elevation($brandTextColor, 1px solid $brandGray6);
        }
      }
    }
  }

  @media #{$less-than-medium-screen} {
    .mn_searchBar {
      background: $brandHeaderColor;

      .mn_searchForm {
        margin: 4rem 1rem 0;

        .mn_searchInput {
          background: $brandLightColor;
          font-weight: bold;
          padding: .4rem 3.5rem .4rem 1.5rem;
        }

        .mn_searchSubmit {
          width: 2.7rem;

          .mn_usaaSVGIconSearch:before {
            width: 2.3rem;
            height: 2.3rem;
          }
        }

        .mn_dropDownWrap {
          margin: 4rem -3.5rem -1rem;

          .mn_quickSearchMatch {
            padding: 1.35rem 5rem 1.35rem 0;
          }
        }
      }
    }
  }
}

.mn_navSeasonalFlyoutSection .mn_link,
.mn_navPopularStoresSection .mn_viewAllPopularStoresLink,
.mn_accountNavigation .mn_extraRewardsNavigationDropdown .mn_linkBottom,
.mn_navCategoryFeaturedSection .mn_seeAllLink {
  @include mn_linkWithArrow($fontSize: 1.6rem);
}

#mn_hsr {
  .mn_contentWrap {
    margin: 0 auto;
    width: $xxlarge;
    padding: 0 2rem;

    .mn_sectionTitle a.mn_viewAllLink {
      &:after {
        display: none;
      }
    }

    @media #{$large-screen} {
      padding: 0 0 0 2.7rem;
    }
  }

  .mn_minor {
    text-transform: lowercase;
  }
}

#mn_msr,
#mn_csr,
#mn_sr {
  .mn_minor {
    text-transform: lowercase;
  }
}

#mn_csr {
  [data-balloon] {
    &:before {
      background: none;
    }

    &:after {
      color: $brandWhite;
      background: $brandPrimaryColor;
      box-shadow: 2px 2px 0 0 rgba(51, 57, 64, .5);
      padding: .3rem 1rem .6rem;
      line-height: 1.5rem;
      text-transform: uppercase;
    }
  }
}

#mn_sr .mn_marketingBanner {
  margin: 1rem 0 3rem;
}
