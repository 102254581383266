@import '~org/styles/variables';

$navCategoriesTOFCloseMenuPosition: absolute !default;
$navCategoriesTOFCloseMenuPositionRight: 3rem !default;
$navCategoriesTOFCloseMenuPositionTop: 2.7rem !default;
$navCategoriesTOFCloseMenuPadding: 0 !default;
$navCategoriesTOFCloseMenuAfterContent: '' !default;
$navCategoriesTOFCloseMenuAfterWidth: 2rem !default;
$navCategoriesTOFCloseMenuAfterHeight: 2rem !default;
$navCategoriesTOFCloseMenuAfterBackground: no-repeat bottom/100% url('~org/assets/images/icons/close.svg') !default;
$navCategoriesTOFCloseMenuAfterFontFamily: () !default;
$navCategoriesTOFCloseMenuAfterFontSize: () !default;
$navCategoriesTOFCloseMenuAfterColor: () !default;

$navCategoriesTOFDropdownMenuWidth: 50rem !default;
$navCategoriesTOFDropdownMenuPositionLeft: 35rem !default;
$navCategoriesTOFDropdownMenuMarginLeft: -1px !default;
$navCategoriesTOFDropdownMenuBackground: #0c2340 !default;
$navCategoriesTOFDropdownMenuTop: 1px !default;
$navCategoriesTOFDropdownMenuPadding: 2rem 5rem !default;
$navCategoriesTOFDropdownMenuMinHeight: calc(100% - 2px) !default;
$navCategoriesTOFDropdownMenuBorderRadius: () !default;
$navCategoriesTOFDropdownMenuBoxShadow: () !default;
$navCategoriesTOFFeaturedSectionTitleMargin: () !default;

.mn_navCategoriesTOF {
  .mn_closeFlyoutMenu {
    background: none;
    border: none;
    position: $navCategoriesTOFCloseMenuPosition;
    right: $navCategoriesTOFCloseMenuPositionRight;
    top: $navCategoriesTOFCloseMenuPositionTop;
    padding: $navCategoriesTOFCloseMenuPadding;
    cursor: pointer;

    &:after {
      content: $navCategoriesTOFCloseMenuAfterContent;
      display: block;
      width: $navCategoriesTOFCloseMenuAfterWidth;
      height: $navCategoriesTOFCloseMenuAfterHeight;
      background: $navCategoriesTOFCloseMenuAfterBackground;
      font-family: $navCategoriesTOFCloseMenuAfterFontFamily;
      font-size: $navCategoriesTOFCloseMenuAfterFontSize;
      color: $navCategoriesTOFCloseMenuAfterColor;
    }
  }

  .mn_dropdownMenuContent {
    display: none;
    width: $navCategoriesTOFDropdownMenuWidth;
    left: $navCategoriesTOFDropdownMenuPositionLeft;
    margin-left: $navCategoriesTOFDropdownMenuMarginLeft;
    background: $navCategoriesTOFDropdownMenuBackground;
    top: $navCategoriesTOFDropdownMenuTop;
    padding: $navCategoriesTOFDropdownMenuPadding;
    min-height: $navCategoriesTOFDropdownMenuMinHeight;
    position: absolute;
    overflow: hidden;
    z-index: 2;
    border-radius: $navCategoriesTOFDropdownMenuBorderRadius;
    box-shadow: $navCategoriesTOFDropdownMenuBoxShadow;
  }

  &.mn_focused .mn_dropdownMenuContent {
    display: block;
  }

  .mn_navCategoryFeaturedSection {
    h2 {
      margin: $navCategoriesTOFFeaturedSectionTitleMargin;
    }
  }
}
