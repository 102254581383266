@import '~org/styles/variables';

/* Merchant Overlay */
$hybridStoresOfferOverlayTextAlign: (small: left, medium: center) !default;
$hybridStoresOfferOverlayColor: (small: $brandTextColor, medium: $brandNeutralDarkColor2) !default;
$hybridStoresOfferOverlayFontSize: (small: 1.8rem , medium: 1.6rem) !default;
$hybridStoresOfferOverlayPadding: (small: 0 1rem 7rem, medium: 0 2rem 7rem) !default;
$hybridStoresOfferOverlayPositionBottom: (small: 0, medium: initial) !default;
$hybridStoresOfferOverlayMinHeight: auto !default;
$hybridStoresOfferOverlayWidth: 64rem !default;
$hybridStoresOfferOverlayContentPadding: (small: 0, medium: 3.1rem 0 0) !default;
$hybridStoresOfferOverlayPositionTop: (small: unset, medium: 20%) !default;

$hybridStoresOfferOverlayHeadOfferSeparatorBorderTop: () !default;
$hybridStoresOfferOverlayHeadOfferSeparatorMargin: (small: .9rem 0, medium: ()) !default;
$hybridStoresOfferOverlayHeadOfferSeparatorDisplay: (small: (), medium: ()) !default;

/* Top Section */
$hybridStoresOfferOverlayTopSectionTitlePadding: (small: 0 0 11.6rem, medium: 0 0 11.6rem) !default;
$hybridStoresOfferOverlayTopSectionTitleMargin: 0 !default;
$hybridStoresOfferOverlayTopSectionTitleFontSize: (small: 1.8rem, medium: 3.2rem) !default;
$hybridStoresOfferOverlayTopSectionTitleFontWeight: 500 !default;
$hybridStoresOfferOverlayTopSectionTitleLineHeight: (small: 3rem, medium: 4rem) !default;

$hybridStoresOfferOverlayImgPosition: absolute !default;
$hybridStoresOfferOverlayImgTop: (small: 28%, medium: 36%) !default;
$hybridStoresOfferOverlayImgPositionLeft: 50% !default;
$hybridStoresOfferOverlayImgTransform: translate(-50%, -50%) !default;
$hybridStoresOfferOverlayImgWidth: 70% !default;

$hybridStoresOfferOverlayOnlineOfferDisplay: inline-flex !default;
$hybridStoresOfferOverlayOnlineOfferJustifyContent: space-between !default;
$hybridStoresOfferOverlayOnlineOfferPadding: (small: 0, medium: 2rem 0) !default;
$hybridStoresOfferOverlayInstoreOfferDisplay: inline-flex !default;
$hybridStoresOfferOverlayInstoreOfferJustifyContent: space-between !default;

$hybridStoresOfferOverlayBottomSectionDisplay: flex !default;
$hybridStoresOfferOverlayBottomSectionFlexDirection: column !default;

$hybridStoresOfferOverlayOfferDetailsDisplay: (small: block ,medium: flex) !default;
$hybridStoresOfferOverlayOfferDetailsFontSize: 1.6rem !default;
$hybridStoresOfferOverlayOfferDetailsFontWeight: 500 !default;
$hybridStoresOfferOverlayOfferDetailsLineHeight: (small: 2.6rem, medium: 2.8rem) !default;

$hybridStoresOfferOverlayTextColor: () !default;
$hybridStoresOfferOverlayPremierMerchantRebateBackground: () !default;
$hybridStoresOfferOverlayRebateValueDisplay: flex !default;
$hybridStoresOfferOverlayPremierMerchantRebateOldFontWeight: 400 !default;
$hybridStoresOfferOverlayPremierMerchantRebateOldColor: () !default;
$hybridStoresOfferOverlayPremierMerchantRebateFontSize: 1.6rem !default;
$hybridStoresOfferOverlayPremierMerchantRebateFontWeight: 500 !default;
$hybridStoresOfferOverlayPremierMerchantRebateLineHeight: 2.8rem !default;
$hybridStoresOfferOverlayPremierMerchantRebateElevatedNewSpacing: .4rem !default;
$hybridStoresOfferOverlayPremierMerchantRebateTieredElevatedNewSpacing: 0 !default;
$hybridStoresOfferOverlayPremierMerchantRebateTieredElevatedDisplay: block !default;
$hybridStoresOfferOverlayInstoreMerchantRebateDisplay: contents !default;
$hybridStoresOfferOverlayInstoreMerchantRebateColor: () !default;
$hybridStoresOfferOverlayInstoreMerchantRebateFontSize: 1.6rem !default;
$hybridStoresOfferOverlayInstoreMerchantRebateFontWeight: 500 !default;
$hybridStoresOfferOverlayInstoreMerchantRebateLineHeight: 2.8rem !default;

$hybridStoresOfferOverlayCTAWidth: 10.4rem !default;
$hybridStoresOfferOverlayCTAHeight: (small: 3.2rem, medium: ()) !default;
$hybridStoresOfferOverlayCTAPadding: .2rem 0 !default;
$hybridStoresOfferOverlayCTAFontSize: 1.4rem !default;
$hybridStoresOfferOverlayCTAFontWeight: 500 !default;
$hybridStoresOfferOverlayCTALineHeight: 2.4rem !default;

$hybridStoresOfferOverlayLinkedCTABackground: #ccc !default;
$hybridStoresOfferOverlayLinkedCTABorder: none !default;

.mn_hybridStoresOfferModal {
  .mn_modal {
    bottom: map-get($hybridStoresOfferOverlayPositionBottom, small);
    top: map-get($hybridStoresOfferOverlayPositionTop, small);
  }

  .mn_modalContent {
    padding: map-get($hybridStoresOfferOverlayPadding, small);
  }

  @media #{$medium-screen} {
    .mn_modal {
      bottom: map-get($hybridStoresOfferOverlayPositionBottom, medium);
      top: map-get($hybridStoresOfferOverlayPositionTop, medium);
      min-height: $hybridStoresOfferOverlayMinHeight;
      width: $hybridStoresOfferOverlayWidth;
    }

    .mn_modalContent {
      padding: map-get($hybridStoresOfferOverlayPadding, medium);
    }
  }
}

.mn_hybridStoresOfferOverlayContent {
  text-align: map-get($hybridStoresOfferOverlayTextAlign, small);
  color: map-get($hybridStoresOfferOverlayColor, small);
  font-size: map-get($hybridStoresOfferOverlayFontSize, small);
  padding: map-get($hybridStoresOfferOverlayContentPadding, small);

  .mn_topSection {
    padding: map-get($hybridStoresOfferOverlayTopSectionTitlePadding, small);

    h2 {
      margin: $hybridStoresOfferOverlayTopSectionTitleMargin;
      font-size: map-get($hybridStoresOfferOverlayTopSectionTitleFontSize, small);
      font-weight: $hybridStoresOfferOverlayTopSectionTitleFontWeight;
      line-height: map-get($hybridStoresOfferOverlayTopSectionTitleLineHeight, small);
    }

    img {
      position: $hybridStoresOfferOverlayImgPosition;
      top: map-get($hybridStoresOfferOverlayImgTop, small);
      left: $hybridStoresOfferOverlayImgPositionLeft;
      transform: $hybridStoresOfferOverlayImgTransform;
      max-width: $hybridStoresOfferOverlayImgWidth;
    }
  }

  hr {
    border: 0;
    border-top: map-get($hybridStoresOfferOverlayHeadOfferSeparatorBorderTop, small);
    margin: map-get($hybridStoresOfferOverlayHeadOfferSeparatorMargin, small);
    display: map-get($hybridStoresOfferOverlayHeadOfferSeparatorDisplay, small);
  }

  .mn_bottomSection {
    display: $hybridStoresOfferOverlayBottomSectionDisplay;
    flex-direction: $hybridStoresOfferOverlayBottomSectionFlexDirection;

    .mn_premierMerchantRebate {
      background: $hybridStoresOfferOverlayPremierMerchantRebateBackground;

      .mn_rebatePrefix {
        color: $hybridStoresOfferOverlayInstoreMerchantRebateColor;
      }

      .mn_rebateValue.mn_rebateElevated {
        display: $hybridStoresOfferOverlayRebateValueDisplay;
      }

      .mn_rebateValueWithCurrency {
        font-weight: $hybridStoresOfferOverlayPremierMerchantRebateFontWeight;
      }

      .mn_blockRebate,
      .mn_elevationNewValue,
      .mn_elevationOldValue {
        color: $hybridStoresOfferOverlayTextColor;
        font-size: $hybridStoresOfferOverlayPremierMerchantRebateFontSize;
        font-weight: $hybridStoresOfferOverlayPremierMerchantRebateFontWeight;
        line-height: $hybridStoresOfferOverlayPremierMerchantRebateLineHeight;
      }

      .mn_elevationOldValue {
        color: $hybridStoresOfferOverlayPremierMerchantRebateOldColor;
        font-weight: $hybridStoresOfferOverlayPremierMerchantRebateOldFontWeight;
      }

      .mn_rebateValue.mn_rebateElevated .mn_elevationNewValue {
        margin-left: $hybridStoresOfferOverlayPremierMerchantRebateElevatedNewSpacing;
      }

      .mn_rebateValue.mn_rebateElevated.mn_tieredElevation {
        display: $hybridStoresOfferOverlayPremierMerchantRebateTieredElevatedDisplay;

        .mn_elevationNewValue:before {
          margin-left: $hybridStoresOfferOverlayPremierMerchantRebateTieredElevatedNewSpacing;
        }
      }
    }

    .mn_onlineOffer {
      display: $hybridStoresOfferOverlayOnlineOfferDisplay;
      justify-content: $hybridStoresOfferOverlayOnlineOfferJustifyContent;
      padding: map-get($hybridStoresOfferOverlayOnlineOfferPadding, small);
    }

    .mn_instoreOffer {
      display: $hybridStoresOfferOverlayInstoreOfferDisplay;
      justify-content: $hybridStoresOfferOverlayInstoreOfferJustifyContent;
    }

    .mn_offerDetails {
      display: map-get($hybridStoresOfferOverlayOfferDetailsDisplay, small);
      font-size: $hybridStoresOfferOverlayOfferDetailsFontSize;
      font-weight: $hybridStoresOfferOverlayOfferDetailsFontWeight;
      line-height: map-get($hybridStoresOfferOverlayOfferDetailsLineHeight, small);
    }

    .mn_button {
      width: $hybridStoresOfferOverlayCTAWidth;
      height: map-get($hybridStoresOfferOverlayCTAHeight, small);
      padding: $hybridStoresOfferOverlayCTAPadding;
      font-size: $hybridStoresOfferOverlayCTAFontSize;
      font-weight: $hybridStoresOfferOverlayCTAFontWeight;
      line-height: $hybridStoresOfferOverlayCTALineHeight;
    }

    .mn_linked {
      background: $hybridStoresOfferOverlayLinkedCTABackground;
      border: $hybridStoresOfferOverlayLinkedCTABorder;
    }

    .mn_instoreMerchantRebate {
      display: $hybridStoresOfferOverlayInstoreMerchantRebateDisplay;
    }

    .mn_inStoreRebate {
      color: $hybridStoresOfferOverlayInstoreMerchantRebateColor;
      font-size: $hybridStoresOfferOverlayInstoreMerchantRebateFontSize;
      font-weight: $hybridStoresOfferOverlayInstoreMerchantRebateFontWeight;
      line-height: $hybridStoresOfferOverlayInstoreMerchantRebateLineHeight;
    }
  }

  @media #{$medium-screen} {
    text-align: map-get($hybridStoresOfferOverlayTextAlign, medium);
    padding: map-get($hybridStoresOfferOverlayContentPadding, medium);

    .mn_topSection {
      padding: map-get($hybridStoresOfferOverlayTopSectionTitlePadding, medium);

      h2 {
        font-size: map-get($hybridStoresOfferOverlayTopSectionTitleFontSize, medium);
        line-height: map-get($hybridStoresOfferOverlayTopSectionTitleLineHeight, medium);
      }

      img {
        top: map-get($hybridStoresOfferOverlayImgTop, medium);
      }
    }

    .mn_bottomSection {
      .mn_onlineOffer {
        padding: map-get($hybridStoresOfferOverlayOnlineOfferPadding, medium);
      }

      .mn_offerDetails {
        display: map-get($hybridStoresOfferOverlayOfferDetailsDisplay, medium);
        line-height: map-get($hybridStoresOfferOverlayOfferDetailsLineHeight, medium);
      }

      .mn_button {
        height: map-get($hybridStoresOfferOverlayCTAHeight, medium);
      }
    }
  }
}
