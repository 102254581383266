@import '~org/styles/variables';

$pillHeight: 2rem () !default;
$pillBackground: () !default;
$pillBorderRadius: () !default;

$pillLabelTextTransform: () !default;
$pillLabelLineHeight: 2rem !default;
$pillLabelFontSize: 1rem !default;
$pillLabelColor: $brandWhite !default;
$pillLabelLetterSpacing: () !default;
$pillLabelFontWeight: bold !default;
$pillLabelPadding: () !default;

$pillLabelAfterPosition: relative !default;
$pillLabelAfterDisplay: inline-block !default;
$pillLabelAfterBackground: () !default;
$pillLabelAfterWidth: 1.7rem !default;
$pillLabelAfterHeight: 1.7rem !default;
$pillLabelAfterPositionRight: () !default;
$pillLabelAfterPositionLeft: () !default;
$pillLabelAfterPositionTop: () !default;
$pillLabelAfterBorderStyle: () !default;
$pillLabelAfterBorderColor: () !default;
$pillLabelAfterBorderWidth: () !default;
$pillLabelAfterBorderRightColor: () !default;
$pillLabelAfterMargin: () !default;

$pillLabelBeforeDisplay: none !default;
$pillLabelBeforePosition: () !default;
$pillLabelBeforePositionLeft: () !default;
$pillLabelBeforeBorderStyle: () !default;
$pillLabelBeforeBorderColor: () !default;
$pillLabelBeforeBorderWidth: () !default;
$pillLabelBeforeBorderLeftColor: () !default;

.mn_pill {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: $pillHeight;
  background: $pillBackground;
  border-radius: $pillBorderRadius;
  z-index: 1;
  text-align: center;

  .mn_pillLabel {
    display: block;
    white-space: nowrap;
    pointer-events: none;
    font-style: normal;
    text-transform: $pillLabelTextTransform;
    line-height: $pillLabelLineHeight;
    font-size: $pillLabelFontSize;
    color: $pillLabelColor;
    letter-spacing: map-get($pillLabelLetterSpacing, small);
    font-weight: $pillLabelFontWeight;
    padding: $pillLabelPadding;

    &:after {
      content: '';
      display: $pillLabelAfterDisplay;
      background: $pillLabelAfterBackground;
      background-size: contain;
      position: $pillLabelAfterPosition;
      width: $pillLabelAfterWidth;
      height: $pillLabelAfterHeight;
      right: $pillLabelAfterPositionRight;
      left: $pillLabelAfterPositionLeft;
      top: $pillLabelAfterPositionTop;
      border-style: $pillLabelAfterBorderStyle;
      border-color: $pillLabelAfterBorderColor;
      border-width: $pillLabelAfterBorderWidth;
      border-right-color: $pillLabelAfterBorderRightColor;
      margin: $pillLabelAfterMargin;
    }

    &:before {
      content: '';
      position: $pillLabelBeforePosition;
      left: $pillLabelBeforePositionLeft;
      display: $pillLabelBeforeDisplay;
      border-style: $pillLabelBeforeBorderStyle;
      border-color: $pillLabelBeforeBorderColor;
      border-width: $pillLabelBeforeBorderWidth;
      border-left-color: $pillLabelBeforeBorderLeftColor;
    }
  }

  @media #{$medium-screen} {
    .mn_pillLabel {
      letter-spacing: map-get($pillLabelLetterSpacing, medium);
    }
  }
}
