@import '~org/styles/variables';

.mn_deletedMemberModal {
  p {
    font-size: 1.5rem;
    line-height: 2.2rem;
    color: #000;
  }

  h1,
  h2 {
    font-size: 1.7rem;
    line-height: 2.2rem;
    color: #000;
  }

  sup {
    font-size: .8rem;
    vertical-align: super;
  }
}
